import './RootLayout.css'
import { Outlet } from "react-router-dom"
import { HeaderComponent } from "../components/ui/nav/header/header";
import { FooterComponent } from "../components/ui/nav/footer/footer";
import { LoginPromptComponent } from '../components/ui/login-prompt/login-prompt';
import AuthContext from '../context/auth.context'
import { useEffect, useState } from 'react';
import { getUser } from '../core/services/login-service/login.service';
import { MagnifyingGlass } from 'react-loader-spinner';

export function RootLayout() {
    const [loggedUser,setUser] = useState('');
    useEffect(() => {
        setUser(getUser());
    }, [])
    

    if(loggedUser?.id){
        return (<>
            <AuthContext.Provider value={{userObj:loggedUser}}>
                <div className='parent-root-layout'>
                    <div className="root-layout">
                        <HeaderComponent />
                        
                        {/* main-content start */}
                        <Outlet />
                        {/* main-content end */}

                        <FooterComponent />
                    </div>
                </div>
            </AuthContext.Provider>
        </>)
    }else{
        return (<>
        <LoginPromptComponent/>
        </>)
    }
    
}

export default RootLayout;