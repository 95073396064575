import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, createRoutesFromElements, Link, NavLink, Route, RouterProvider, Routes } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import { HomePage } from './pages/home/home';
import { RegisterPage } from './pages/auth/register/register';
import { LoginPage } from './pages/auth/login/login';
import { ProfilePage } from './pages/profile/profile.page';
import { ViewQuizPage } from './pages/quiz/view-quiz/view-quizzes.page';
import { QuizResultPage } from './pages/quiz/quiz-result/quiz-result.page';
import { AttendQuizPage } from './pages/quiz/attend-quiz/attend-quize.page';
import { NotFoundPage } from './components/404/404';
import { ForgotPasswordPage } from './pages/auth/forgot-password/forgot-pass';
import { CategoriesPage } from './pages/categories/categories.page';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { MyQuizzesPage } from './pages/quiz/my-quizzes/my-quizzes.page';
import { ChangePasswordPage } from './pages/auth/change-password/change-password';
import { ProfileChangePasswordPage } from './pages/profile/profile-change-pass/change-password';
import { PostByCategoryPage } from './pages/post-by-categories/post-by-categories';
import { CategoryFeedPage } from './pages/post-by-categories/category-posts/category-posts';
import PracticeQuizView from './pages/quiz/practice-quiz/practice-quiz-view';
import { AttendPracticeQuizPage } from './pages/quiz/practice-quiz/attend-practice-quiz/attend-practice-quiz';
import { PracticeQuizResultPage } from './pages/quiz/practice-quiz/practice-quiz-result/practice-quiz-result';
import SelectQuizView from './pages/quiz/select-quiz/select-quiz';



const mRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route path='/' element={<RootLayout />}>
      {/* <Route index element={<HomePage/>}/> */}
      {/* feed-route */}
      <Route path="/" element={<HomePage />} />
      <Route path="/posts-by-categories" element={<CategoryFeedPage />} />
      {/*quiz-route */}
      <Route path="/select-quiz" element={<SelectQuizView />} />
      //? PracticeQuiz
      <Route path="/practice-quiz" element={<PracticeQuizView />} />
      <Route path="/practice-quiz/attend-quiz" element={<AttendPracticeQuizPage />} />
      <Route path="/practice-quiz/result" element={<PracticeQuizResultPage />} />
      //? liveQuiz
      <Route path="/quiz" element={<ViewQuizPage />} />
      <Route path="/quiz/attend-quiz" element={<AttendQuizPage />} />
      <Route path="/quiz/result" element={<QuizResultPage />} />
      <Route path="/quiz/my-quizzes" element={<MyQuizzesPage />} />
      {/* profile-route */}
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/profile/change-password" element={<ProfileChangePasswordPage />} />
      <Route path="/categories" element={<CategoriesPage />} />
      <Route path="/post-by-categories" element={<PostByCategoryPage />} />
    </Route>
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route path="/change-password" element={<ChangePasswordPage />} />
    <Route path="*" element={<NotFoundPage/>}/>
    </>
  )
)

function App() {

  return (<>
    <RouterProvider router={mRouter} />
    <ToastContainer /> 
  </>)

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
