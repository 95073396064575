import './categories-card.css'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import categoryThum from '../../../assets/svg/category-thum.svg';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ViewPostsButton(categoryId) {
  return (<>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        {/* <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton> */}
      </Grid>
      <Grid item xs={12}>
        <NavLink to={`/posts-by-categories?category=${categoryId?.categoryId}`}>
          <Button variant="contained" color='error'>
           View Posts
          </Button>
        </NavLink>
      </Grid>
    </Grid>
  </>)
}

export default function CategoriesCardComponent({ category, discover }) {
  const [expanded, setExpanded] = useState(false);

  let iconComponent = <AddBoxIcon style={{ color: 'green' }} />
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (expanded) {
      showDToaster('Removed!');
    } else {
      showDToaster('Added!');
    }
    iconComponent = expanded ? (
      <RemoveCircleIcon style={{ color: 'red' }} />
    ) : (
      <AddBoxIcon style={{ color: 'green' }} />
    );
  };

  return (
    <Card className='mAuto' sx={{ maxWidth: 205 }}>
      <CardMedia
        component="img"
        height="150"
        image={categoryThum}
        alt=""
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <b> {category?.categoryName} </b>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {discover ?
          <ViewPostsButton categoryId={category?.categoryId} />
          :
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            {expanded ? <RemoveCircleIcon style={{ color: 'red' }} /> : <AddBoxIcon style={{ color: 'green' }} />}
          </ExpandMore>
        }

      </CardActions>
    </Card>
  );
}