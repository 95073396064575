import noDataSvg from '../../assets/svg/no-data.svg';


export function NoDataFound() {
    return (
        <>
         <h2> Sorry! No data found!</h2>
         <img src={noDataSvg} alt='no data'/>
         {/* <a href="https://storyset.com/data" target="_blank">Data illustrations by Storyset</a> */}
        </>
    )
}