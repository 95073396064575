import { getRequest, patchRequest, postRequest } from "../base/base-api.service";



function getSingleQuiz(quizID) {
    return new Promise((resolve, reject) => {
        getRequest(`quizzes/view/${quizID}`)
            .then(response => {
                console.log(response);
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function getAllQuizzes() {
    return new Promise((resolve, reject) => {
        getRequest('quizzes/viewAll')
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function checkAttempt(userId, quizId){
    return new Promise((resolve, reject) => {
        getRequest(`check/checkattempt?userId=${userId}&quizId=${quizId}`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function attemptingQuiz( quizId ) {
    return new Promise((resolve, reject) => {
        patchRequest(`quizzes/attempt/${quizId}`)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}






export  {getSingleQuiz, getAllQuizzes, checkAttempt, attemptingQuiz};  
