import CategoriesCardComponent from "../../components/ui/categories-card/categories-card";
import Grid from '@mui/material/Grid';
import { getCategories } from "../../core/services/categories-service/categories.service";
import { showDToaster } from "../../core/services/toaster-service/toaster.service";
import { useEffect, useState } from "react";
import { SpinnerComponent } from "../../components/ui/spinner/spinner";
import { changeRefreshToken, removeUser } from "../../core/services/login-service/login.service";
import { useNavigate } from 'react-router-dom';

export function CategoriesPage(){
    const [fetchedTopics, setFetchedTopics] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const navigate = useNavigate();
    
    useEffect(() => {
        getCategoriesFunc();
        changeRefreshTokenFunc();
    }, []);

    useEffect(() => {
        console.log(fetchedTopics);
    }, [fetchedTopics]);
    

    const getCategoriesFunc = () => {
        setShowLoader(true);
        getCategories().then((v)=>{
            console.log(v);
            let data = v?.data?.results;
            setFetchedTopics(data);
            setShowLoader(false);
            if(v){
                // showDToaster('Congo!!');
            }
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message);setShowLoader(false);});
    }

    const changeRefreshTokenFunc = ()=>{
        changeRefreshToken().then((v)=>{
          setShowLoader(false);
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false); removeUser(); navigate('/'); });
    };

    return (<>
    {showLoader && <SpinnerComponent/>}
        <div>
            <h2> Topics </h2>
            <Grid container spacing={2}>
            {fetchedTopics?.map((category, index) => {
                console.log(category);
                return (<>
                <Grid item xs={6} sm={4} md={6}>
                <CategoriesCardComponent key={index} category={category} discover={false} />
                </Grid>
                </>)
            })}
            {/* <Grid item xs={6} sm={4} md={6}>
                <CategoriesCardComponent/>
            </Grid>
            <Grid item xs={6} sm={4} md={6}>
            <CategoriesCardComponent/>
            </Grid> */}
          </Grid>
        </div>
    </>)
}