export const localStorageKeyConstants = {
    token:'kw-token',
    refreshToken:'kw-refresh-token',
    user:'kw-user'
}

export const AWS_VARS = {
    aws_base:'https://knowalledge-bucket.s3.ap-south-1.amazonaws.com/'
}

export const stateAndCity = [
    {
        "id": "1",
        "name": "Mumbai",
        "state": "Maharashtra"
    },
    {
        "id": "2",
        "name": "Delhi",
        "state": "Delhi"
    },
    {
        "id": "3",
        "name": "Bengaluru",
        "state": "Karnataka"
    },
    {
        "id": "4",
        "name": "Ahmedabad",
        "state": "Gujarat"
    },
    {
        "id": "5",
        "name": "Hyderabad",
        "state": "Telangana"
    },
    {
        "id": "6",
        "name": "Chennai",
        "state": "Tamil Nadu"
    },
    {
        "id": "7",
        "name": "Kolkata",
        "state": "West Bengal"
    },
    {
        "id": "8",
        "name": "Pune",
        "state": "Maharashtra"
    },
    {
        "id": "9",
        "name": "Jaipur",
        "state": "Rajasthan"
    },
    {
        "id": "10",
        "name": "Surat",
        "state": "Gujarat"
    },
    {
        "id": "11",
        "name": "Lucknow",
        "state": "Uttar Pradesh"
    },
    {
        "id": "12",
        "name": "Kanpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "13",
        "name": "Nagpur",
        "state": "Maharashtra"
    },
    {
        "id": "14",
        "name": "Patna",
        "state": "Bihar"
    },
    {
        "id": "15",
        "name": "Indore",
        "state": "Madhya Pradesh"
    },
    {
        "id": "16",
        "name": "Thane",
        "state": "Maharashtra"
    },
    {
        "id": "17",
        "name": "Bhopal",
        "state": "Madhya Pradesh"
    },
    {
        "id": "18",
        "name": "Visakhapatnam",
        "state": "Andhra Pradesh"
    },
    {
        "id": "19",
        "name": "Vadodara",
        "state": "Gujarat"
    },
    {
        "id": "20",
        "name": "Firozabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "21",
        "name": "Ludhiana",
        "state": "Punjab"
    },
    {
        "id": "22",
        "name": "Rajkot",
        "state": "Gujarat"
    },
    {
        "id": "23",
        "name": "Agra",
        "state": "Uttar Pradesh"
    },
    {
        "id": "24",
        "name": "Siliguri",
        "state": "West Bengal"
    },
    {
        "id": "25",
        "name": "Nashik",
        "state": "Maharashtra"
    },
    {
        "id": "26",
        "name": "Faridabad",
        "state": "Haryana"
    },
    {
        "id": "27",
        "name": "Patiala",
        "state": "Punjab"
    },
    {
        "id": "28",
        "name": "Meerut",
        "state": "Uttar Pradesh"
    },
    {
        "id": "29",
        "name": "Kalyan-Dombivali",
        "state": "Maharashtra"
    },
    {
        "id": "30",
        "name": "Vasai-Virar",
        "state": "Maharashtra"
    },
    {
        "id": "31",
        "name": "Varanasi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "32",
        "name": "Srinagar",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "33",
        "name": "Dhanbad",
        "state": "Jharkhand"
    },
    {
        "id": "34",
        "name": "Jodhpur",
        "state": "Rajasthan"
    },
    {
        "id": "35",
        "name": "Amritsar",
        "state": "Punjab"
    },
    {
        "id": "36",
        "name": "Raipur",
        "state": "Chhattisgarh"
    },
    {
        "id": "37",
        "name": "Allahabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "38",
        "name": "Coimbatore",
        "state": "Tamil Nadu"
    },
    {
        "id": "39",
        "name": "Jabalpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "40",
        "name": "Gwalior",
        "state": "Madhya Pradesh"
    },
    {
        "id": "41",
        "name": "Vijayawada",
        "state": "Andhra Pradesh"
    },
    {
        "id": "42",
        "name": "Madurai",
        "state": "Tamil Nadu"
    },
    {
        "id": "43",
        "name": "Guwahati",
        "state": "Assam"
    },
    {
        "id": "44",
        "name": "Chandigarh",
        "state": "Chandigarh"
    },
    {
        "id": "45",
        "name": "Hubli-Dharwad",
        "state": "Karnataka"
    },
    {
        "id": "46",
        "name": "Amroha",
        "state": "Uttar Pradesh"
    },
    {
        "id": "47",
        "name": "Moradabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "48",
        "name": "Gurgaon",
        "state": "Haryana"
    },
    {
        "id": "49",
        "name": "Aligarh",
        "state": "Uttar Pradesh"
    },
    {
        "id": "50",
        "name": "Solapur",
        "state": "Maharashtra"
    },
    {
        "id": "51",
        "name": "Ranchi",
        "state": "Jharkhand"
    },
    {
        "id": "52",
        "name": "Jalandhar",
        "state": "Punjab"
    },
    {
        "id": "53",
        "name": "Tiruchirappalli",
        "state": "Tamil Nadu"
    },
    {
        "id": "54",
        "name": "Bhubaneswar",
        "state": "Odisha"
    },
    {
        "id": "55",
        "name": "Salem",
        "state": "Tamil Nadu"
    },
    {
        "id": "56",
        "name": "Warangal",
        "state": "Telangana"
    },
    {
        "id": "57",
        "name": "Mira-Bhayandar",
        "state": "Maharashtra"
    },
    {
        "id": "58",
        "name": "Thiruvananthapuram",
        "state": "Kerala"
    },
    {
        "id": "59",
        "name": "Bhiwandi",
        "state": "Maharashtra"
    },
    {
        "id": "60",
        "name": "Saharanpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "61",
        "name": "Guntur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "62",
        "name": "Amravati",
        "state": "Maharashtra"
    },
    {
        "id": "63",
        "name": "Bikaner",
        "state": "Rajasthan"
    },
    {
        "id": "64",
        "name": "Noida",
        "state": "Uttar Pradesh"
    },
    {
        "id": "65",
        "name": "Jamshedpur",
        "state": "Jharkhand"
    },
    {
        "id": "66",
        "name": "Bhilai Nagar",
        "state": "Chhattisgarh"
    },
    {
        "id": "67",
        "name": "Cuttack",
        "state": "Odisha"
    },
    {
        "id": "68",
        "name": "Kochi",
        "state": "Kerala"
    },
    {
        "id": "69",
        "name": "Udaipur",
        "state": "Rajasthan"
    },
    {
        "id": "70",
        "name": "Bhavnagar",
        "state": "Gujarat"
    },
    {
        "id": "71",
        "name": "Dehradun",
        "state": "Uttarakhand"
    },
    {
        "id": "72",
        "name": "Asansol",
        "state": "West Bengal"
    },
    {
        "id": "73",
        "name": "Nanded-Waghala",
        "state": "Maharashtra"
    },
    {
        "id": "74",
        "name": "Ajmer",
        "state": "Rajasthan"
    },
    {
        "id": "75",
        "name": "Jamnagar",
        "state": "Gujarat"
    },
    {
        "id": "76",
        "name": "Ujjain",
        "state": "Madhya Pradesh"
    },
    {
        "id": "77",
        "name": "Sangli",
        "state": "Maharashtra"
    },
    {
        "id": "78",
        "name": "Loni",
        "state": "Uttar Pradesh"
    },
    {
        "id": "79",
        "name": "Jhansi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "80",
        "name": "Pondicherry",
        "state": "Puducherry"
    },
    {
        "id": "81",
        "name": "Nellore",
        "state": "Andhra Pradesh"
    },
    {
        "id": "82",
        "name": "Jammu",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "83",
        "name": "Belagavi",
        "state": "Karnataka"
    },
    {
        "id": "84",
        "name": "Raurkela",
        "state": "Odisha"
    },
    {
        "id": "85",
        "name": "Mangaluru",
        "state": "Karnataka"
    },
    {
        "id": "86",
        "name": "Tirunelveli",
        "state": "Tamil Nadu"
    },
    {
        "id": "87",
        "name": "Malegaon",
        "state": "Maharashtra"
    },
    {
        "id": "88",
        "name": "Gaya",
        "state": "Bihar"
    },
    {
        "id": "89",
        "name": "Tiruppur",
        "state": "Tamil Nadu"
    },
    {
        "id": "90",
        "name": "Davanagere",
        "state": "Karnataka"
    },
    {
        "id": "91",
        "name": "Kozhikode",
        "state": "Kerala"
    },
    {
        "id": "92",
        "name": "Akola",
        "state": "Maharashtra"
    },
    {
        "id": "93",
        "name": "Kurnool",
        "state": "Andhra Pradesh"
    },
    {
        "id": "94",
        "name": "Bokaro Steel City",
        "state": "Jharkhand"
    },
    {
        "id": "95",
        "name": "Rajahmundry",
        "state": "Andhra Pradesh"
    },
    {
        "id": "96",
        "name": "Ballari",
        "state": "Karnataka"
    },
    {
        "id": "97",
        "name": "Agartala",
        "state": "Tripura"
    },
    {
        "id": "98",
        "name": "Bhagalpur",
        "state": "Bihar"
    },
    {
        "id": "99",
        "name": "Latur",
        "state": "Maharashtra"
    },
    {
        "id": "100",
        "name": "Dhule",
        "state": "Maharashtra"
    },
    {
        "id": "101",
        "name": "Korba",
        "state": "Chhattisgarh"
    },
    {
        "id": "102",
        "name": "Bhilwara",
        "state": "Rajasthan"
    },
    {
        "id": "103",
        "name": "Brahmapur",
        "state": "Odisha"
    },
    {
        "id": "104",
        "name": "Mysore",
        "state": "Karnataka"
    },
    {
        "id": "105",
        "name": "Muzaffarpur",
        "state": "Bihar"
    },
    {
        "id": "106",
        "name": "Ahmednagar",
        "state": "Maharashtra"
    },
    {
        "id": "107",
        "name": "Kollam",
        "state": "Kerala"
    },
    {
        "id": "108",
        "name": "Raghunathganj",
        "state": "West Bengal"
    },
    {
        "id": "109",
        "name": "Bilaspur",
        "state": "Chhattisgarh"
    },
    {
        "id": "110",
        "name": "Shahjahanpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "111",
        "name": "Thrissur",
        "state": "Kerala"
    },
    {
        "id": "112",
        "name": "Alwar",
        "state": "Rajasthan"
    },
    {
        "id": "113",
        "name": "Kakinada",
        "state": "Andhra Pradesh"
    },
    {
        "id": "114",
        "name": "Nizamabad",
        "state": "Telangana"
    },
    {
        "id": "115",
        "name": "Sagar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "116",
        "name": "Tumkur",
        "state": "Karnataka"
    },
    {
        "id": "117",
        "name": "Hisar",
        "state": "Haryana"
    },
    {
        "id": "118",
        "name": "Rohtak",
        "state": "Haryana"
    },
    {
        "id": "119",
        "name": "Panipat",
        "state": "Haryana"
    },
    {
        "id": "120",
        "name": "Darbhanga",
        "state": "Bihar"
    },
    {
        "id": "121",
        "name": "Kharagpur",
        "state": "West Bengal"
    },
    {
        "id": "122",
        "name": "Aizawl",
        "state": "Mizoram"
    },
    {
        "id": "123",
        "name": "Ichalkaranji",
        "state": "Maharashtra"
    },
    {
        "id": "124",
        "name": "Tirupati",
        "state": "Andhra Pradesh"
    },
    {
        "id": "125",
        "name": "Karnal",
        "state": "Haryana"
    },
    {
        "id": "126",
        "name": "Bathinda",
        "state": "Punjab"
    },
    {
        "id": "127",
        "name": "Rampur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "128",
        "name": "Shivamogga",
        "state": "Karnataka"
    },
    {
        "id": "129",
        "name": "Ratlam",
        "state": "Madhya Pradesh"
    },
    {
        "id": "130",
        "name": "Modinagar",
        "state": "Uttar Pradesh"
    },
    {
        "id": "131",
        "name": "Durg",
        "state": "Chhattisgarh"
    },
    {
        "id": "132",
        "name": "Shillong",
        "state": "Meghalaya"
    },
    {
        "id": "133",
        "name": "Imphal",
        "state": "Manipur"
    },
    {
        "id": "134",
        "name": "Hapur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "135",
        "name": "Ranipet",
        "state": "Tamil Nadu"
    },
    {
        "id": "136",
        "name": "Anantapur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "137",
        "name": "Arrah",
        "state": "Bihar"
    },
    {
        "id": "138",
        "name": "Karimnagar",
        "state": "Telangana"
    },
    {
        "id": "139",
        "name": "Parbhani",
        "state": "Maharashtra"
    },
    {
        "id": "140",
        "name": "Etawah",
        "state": "Uttar Pradesh"
    },
    {
        "id": "141",
        "name": "Bharatpur",
        "state": "Rajasthan"
    },
    {
        "id": "142",
        "name": "Begusarai",
        "state": "Bihar"
    },
    {
        "id": "143",
        "name": "New Delhi",
        "state": "Delhi"
    },
    {
        "id": "144",
        "name": "Chhapra",
        "state": "Bihar"
    },
    {
        "id": "145",
        "name": "Kadapa",
        "state": "Andhra Pradesh"
    },
    {
        "id": "146",
        "name": "Ramagundam",
        "state": "Telangana"
    },
    {
        "id": "147",
        "name": "Pali",
        "state": "Rajasthan"
    },
    {
        "id": "148",
        "name": "Satna",
        "state": "Madhya Pradesh"
    },
    {
        "id": "149",
        "name": "Vizianagaram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "150",
        "name": "Katihar",
        "state": "Bihar"
    },
    {
        "id": "151",
        "name": "Hardwar",
        "state": "Uttarakhand"
    },
    {
        "id": "152",
        "name": "Sonipat",
        "state": "Haryana"
    },
    {
        "id": "153",
        "name": "Nagercoil",
        "state": "Tamil Nadu"
    },
    {
        "id": "154",
        "name": "Thanjavur",
        "state": "Tamil Nadu"
    },
    {
        "id": "155",
        "name": "Murwara (Katni)",
        "state": "Madhya Pradesh"
    },
    {
        "id": "156",
        "name": "Naihati",
        "state": "West Bengal"
    },
    {
        "id": "157",
        "name": "Sambhal",
        "state": "Uttar Pradesh"
    },
    {
        "id": "158",
        "name": "Nadiad",
        "state": "Gujarat"
    },
    {
        "id": "159",
        "name": "Yamunanagar",
        "state": "Haryana"
    },
    {
        "id": "160",
        "name": "English Bazar",
        "state": "West Bengal"
    },
    {
        "id": "161",
        "name": "Eluru",
        "state": "Andhra Pradesh"
    },
    {
        "id": "162",
        "name": "Munger",
        "state": "Bihar"
    },
    {
        "id": "163",
        "name": "Panchkula",
        "state": "Haryana"
    },
    {
        "id": "164",
        "name": "Raayachuru",
        "state": "Karnataka"
    },
    {
        "id": "165",
        "name": "Panvel",
        "state": "Maharashtra"
    },
    {
        "id": "166",
        "name": "Deoghar",
        "state": "Jharkhand"
    },
    {
        "id": "167",
        "name": "Ongole",
        "state": "Andhra Pradesh"
    },
    {
        "id": "168",
        "name": "Nandyal",
        "state": "Andhra Pradesh"
    },
    {
        "id": "169",
        "name": "Morena",
        "state": "Madhya Pradesh"
    },
    {
        "id": "170",
        "name": "Bhiwani",
        "state": "Haryana"
    },
    {
        "id": "171",
        "name": "Porbandar",
        "state": "Gujarat"
    },
    {
        "id": "172",
        "name": "Palakkad",
        "state": "Kerala"
    },
    {
        "id": "173",
        "name": "Anand",
        "state": "Gujarat"
    },
    {
        "id": "174",
        "name": "Purnia",
        "state": "Bihar"
    },
    {
        "id": "175",
        "name": "Baharampur",
        "state": "West Bengal"
    },
    {
        "id": "176",
        "name": "Barmer",
        "state": "Rajasthan"
    },
    {
        "id": "177",
        "name": "Morvi",
        "state": "Gujarat"
    },
    {
        "id": "178",
        "name": "Orai",
        "state": "Uttar Pradesh"
    },
    {
        "id": "179",
        "name": "Bahraich",
        "state": "Uttar Pradesh"
    },
    {
        "id": "180",
        "name": "Sikar",
        "state": "Rajasthan"
    },
    {
        "id": "181",
        "name": "Vellore",
        "state": "Tamil Nadu"
    },
    {
        "id": "182",
        "name": "Singrauli",
        "state": "Madhya Pradesh"
    },
    {
        "id": "183",
        "name": "Khammam",
        "state": "Telangana"
    },
    {
        "id": "184",
        "name": "Mahesana",
        "state": "Gujarat"
    },
    {
        "id": "185",
        "name": "Silchar",
        "state": "Assam"
    },
    {
        "id": "186",
        "name": "Sambalpur",
        "state": "Odisha"
    },
    {
        "id": "187",
        "name": "Rewa",
        "state": "Madhya Pradesh"
    },
    {
        "id": "188",
        "name": "Unnao",
        "state": "Uttar Pradesh"
    },
    {
        "id": "189",
        "name": "Hugli-Chinsurah",
        "state": "West Bengal"
    },
    {
        "id": "190",
        "name": "Raiganj",
        "state": "West Bengal"
    },
    {
        "id": "191",
        "name": "Phusro",
        "state": "Jharkhand"
    },
    {
        "id": "192",
        "name": "Adityapur",
        "state": "Jharkhand"
    },
    {
        "id": "193",
        "name": "Alappuzha",
        "state": "Kerala"
    },
    {
        "id": "194",
        "name": "Bahadurgarh",
        "state": "Haryana"
    },
    {
        "id": "195",
        "name": "Machilipatnam",
        "state": "Andhra Pradesh"
    },
    {
        "id": "196",
        "name": "Rae Bareli",
        "state": "Uttar Pradesh"
    },
    {
        "id": "197",
        "name": "Jalpaiguri",
        "state": "West Bengal"
    },
    {
        "id": "198",
        "name": "Bharuch",
        "state": "Gujarat"
    },
    {
        "id": "199",
        "name": "Pathankot",
        "state": "Punjab"
    },
    {
        "id": "200",
        "name": "Hoshiarpur",
        "state": "Punjab"
    },
    {
        "id": "201",
        "name": "Baramula",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "202",
        "name": "Adoni",
        "state": "Andhra Pradesh"
    },
    {
        "id": "203",
        "name": "Jind",
        "state": "Haryana"
    },
    {
        "id": "204",
        "name": "Tonk",
        "state": "Rajasthan"
    },
    {
        "id": "205",
        "name": "Tenali",
        "state": "Andhra Pradesh"
    },
    {
        "id": "206",
        "name": "Kancheepuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "207",
        "name": "Vapi",
        "state": "Gujarat"
    },
    {
        "id": "208",
        "name": "Sirsa",
        "state": "Haryana"
    },
    {
        "id": "209",
        "name": "Navsari",
        "state": "Gujarat"
    },
    {
        "id": "210",
        "name": "Mahbubnagar",
        "state": "Telangana"
    },
    {
        "id": "211",
        "name": "Puri",
        "state": "Odisha"
    },
    {
        "id": "212",
        "name": "Robertson Pet",
        "state": "Karnataka"
    },
    {
        "id": "213",
        "name": "Erode",
        "state": "Tamil Nadu"
    },
    {
        "id": "214",
        "name": "Batala",
        "state": "Punjab"
    },
    {
        "id": "215",
        "name": "Haldwani-cum-Kathgodam",
        "state": "Uttarakhand"
    },
    {
        "id": "216",
        "name": "Vidisha",
        "state": "Madhya Pradesh"
    },
    {
        "id": "217",
        "name": "Saharsa",
        "state": "Bihar"
    },
    {
        "id": "218",
        "name": "Thanesar",
        "state": "Haryana"
    },
    {
        "id": "219",
        "name": "Chittoor",
        "state": "Andhra Pradesh"
    },
    {
        "id": "220",
        "name": "Veraval",
        "state": "Gujarat"
    },
    {
        "id": "221",
        "name": "Lakhimpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "222",
        "name": "Sitapur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "223",
        "name": "Hindupur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "224",
        "name": "Santipur",
        "state": "West Bengal"
    },
    {
        "id": "225",
        "name": "Balurghat",
        "state": "West Bengal"
    },
    {
        "id": "226",
        "name": "Ganjbasoda",
        "state": "Madhya Pradesh"
    },
    {
        "id": "227",
        "name": "Moga",
        "state": "Punjab"
    },
    {
        "id": "228",
        "name": "Proddatur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "229",
        "name": "Srinagar",
        "state": "Uttarakhand"
    },
    {
        "id": "230",
        "name": "Medinipur",
        "state": "West Bengal"
    },
    {
        "id": "231",
        "name": "Habra",
        "state": "West Bengal"
    },
    {
        "id": "232",
        "name": "Sasaram",
        "state": "Bihar"
    },
    {
        "id": "233",
        "name": "Hajipur",
        "state": "Bihar"
    },
    {
        "id": "234",
        "name": "Bhuj",
        "state": "Gujarat"
    },
    {
        "id": "235",
        "name": "Shivpuri",
        "state": "Madhya Pradesh"
    },
    {
        "id": "236",
        "name": "Ranaghat",
        "state": "West Bengal"
    },
    {
        "id": "237",
        "name": "Shimla",
        "state": "Himachal Pradesh"
    },
    {
        "id": "238",
        "name": "Tiruvannamalai",
        "state": "Tamil Nadu"
    },
    {
        "id": "239",
        "name": "Kaithal",
        "state": "Haryana"
    },
    {
        "id": "240",
        "name": "Rajnandgaon",
        "state": "Chhattisgarh"
    },
    {
        "id": "241",
        "name": "Godhra",
        "state": "Gujarat"
    },
    {
        "id": "242",
        "name": "Hazaribag",
        "state": "Jharkhand"
    },
    {
        "id": "243",
        "name": "Bhimavaram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "244",
        "name": "Mandsaur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "245",
        "name": "Dibrugarh",
        "state": "Assam"
    },
    {
        "id": "246",
        "name": "Kolar",
        "state": "Karnataka"
    },
    {
        "id": "247",
        "name": "Bankura",
        "state": "West Bengal"
    },
    {
        "id": "248",
        "name": "Mandya",
        "state": "Karnataka"
    },
    {
        "id": "249",
        "name": "Dehri-on-Sone",
        "state": "Bihar"
    },
    {
        "id": "250",
        "name": "Madanapalle",
        "state": "Andhra Pradesh"
    },
    {
        "id": "251",
        "name": "Malerkotla",
        "state": "Punjab"
    },
    {
        "id": "252",
        "name": "Lalitpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "253",
        "name": "Bettiah",
        "state": "Bihar"
    },
    {
        "id": "254",
        "name": "Pollachi",
        "state": "Tamil Nadu"
    },
    {
        "id": "255",
        "name": "Khanna",
        "state": "Punjab"
    },
    {
        "id": "256",
        "name": "Neemuch",
        "state": "Madhya Pradesh"
    },
    {
        "id": "257",
        "name": "Palwal",
        "state": "Haryana"
    },
    {
        "id": "258",
        "name": "Palanpur",
        "state": "Gujarat"
    },
    {
        "id": "259",
        "name": "Guntakal",
        "state": "Andhra Pradesh"
    },
    {
        "id": "260",
        "name": "Nabadwip",
        "state": "West Bengal"
    },
    {
        "id": "261",
        "name": "Udupi",
        "state": "Karnataka"
    },
    {
        "id": "262",
        "name": "Jagdalpur",
        "state": "Chhattisgarh"
    },
    {
        "id": "263",
        "name": "Motihari",
        "state": "Bihar"
    },
    {
        "id": "264",
        "name": "Pilibhit",
        "state": "Uttar Pradesh"
    },
    {
        "id": "265",
        "name": "Dimapur",
        "state": "Nagaland"
    },
    {
        "id": "266",
        "name": "Mohali",
        "state": "Punjab"
    },
    {
        "id": "267",
        "name": "Sadulpur",
        "state": "Rajasthan"
    },
    {
        "id": "268",
        "name": "Rajapalayam",
        "state": "Tamil Nadu"
    },
    {
        "id": "269",
        "name": "Dharmavaram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "270",
        "name": "Kashipur",
        "state": "Uttarakhand"
    },
    {
        "id": "271",
        "name": "Sivakasi",
        "state": "Tamil Nadu"
    },
    {
        "id": "272",
        "name": "Darjiling",
        "state": "West Bengal"
    },
    {
        "id": "273",
        "name": "Chikkamagaluru",
        "state": "Karnataka"
    },
    {
        "id": "274",
        "name": "Gudivada",
        "state": "Andhra Pradesh"
    },
    {
        "id": "275",
        "name": "Baleshwar Town",
        "state": "Odisha"
    },
    {
        "id": "276",
        "name": "Mancherial",
        "state": "Telangana"
    },
    {
        "id": "277",
        "name": "Srikakulam",
        "state": "Andhra Pradesh"
    },
    {
        "id": "278",
        "name": "Adilabad",
        "state": "Telangana"
    },
    {
        "id": "279",
        "name": "Yavatmal",
        "state": "Maharashtra"
    },
    {
        "id": "280",
        "name": "Barnala",
        "state": "Punjab"
    },
    {
        "id": "281",
        "name": "Nagaon",
        "state": "Assam"
    },
    {
        "id": "282",
        "name": "Narasaraopet",
        "state": "Andhra Pradesh"
    },
    {
        "id": "283",
        "name": "Raigarh",
        "state": "Chhattisgarh"
    },
    {
        "id": "284",
        "name": "Roorkee",
        "state": "Uttarakhand"
    },
    {
        "id": "285",
        "name": "Valsad",
        "state": "Gujarat"
    },
    {
        "id": "286",
        "name": "Ambikapur",
        "state": "Chhattisgarh"
    },
    {
        "id": "287",
        "name": "Giridih",
        "state": "Jharkhand"
    },
    {
        "id": "288",
        "name": "Chandausi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "289",
        "name": "Purulia",
        "state": "West Bengal"
    },
    {
        "id": "290",
        "name": "Patan",
        "state": "Gujarat"
    },
    {
        "id": "291",
        "name": "Bagaha",
        "state": "Bihar"
    },
    {
        "id": "292",
        "name": "Hardoi ",
        "state": "Uttar Pradesh"
    },
    {
        "id": "293",
        "name": "Achalpur",
        "state": "Maharashtra"
    },
    {
        "id": "294",
        "name": "Osmanabad",
        "state": "Maharashtra"
    },
    {
        "id": "295",
        "name": "Deesa",
        "state": "Gujarat"
    },
    {
        "id": "296",
        "name": "Nandurbar",
        "state": "Maharashtra"
    },
    {
        "id": "297",
        "name": "Azamgarh",
        "state": "Uttar Pradesh"
    },
    {
        "id": "298",
        "name": "Ramgarh",
        "state": "Jharkhand"
    },
    {
        "id": "299",
        "name": "Firozpur",
        "state": "Punjab"
    },
    {
        "id": "300",
        "name": "Baripada Town",
        "state": "Odisha"
    },
    {
        "id": "301",
        "name": "Karwar",
        "state": "Karnataka"
    },
    {
        "id": "302",
        "name": "Siwan",
        "state": "Bihar"
    },
    {
        "id": "303",
        "name": "Rajampet",
        "state": "Andhra Pradesh"
    },
    {
        "id": "304",
        "name": "Pudukkottai",
        "state": "Tamil Nadu"
    },
    {
        "id": "305",
        "name": "Anantnag",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "306",
        "name": "Tadpatri",
        "state": "Andhra Pradesh"
    },
    {
        "id": "307",
        "name": "Satara",
        "state": "Maharashtra"
    },
    {
        "id": "308",
        "name": "Bhadrak",
        "state": "Odisha"
    },
    {
        "id": "309",
        "name": "Kishanganj",
        "state": "Bihar"
    },
    {
        "id": "310",
        "name": "Suryapet",
        "state": "Telangana"
    },
    {
        "id": "311",
        "name": "Wardha",
        "state": "Maharashtra"
    },
    {
        "id": "312",
        "name": "Ranebennuru",
        "state": "Karnataka"
    },
    {
        "id": "313",
        "name": "Amreli",
        "state": "Gujarat"
    },
    {
        "id": "314",
        "name": "Neyveli (TS)",
        "state": "Tamil Nadu"
    },
    {
        "id": "315",
        "name": "Jamalpur",
        "state": "Bihar"
    },
    {
        "id": "316",
        "name": "Marmagao",
        "state": "Goa"
    },
    {
        "id": "317",
        "name": "Udgir",
        "state": "Maharashtra"
    },
    {
        "id": "318",
        "name": "Tadepalligudem",
        "state": "Andhra Pradesh"
    },
    {
        "id": "319",
        "name": "Nagapattinam",
        "state": "Tamil Nadu"
    },
    {
        "id": "320",
        "name": "Buxar",
        "state": "Bihar"
    },
    {
        "id": "321",
        "name": "Aurangabad",
        "state": "Maharashtra"
    },
    {
        "id": "322",
        "name": "Jehanabad",
        "state": "Bihar"
    },
    {
        "id": "323",
        "name": "Phagwara",
        "state": "Punjab"
    },
    {
        "id": "324",
        "name": "Khair",
        "state": "Uttar Pradesh"
    },
    {
        "id": "325",
        "name": "Sawai Madhopur",
        "state": "Rajasthan"
    },
    {
        "id": "326",
        "name": "Kapurthala",
        "state": "Punjab"
    },
    {
        "id": "327",
        "name": "Chilakaluripet",
        "state": "Andhra Pradesh"
    },
    {
        "id": "328",
        "name": "Aurangabad",
        "state": "Bihar"
    },
    {
        "id": "329",
        "name": "Malappuram",
        "state": "Kerala"
    },
    {
        "id": "330",
        "name": "Rewari",
        "state": "Haryana"
    },
    {
        "id": "331",
        "name": "Nagaur",
        "state": "Rajasthan"
    },
    {
        "id": "332",
        "name": "Sultanpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "333",
        "name": "Nagda",
        "state": "Madhya Pradesh"
    },
    {
        "id": "334",
        "name": "Port Blair",
        "state": "Andaman and Nicobar Islands"
    },
    {
        "id": "335",
        "name": "Lakhisarai",
        "state": "Bihar"
    },
    {
        "id": "336",
        "name": "Panaji",
        "state": "Goa"
    },
    {
        "id": "337",
        "name": "Tinsukia",
        "state": "Assam"
    },
    {
        "id": "338",
        "name": "Itarsi",
        "state": "Madhya Pradesh"
    },
    {
        "id": "339",
        "name": "Kohima",
        "state": "Nagaland"
    },
    {
        "id": "340",
        "name": "Balangir",
        "state": "Odisha"
    },
    {
        "id": "341",
        "name": "Nawada",
        "state": "Bihar"
    },
    {
        "id": "342",
        "name": "Jharsuguda",
        "state": "Odisha"
    },
    {
        "id": "343",
        "name": "Jagtial",
        "state": "Telangana"
    },
    {
        "id": "344",
        "name": "Viluppuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "345",
        "name": "Amalner",
        "state": "Maharashtra"
    },
    {
        "id": "346",
        "name": "Zirakpur",
        "state": "Punjab"
    },
    {
        "id": "347",
        "name": "Tanda",
        "state": "Uttar Pradesh"
    },
    {
        "id": "348",
        "name": "Tiruchengode",
        "state": "Tamil Nadu"
    },
    {
        "id": "349",
        "name": "Nagina",
        "state": "Uttar Pradesh"
    },
    {
        "id": "350",
        "name": "Yemmiganur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "351",
        "name": "Vaniyambadi",
        "state": "Tamil Nadu"
    },
    {
        "id": "352",
        "name": "Sarni",
        "state": "Madhya Pradesh"
    },
    {
        "id": "353",
        "name": "Theni Allinagaram",
        "state": "Tamil Nadu"
    },
    {
        "id": "354",
        "name": "Margao",
        "state": "Goa"
    },
    {
        "id": "355",
        "name": "Akot",
        "state": "Maharashtra"
    },
    {
        "id": "356",
        "name": "Sehore",
        "state": "Madhya Pradesh"
    },
    {
        "id": "357",
        "name": "Mhow Cantonment",
        "state": "Madhya Pradesh"
    },
    {
        "id": "358",
        "name": "Kot Kapura",
        "state": "Punjab"
    },
    {
        "id": "359",
        "name": "Makrana",
        "state": "Rajasthan"
    },
    {
        "id": "360",
        "name": "Pandharpur",
        "state": "Maharashtra"
    },
    {
        "id": "361",
        "name": "Miryalaguda",
        "state": "Telangana"
    },
    {
        "id": "362",
        "name": "Shamli",
        "state": "Uttar Pradesh"
    },
    {
        "id": "363",
        "name": "Seoni",
        "state": "Madhya Pradesh"
    },
    {
        "id": "364",
        "name": "Ranibennur",
        "state": "Karnataka"
    },
    {
        "id": "365",
        "name": "Kadiri",
        "state": "Andhra Pradesh"
    },
    {
        "id": "366",
        "name": "Shrirampur",
        "state": "Maharashtra"
    },
    {
        "id": "367",
        "name": "Rudrapur",
        "state": "Uttarakhand"
    },
    {
        "id": "368",
        "name": "Parli",
        "state": "Maharashtra"
    },
    {
        "id": "369",
        "name": "Najibabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "370",
        "name": "Nirmal",
        "state": "Telangana"
    },
    {
        "id": "371",
        "name": "Udhagamandalam",
        "state": "Tamil Nadu"
    },
    {
        "id": "372",
        "name": "Shikohabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "373",
        "name": "Jhumri Tilaiya",
        "state": "Jharkhand"
    },
    {
        "id": "374",
        "name": "Aruppukkottai",
        "state": "Tamil Nadu"
    },
    {
        "id": "375",
        "name": "Ponnani",
        "state": "Kerala"
    },
    {
        "id": "376",
        "name": "Jamui",
        "state": "Bihar"
    },
    {
        "id": "377",
        "name": "Sitamarhi",
        "state": "Bihar"
    },
    {
        "id": "378",
        "name": "Chirala",
        "state": "Andhra Pradesh"
    },
    {
        "id": "379",
        "name": "Anjar",
        "state": "Gujarat"
    },
    {
        "id": "380",
        "name": "Karaikal",
        "state": "Puducherry"
    },
    {
        "id": "381",
        "name": "Hansi",
        "state": "Haryana"
    },
    {
        "id": "382",
        "name": "Anakapalle",
        "state": "Andhra Pradesh"
    },
    {
        "id": "383",
        "name": "Mahasamund",
        "state": "Chhattisgarh"
    },
    {
        "id": "384",
        "name": "Faridkot",
        "state": "Punjab"
    },
    {
        "id": "385",
        "name": "Saunda",
        "state": "Jharkhand"
    },
    {
        "id": "386",
        "name": "Dhoraji",
        "state": "Gujarat"
    },
    {
        "id": "387",
        "name": "Paramakudi",
        "state": "Tamil Nadu"
    },
    {
        "id": "388",
        "name": "Balaghat",
        "state": "Madhya Pradesh"
    },
    {
        "id": "389",
        "name": "Sujangarh",
        "state": "Rajasthan"
    },
    {
        "id": "390",
        "name": "Khambhat",
        "state": "Gujarat"
    },
    {
        "id": "391",
        "name": "Muktsar",
        "state": "Punjab"
    },
    {
        "id": "392",
        "name": "Rajpura",
        "state": "Punjab"
    },
    {
        "id": "393",
        "name": "Kavali",
        "state": "Andhra Pradesh"
    },
    {
        "id": "394",
        "name": "Dhamtari",
        "state": "Chhattisgarh"
    },
    {
        "id": "395",
        "name": "Ashok Nagar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "396",
        "name": "Sardarshahar",
        "state": "Rajasthan"
    },
    {
        "id": "397",
        "name": "Mahuva",
        "state": "Gujarat"
    },
    {
        "id": "398",
        "name": "Bargarh",
        "state": "Odisha"
    },
    {
        "id": "399",
        "name": "Kamareddy",
        "state": "Telangana"
    },
    {
        "id": "400",
        "name": "Sahibganj",
        "state": "Jharkhand"
    },
    {
        "id": "401",
        "name": "Kothagudem",
        "state": "Telangana"
    },
    {
        "id": "402",
        "name": "Ramanagaram",
        "state": "Karnataka"
    },
    {
        "id": "403",
        "name": "Gokak",
        "state": "Karnataka"
    },
    {
        "id": "404",
        "name": "Tikamgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "405",
        "name": "Araria",
        "state": "Bihar"
    },
    {
        "id": "406",
        "name": "Rishikesh",
        "state": "Uttarakhand"
    },
    {
        "id": "407",
        "name": "Shahdol",
        "state": "Madhya Pradesh"
    },
    {
        "id": "408",
        "name": "Medininagar (Daltonganj)",
        "state": "Jharkhand"
    },
    {
        "id": "409",
        "name": "Arakkonam",
        "state": "Tamil Nadu"
    },
    {
        "id": "410",
        "name": "Washim",
        "state": "Maharashtra"
    },
    {
        "id": "411",
        "name": "Sangrur",
        "state": "Punjab"
    },
    {
        "id": "412",
        "name": "Bodhan",
        "state": "Telangana"
    },
    {
        "id": "413",
        "name": "Fazilka",
        "state": "Punjab"
    },
    {
        "id": "414",
        "name": "Palacole",
        "state": "Andhra Pradesh"
    },
    {
        "id": "415",
        "name": "Keshod",
        "state": "Gujarat"
    },
    {
        "id": "416",
        "name": "Sullurpeta",
        "state": "Andhra Pradesh"
    },
    {
        "id": "417",
        "name": "Wadhwan",
        "state": "Gujarat"
    },
    {
        "id": "418",
        "name": "Gurdaspur",
        "state": "Punjab"
    },
    {
        "id": "419",
        "name": "Vatakara",
        "state": "Kerala"
    },
    {
        "id": "420",
        "name": "Tura",
        "state": "Meghalaya"
    },
    {
        "id": "421",
        "name": "Narnaul",
        "state": "Haryana"
    },
    {
        "id": "422",
        "name": "Kharar",
        "state": "Punjab"
    },
    {
        "id": "423",
        "name": "Yadgir",
        "state": "Karnataka"
    },
    {
        "id": "424",
        "name": "Ambejogai",
        "state": "Maharashtra"
    },
    {
        "id": "425",
        "name": "Ankleshwar",
        "state": "Gujarat"
    },
    {
        "id": "426",
        "name": "Savarkundla",
        "state": "Gujarat"
    },
    {
        "id": "427",
        "name": "Paradip",
        "state": "Odisha"
    },
    {
        "id": "428",
        "name": "Virudhachalam",
        "state": "Tamil Nadu"
    },
    {
        "id": "429",
        "name": "Kanhangad",
        "state": "Kerala"
    },
    {
        "id": "430",
        "name": "Kadi",
        "state": "Gujarat"
    },
    {
        "id": "431",
        "name": "Srivilliputhur",
        "state": "Tamil Nadu"
    },
    {
        "id": "432",
        "name": "Gobindgarh",
        "state": "Punjab"
    },
    {
        "id": "433",
        "name": "Tindivanam",
        "state": "Tamil Nadu"
    },
    {
        "id": "434",
        "name": "Mansa",
        "state": "Punjab"
    },
    {
        "id": "435",
        "name": "Taliparamba",
        "state": "Kerala"
    },
    {
        "id": "436",
        "name": "Manmad",
        "state": "Maharashtra"
    },
    {
        "id": "437",
        "name": "Tanuku",
        "state": "Andhra Pradesh"
    },
    {
        "id": "438",
        "name": "Rayachoti",
        "state": "Andhra Pradesh"
    },
    {
        "id": "439",
        "name": "Virudhunagar",
        "state": "Tamil Nadu"
    },
    {
        "id": "440",
        "name": "Koyilandy",
        "state": "Kerala"
    },
    {
        "id": "441",
        "name": "Jorhat",
        "state": "Assam"
    },
    {
        "id": "442",
        "name": "Karur",
        "state": "Tamil Nadu"
    },
    {
        "id": "443",
        "name": "Valparai",
        "state": "Tamil Nadu"
    },
    {
        "id": "444",
        "name": "Srikalahasti",
        "state": "Andhra Pradesh"
    },
    {
        "id": "445",
        "name": "Neyyattinkara",
        "state": "Kerala"
    },
    {
        "id": "446",
        "name": "Bapatla",
        "state": "Andhra Pradesh"
    },
    {
        "id": "447",
        "name": "Fatehabad",
        "state": "Haryana"
    },
    {
        "id": "448",
        "name": "Malout",
        "state": "Punjab"
    },
    {
        "id": "449",
        "name": "Sankarankovil",
        "state": "Tamil Nadu"
    },
    {
        "id": "450",
        "name": "Tenkasi",
        "state": "Tamil Nadu"
    },
    {
        "id": "451",
        "name": "Ratnagiri",
        "state": "Maharashtra"
    },
    {
        "id": "452",
        "name": "Rabkavi Banhatti",
        "state": "Karnataka"
    },
    {
        "id": "453",
        "name": "Sikandrabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "454",
        "name": "Chaibasa",
        "state": "Jharkhand"
    },
    {
        "id": "455",
        "name": "Chirmiri",
        "state": "Chhattisgarh"
    },
    {
        "id": "456",
        "name": "Palwancha",
        "state": "Telangana"
    },
    {
        "id": "457",
        "name": "Bhawanipatna",
        "state": "Odisha"
    },
    {
        "id": "458",
        "name": "Kayamkulam",
        "state": "Kerala"
    },
    {
        "id": "459",
        "name": "Pithampur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "460",
        "name": "Nabha",
        "state": "Punjab"
    },
    {
        "id": "461",
        "name": "Shahabad, Hardoi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "462",
        "name": "Dhenkanal",
        "state": "Odisha"
    },
    {
        "id": "463",
        "name": "Uran Islampur",
        "state": "Maharashtra"
    },
    {
        "id": "464",
        "name": "Gopalganj",
        "state": "Bihar"
    },
    {
        "id": "465",
        "name": "Bongaigaon City",
        "state": "Assam"
    },
    {
        "id": "466",
        "name": "Palani",
        "state": "Tamil Nadu"
    },
    {
        "id": "467",
        "name": "Pusad",
        "state": "Maharashtra"
    },
    {
        "id": "468",
        "name": "Sopore",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "469",
        "name": "Pilkhuwa",
        "state": "Uttar Pradesh"
    },
    {
        "id": "470",
        "name": "Tarn Taran",
        "state": "Punjab"
    },
    {
        "id": "471",
        "name": "Renukoot",
        "state": "Uttar Pradesh"
    },
    {
        "id": "472",
        "name": "Mandamarri",
        "state": "Telangana"
    },
    {
        "id": "473",
        "name": "Shahabad",
        "state": "Karnataka"
    },
    {
        "id": "474",
        "name": "Barbil",
        "state": "Odisha"
    },
    {
        "id": "475",
        "name": "Koratla",
        "state": "Telangana"
    },
    {
        "id": "476",
        "name": "Madhubani",
        "state": "Bihar"
    },
    {
        "id": "477",
        "name": "Arambagh",
        "state": "West Bengal"
    },
    {
        "id": "478",
        "name": "Gohana",
        "state": "Haryana"
    },
    {
        "id": "479",
        "name": "Ladnu",
        "state": "Rajasthan"
    },
    {
        "id": "480",
        "name": "Pattukkottai",
        "state": "Tamil Nadu"
    },
    {
        "id": "481",
        "name": "Sirsi",
        "state": "Karnataka"
    },
    {
        "id": "482",
        "name": "Sircilla",
        "state": "Telangana"
    },
    {
        "id": "483",
        "name": "Tamluk",
        "state": "West Bengal"
    },
    {
        "id": "484",
        "name": "Jagraon",
        "state": "Punjab"
    },
    {
        "id": "485",
        "name": "AlipurdUrban Agglomerationr",
        "state": "West Bengal"
    },
    {
        "id": "486",
        "name": "Alirajpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "487",
        "name": "Tandur",
        "state": "Telangana"
    },
    {
        "id": "488",
        "name": "Naidupet",
        "state": "Andhra Pradesh"
    },
    {
        "id": "489",
        "name": "Tirupathur",
        "state": "Tamil Nadu"
    },
    {
        "id": "490",
        "name": "Tohana",
        "state": "Haryana"
    },
    {
        "id": "491",
        "name": "Ratangarh",
        "state": "Rajasthan"
    },
    {
        "id": "492",
        "name": "Dhubri",
        "state": "Assam"
    },
    {
        "id": "493",
        "name": "Masaurhi",
        "state": "Bihar"
    },
    {
        "id": "494",
        "name": "Visnagar",
        "state": "Gujarat"
    },
    {
        "id": "495",
        "name": "Vrindavan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "496",
        "name": "Nokha",
        "state": "Rajasthan"
    },
    {
        "id": "497",
        "name": "Nagari",
        "state": "Andhra Pradesh"
    },
    {
        "id": "498",
        "name": "Narwana",
        "state": "Haryana"
    },
    {
        "id": "499",
        "name": "Ramanathapuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "500",
        "name": "Ujhani",
        "state": "Uttar Pradesh"
    },
    {
        "id": "501",
        "name": "Samastipur",
        "state": "Bihar"
    },
    {
        "id": "502",
        "name": "Laharpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "503",
        "name": "Sangamner",
        "state": "Maharashtra"
    },
    {
        "id": "504",
        "name": "Nimbahera",
        "state": "Rajasthan"
    },
    {
        "id": "505",
        "name": "Siddipet",
        "state": "Telangana"
    },
    {
        "id": "506",
        "name": "Suri",
        "state": "West Bengal"
    },
    {
        "id": "507",
        "name": "Diphu",
        "state": "Assam"
    },
    {
        "id": "508",
        "name": "Jhargram",
        "state": "West Bengal"
    },
    {
        "id": "509",
        "name": "Shirpur-Warwade",
        "state": "Maharashtra"
    },
    {
        "id": "510",
        "name": "Tilhar",
        "state": "Uttar Pradesh"
    },
    {
        "id": "511",
        "name": "Sindhnur",
        "state": "Karnataka"
    },
    {
        "id": "512",
        "name": "Udumalaipettai",
        "state": "Tamil Nadu"
    },
    {
        "id": "513",
        "name": "Malkapur",
        "state": "Maharashtra"
    },
    {
        "id": "514",
        "name": "Wanaparthy",
        "state": "Telangana"
    },
    {
        "id": "515",
        "name": "Gudur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "516",
        "name": "Kendujhar",
        "state": "Odisha"
    },
    {
        "id": "517",
        "name": "Mandla",
        "state": "Madhya Pradesh"
    },
    {
        "id": "518",
        "name": "Mandi",
        "state": "Himachal Pradesh"
    },
    {
        "id": "519",
        "name": "Nedumangad",
        "state": "Kerala"
    },
    {
        "id": "520",
        "name": "North Lakhimpur",
        "state": "Assam"
    },
    {
        "id": "521",
        "name": "Vinukonda",
        "state": "Andhra Pradesh"
    },
    {
        "id": "522",
        "name": "Tiptur",
        "state": "Karnataka"
    },
    {
        "id": "523",
        "name": "Gobichettipalayam",
        "state": "Tamil Nadu"
    },
    {
        "id": "524",
        "name": "Sunabeda",
        "state": "Odisha"
    },
    {
        "id": "525",
        "name": "Wani",
        "state": "Maharashtra"
    },
    {
        "id": "526",
        "name": "Upleta",
        "state": "Gujarat"
    },
    {
        "id": "527",
        "name": "Narasapuram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "528",
        "name": "Nuzvid",
        "state": "Andhra Pradesh"
    },
    {
        "id": "529",
        "name": "Tezpur",
        "state": "Assam"
    },
    {
        "id": "530",
        "name": "Una",
        "state": "Gujarat"
    },
    {
        "id": "531",
        "name": "Markapur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "532",
        "name": "Sheopur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "533",
        "name": "Thiruvarur",
        "state": "Tamil Nadu"
    },
    {
        "id": "534",
        "name": "Sidhpur",
        "state": "Gujarat"
    },
    {
        "id": "535",
        "name": "Sahaswan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "536",
        "name": "Suratgarh",
        "state": "Rajasthan"
    },
    {
        "id": "537",
        "name": "Shajapur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "538",
        "name": "Rayagada",
        "state": "Odisha"
    },
    {
        "id": "539",
        "name": "Lonavla",
        "state": "Maharashtra"
    },
    {
        "id": "540",
        "name": "Ponnur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "541",
        "name": "Kagaznagar",
        "state": "Telangana"
    },
    {
        "id": "542",
        "name": "Gadwal",
        "state": "Telangana"
    },
    {
        "id": "543",
        "name": "Bhatapara",
        "state": "Chhattisgarh"
    },
    {
        "id": "544",
        "name": "Kandukur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "545",
        "name": "Sangareddy",
        "state": "Telangana"
    },
    {
        "id": "546",
        "name": "Unjha",
        "state": "Gujarat"
    },
    {
        "id": "547",
        "name": "Lunglei",
        "state": "Mizoram"
    },
    {
        "id": "548",
        "name": "Karimganj",
        "state": "Assam"
    },
    {
        "id": "549",
        "name": "Kannur",
        "state": "Kerala"
    },
    {
        "id": "550",
        "name": "Bobbili",
        "state": "Andhra Pradesh"
    },
    {
        "id": "551",
        "name": "Mokameh",
        "state": "Bihar"
    },
    {
        "id": "552",
        "name": "Talegaon Dabhade",
        "state": "Maharashtra"
    },
    {
        "id": "553",
        "name": "Anjangaon",
        "state": "Maharashtra"
    },
    {
        "id": "554",
        "name": "Mangrol",
        "state": "Gujarat"
    },
    {
        "id": "555",
        "name": "Sunam",
        "state": "Punjab"
    },
    {
        "id": "556",
        "name": "Gangarampur",
        "state": "West Bengal"
    },
    {
        "id": "557",
        "name": "Thiruvallur",
        "state": "Tamil Nadu"
    },
    {
        "id": "558",
        "name": "Tirur",
        "state": "Kerala"
    },
    {
        "id": "559",
        "name": "Rath",
        "state": "Uttar Pradesh"
    },
    {
        "id": "560",
        "name": "Jatani",
        "state": "Odisha"
    },
    {
        "id": "561",
        "name": "Viramgam",
        "state": "Gujarat"
    },
    {
        "id": "562",
        "name": "Rajsamand",
        "state": "Rajasthan"
    },
    {
        "id": "563",
        "name": "Yanam",
        "state": "Puducherry"
    },
    {
        "id": "564",
        "name": "Kottayam",
        "state": "Kerala"
    },
    {
        "id": "565",
        "name": "Panruti",
        "state": "Tamil Nadu"
    },
    {
        "id": "566",
        "name": "Dhuri",
        "state": "Punjab"
    },
    {
        "id": "567",
        "name": "Namakkal",
        "state": "Tamil Nadu"
    },
    {
        "id": "568",
        "name": "Kasaragod",
        "state": "Kerala"
    },
    {
        "id": "569",
        "name": "Modasa",
        "state": "Gujarat"
    },
    {
        "id": "570",
        "name": "Rayadurg",
        "state": "Andhra Pradesh"
    },
    {
        "id": "571",
        "name": "Supaul",
        "state": "Bihar"
    },
    {
        "id": "572",
        "name": "Kunnamkulam",
        "state": "Kerala"
    },
    {
        "id": "573",
        "name": "Umred",
        "state": "Maharashtra"
    },
    {
        "id": "574",
        "name": "Bellampalle",
        "state": "Telangana"
    },
    {
        "id": "575",
        "name": "Sibsagar",
        "state": "Assam"
    },
    {
        "id": "576",
        "name": "Mandi Dabwali",
        "state": "Haryana"
    },
    {
        "id": "577",
        "name": "Ottappalam",
        "state": "Kerala"
    },
    {
        "id": "578",
        "name": "Dumraon",
        "state": "Bihar"
    },
    {
        "id": "579",
        "name": "Samalkot",
        "state": "Andhra Pradesh"
    },
    {
        "id": "580",
        "name": "Jaggaiahpet",
        "state": "Andhra Pradesh"
    },
    {
        "id": "581",
        "name": "Goalpara",
        "state": "Assam"
    },
    {
        "id": "582",
        "name": "Tuni",
        "state": "Andhra Pradesh"
    },
    {
        "id": "583",
        "name": "Lachhmangarh",
        "state": "Rajasthan"
    },
    {
        "id": "584",
        "name": "Bhongir",
        "state": "Telangana"
    },
    {
        "id": "585",
        "name": "Amalapuram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "586",
        "name": "Firozpur Cantt.",
        "state": "Punjab"
    },
    {
        "id": "587",
        "name": "Vikarabad",
        "state": "Telangana"
    },
    {
        "id": "588",
        "name": "Thiruvalla",
        "state": "Kerala"
    },
    {
        "id": "589",
        "name": "Sherkot",
        "state": "Uttar Pradesh"
    },
    {
        "id": "590",
        "name": "Palghar",
        "state": "Maharashtra"
    },
    {
        "id": "591",
        "name": "Shegaon",
        "state": "Maharashtra"
    },
    {
        "id": "592",
        "name": "Jangaon",
        "state": "Telangana"
    },
    {
        "id": "593",
        "name": "Bheemunipatnam",
        "state": "Andhra Pradesh"
    },
    {
        "id": "594",
        "name": "Panna",
        "state": "Madhya Pradesh"
    },
    {
        "id": "595",
        "name": "Thodupuzha",
        "state": "Kerala"
    },
    {
        "id": "596",
        "name": "KathUrban Agglomeration",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "597",
        "name": "Palitana",
        "state": "Gujarat"
    },
    {
        "id": "598",
        "name": "Arwal",
        "state": "Bihar"
    },
    {
        "id": "599",
        "name": "Venkatagiri",
        "state": "Andhra Pradesh"
    },
    {
        "id": "600",
        "name": "Kalpi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "601",
        "name": "Rajgarh (Churu)",
        "state": "Rajasthan"
    },
    {
        "id": "602",
        "name": "Sattenapalle",
        "state": "Andhra Pradesh"
    },
    {
        "id": "603",
        "name": "Arsikere",
        "state": "Karnataka"
    },
    {
        "id": "604",
        "name": "Ozar",
        "state": "Maharashtra"
    },
    {
        "id": "605",
        "name": "Thirumangalam",
        "state": "Tamil Nadu"
    },
    {
        "id": "606",
        "name": "Petlad",
        "state": "Gujarat"
    },
    {
        "id": "607",
        "name": "Nasirabad",
        "state": "Rajasthan"
    },
    {
        "id": "608",
        "name": "Phaltan",
        "state": "Maharashtra"
    },
    {
        "id": "609",
        "name": "Rampurhat",
        "state": "West Bengal"
    },
    {
        "id": "610",
        "name": "Nanjangud",
        "state": "Karnataka"
    },
    {
        "id": "611",
        "name": "Forbesganj",
        "state": "Bihar"
    },
    {
        "id": "612",
        "name": "Tundla",
        "state": "Uttar Pradesh"
    },
    {
        "id": "613",
        "name": "BhabUrban Agglomeration",
        "state": "Bihar"
    },
    {
        "id": "614",
        "name": "Sagara",
        "state": "Karnataka"
    },
    {
        "id": "615",
        "name": "Pithapuram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "616",
        "name": "Sira",
        "state": "Karnataka"
    },
    {
        "id": "617",
        "name": "Bhadrachalam",
        "state": "Telangana"
    },
    {
        "id": "618",
        "name": "Charkhi Dadri",
        "state": "Haryana"
    },
    {
        "id": "619",
        "name": "Chatra",
        "state": "Jharkhand"
    },
    {
        "id": "620",
        "name": "Palasa Kasibugga",
        "state": "Andhra Pradesh"
    },
    {
        "id": "621",
        "name": "Nohar",
        "state": "Rajasthan"
    },
    {
        "id": "622",
        "name": "Yevla",
        "state": "Maharashtra"
    },
    {
        "id": "623",
        "name": "Sirhind Fatehgarh Sahib",
        "state": "Punjab"
    },
    {
        "id": "624",
        "name": "Bhainsa",
        "state": "Telangana"
    },
    {
        "id": "625",
        "name": "Parvathipuram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "626",
        "name": "Shahade",
        "state": "Maharashtra"
    },
    {
        "id": "627",
        "name": "Chalakudy",
        "state": "Kerala"
    },
    {
        "id": "628",
        "name": "Narkatiaganj",
        "state": "Bihar"
    },
    {
        "id": "629",
        "name": "Kapadvanj",
        "state": "Gujarat"
    },
    {
        "id": "630",
        "name": "Macherla",
        "state": "Andhra Pradesh"
    },
    {
        "id": "631",
        "name": "Raghogarh-Vijaypur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "632",
        "name": "Rupnagar",
        "state": "Punjab"
    },
    {
        "id": "633",
        "name": "Naugachhia",
        "state": "Bihar"
    },
    {
        "id": "634",
        "name": "Sendhwa",
        "state": "Madhya Pradesh"
    },
    {
        "id": "635",
        "name": "Byasanagar",
        "state": "Odisha"
    },
    {
        "id": "636",
        "name": "Sandila",
        "state": "Uttar Pradesh"
    },
    {
        "id": "637",
        "name": "Gooty",
        "state": "Andhra Pradesh"
    },
    {
        "id": "638",
        "name": "Salur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "639",
        "name": "Nanpara",
        "state": "Uttar Pradesh"
    },
    {
        "id": "640",
        "name": "Sardhana",
        "state": "Uttar Pradesh"
    },
    {
        "id": "641",
        "name": "Vita",
        "state": "Maharashtra"
    },
    {
        "id": "642",
        "name": "Gumia",
        "state": "Jharkhand"
    },
    {
        "id": "643",
        "name": "Puttur",
        "state": "Karnataka"
    },
    {
        "id": "644",
        "name": "Jalandhar Cantt.",
        "state": "Punjab"
    },
    {
        "id": "645",
        "name": "Nehtaur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "646",
        "name": "Changanassery",
        "state": "Kerala"
    },
    {
        "id": "647",
        "name": "Mandapeta",
        "state": "Andhra Pradesh"
    },
    {
        "id": "648",
        "name": "Dumka",
        "state": "Jharkhand"
    },
    {
        "id": "649",
        "name": "Seohara",
        "state": "Uttar Pradesh"
    },
    {
        "id": "650",
        "name": "Umarkhed",
        "state": "Maharashtra"
    },
    {
        "id": "651",
        "name": "Madhupur",
        "state": "Jharkhand"
    },
    {
        "id": "652",
        "name": "Vikramasingapuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "653",
        "name": "Punalur",
        "state": "Kerala"
    },
    {
        "id": "654",
        "name": "Kendrapara",
        "state": "Odisha"
    },
    {
        "id": "655",
        "name": "Sihor",
        "state": "Gujarat"
    },
    {
        "id": "656",
        "name": "Nellikuppam",
        "state": "Tamil Nadu"
    },
    {
        "id": "657",
        "name": "Samana",
        "state": "Punjab"
    },
    {
        "id": "658",
        "name": "Warora",
        "state": "Maharashtra"
    },
    {
        "id": "659",
        "name": "Nilambur",
        "state": "Kerala"
    },
    {
        "id": "660",
        "name": "Rasipuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "661",
        "name": "Ramnagar",
        "state": "Uttarakhand"
    },
    {
        "id": "662",
        "name": "Jammalamadugu",
        "state": "Andhra Pradesh"
    },
    {
        "id": "663",
        "name": "Nawanshahr",
        "state": "Punjab"
    },
    {
        "id": "664",
        "name": "Thoubal",
        "state": "Manipur"
    },
    {
        "id": "665",
        "name": "Athni",
        "state": "Karnataka"
    },
    {
        "id": "666",
        "name": "Cherthala",
        "state": "Kerala"
    },
    {
        "id": "667",
        "name": "Sidhi",
        "state": "Madhya Pradesh"
    },
    {
        "id": "668",
        "name": "Farooqnagar",
        "state": "Telangana"
    },
    {
        "id": "669",
        "name": "Peddapuram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "670",
        "name": "Chirkunda",
        "state": "Jharkhand"
    },
    {
        "id": "671",
        "name": "Pachora",
        "state": "Maharashtra"
    },
    {
        "id": "672",
        "name": "Madhepura",
        "state": "Bihar"
    },
    {
        "id": "673",
        "name": "Pithoragarh",
        "state": "Uttarakhand"
    },
    {
        "id": "674",
        "name": "Tumsar",
        "state": "Maharashtra"
    },
    {
        "id": "675",
        "name": "Phalodi",
        "state": "Rajasthan"
    },
    {
        "id": "676",
        "name": "Tiruttani",
        "state": "Tamil Nadu"
    },
    {
        "id": "677",
        "name": "Rampura Phul",
        "state": "Punjab"
    },
    {
        "id": "678",
        "name": "Perinthalmanna",
        "state": "Kerala"
    },
    {
        "id": "679",
        "name": "Padrauna",
        "state": "Uttar Pradesh"
    },
    {
        "id": "680",
        "name": "Pipariya",
        "state": "Madhya Pradesh"
    },
    {
        "id": "681",
        "name": "Dalli-Rajhara",
        "state": "Chhattisgarh"
    },
    {
        "id": "682",
        "name": "Punganur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "683",
        "name": "Mattannur",
        "state": "Kerala"
    },
    {
        "id": "684",
        "name": "Mathura",
        "state": "Uttar Pradesh"
    },
    {
        "id": "685",
        "name": "Thakurdwara",
        "state": "Uttar Pradesh"
    },
    {
        "id": "686",
        "name": "Nandivaram-Guduvancheri",
        "state": "Tamil Nadu"
    },
    {
        "id": "687",
        "name": "Mulbagal",
        "state": "Karnataka"
    },
    {
        "id": "688",
        "name": "Manjlegaon",
        "state": "Maharashtra"
    },
    {
        "id": "689",
        "name": "Wankaner",
        "state": "Gujarat"
    },
    {
        "id": "690",
        "name": "Sillod",
        "state": "Maharashtra"
    },
    {
        "id": "691",
        "name": "Nidadavole",
        "state": "Andhra Pradesh"
    },
    {
        "id": "692",
        "name": "Surapura",
        "state": "Karnataka"
    },
    {
        "id": "693",
        "name": "Rajagangapur",
        "state": "Odisha"
    },
    {
        "id": "694",
        "name": "Sheikhpura",
        "state": "Bihar"
    },
    {
        "id": "695",
        "name": "Parlakhemundi",
        "state": "Odisha"
    },
    {
        "id": "696",
        "name": "Kalimpong",
        "state": "West Bengal"
    },
    {
        "id": "697",
        "name": "Siruguppa",
        "state": "Karnataka"
    },
    {
        "id": "698",
        "name": "Arvi",
        "state": "Maharashtra"
    },
    {
        "id": "699",
        "name": "Limbdi",
        "state": "Gujarat"
    },
    {
        "id": "700",
        "name": "Barpeta",
        "state": "Assam"
    },
    {
        "id": "701",
        "name": "Manglaur",
        "state": "Uttarakhand"
    },
    {
        "id": "702",
        "name": "Repalle",
        "state": "Andhra Pradesh"
    },
    {
        "id": "703",
        "name": "Mudhol",
        "state": "Karnataka"
    },
    {
        "id": "704",
        "name": "Shujalpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "705",
        "name": "Mandvi",
        "state": "Gujarat"
    },
    {
        "id": "706",
        "name": "Thangadh",
        "state": "Gujarat"
    },
    {
        "id": "707",
        "name": "Sironj",
        "state": "Madhya Pradesh"
    },
    {
        "id": "708",
        "name": "Nandura",
        "state": "Maharashtra"
    },
    {
        "id": "709",
        "name": "Shoranur",
        "state": "Kerala"
    },
    {
        "id": "710",
        "name": "Nathdwara",
        "state": "Rajasthan"
    },
    {
        "id": "711",
        "name": "Periyakulam",
        "state": "Tamil Nadu"
    },
    {
        "id": "712",
        "name": "Sultanganj",
        "state": "Bihar"
    },
    {
        "id": "713",
        "name": "Medak",
        "state": "Telangana"
    },
    {
        "id": "714",
        "name": "Narayanpet",
        "state": "Telangana"
    },
    {
        "id": "715",
        "name": "Raxaul Bazar",
        "state": "Bihar"
    },
    {
        "id": "716",
        "name": "Rajauri",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "717",
        "name": "Pernampattu",
        "state": "Tamil Nadu"
    },
    {
        "id": "718",
        "name": "Nainital",
        "state": "Uttarakhand"
    },
    {
        "id": "719",
        "name": "Ramachandrapuram",
        "state": "Andhra Pradesh"
    },
    {
        "id": "720",
        "name": "Vaijapur",
        "state": "Maharashtra"
    },
    {
        "id": "721",
        "name": "Nangal",
        "state": "Punjab"
    },
    {
        "id": "722",
        "name": "Sidlaghatta",
        "state": "Karnataka"
    },
    {
        "id": "723",
        "name": "Punch",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "724",
        "name": "Pandhurna",
        "state": "Madhya Pradesh"
    },
    {
        "id": "725",
        "name": "Wadgaon Road",
        "state": "Maharashtra"
    },
    {
        "id": "726",
        "name": "Talcher",
        "state": "Odisha"
    },
    {
        "id": "727",
        "name": "Varkala",
        "state": "Kerala"
    },
    {
        "id": "728",
        "name": "Pilani",
        "state": "Rajasthan"
    },
    {
        "id": "729",
        "name": "Nowgong",
        "state": "Madhya Pradesh"
    },
    {
        "id": "730",
        "name": "Naila Janjgir",
        "state": "Chhattisgarh"
    },
    {
        "id": "731",
        "name": "Mapusa",
        "state": "Goa"
    },
    {
        "id": "732",
        "name": "Vellakoil",
        "state": "Tamil Nadu"
    },
    {
        "id": "733",
        "name": "Merta City",
        "state": "Rajasthan"
    },
    {
        "id": "734",
        "name": "Sivaganga",
        "state": "Tamil Nadu"
    },
    {
        "id": "735",
        "name": "Mandideep",
        "state": "Madhya Pradesh"
    },
    {
        "id": "736",
        "name": "Sailu",
        "state": "Maharashtra"
    },
    {
        "id": "737",
        "name": "Vyara",
        "state": "Gujarat"
    },
    {
        "id": "738",
        "name": "Kovvur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "739",
        "name": "Vadalur",
        "state": "Tamil Nadu"
    },
    {
        "id": "740",
        "name": "Nawabganj",
        "state": "Uttar Pradesh"
    },
    {
        "id": "741",
        "name": "Padra",
        "state": "Gujarat"
    },
    {
        "id": "742",
        "name": "Sainthia",
        "state": "West Bengal"
    },
    {
        "id": "743",
        "name": "Siana",
        "state": "Uttar Pradesh"
    },
    {
        "id": "744",
        "name": "Shahpur",
        "state": "Karnataka"
    },
    {
        "id": "745",
        "name": "Sojat",
        "state": "Rajasthan"
    },
    {
        "id": "746",
        "name": "Noorpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "747",
        "name": "Paravoor",
        "state": "Kerala"
    },
    {
        "id": "748",
        "name": "Murtijapur",
        "state": "Maharashtra"
    },
    {
        "id": "749",
        "name": "Ramnagar",
        "state": "Bihar"
    },
    {
        "id": "750",
        "name": "Sundargarh",
        "state": "Odisha"
    },
    {
        "id": "751",
        "name": "Taki",
        "state": "West Bengal"
    },
    {
        "id": "752",
        "name": "Saundatti-Yellamma",
        "state": "Karnataka"
    },
    {
        "id": "753",
        "name": "Pathanamthitta",
        "state": "Kerala"
    },
    {
        "id": "754",
        "name": "Wadi",
        "state": "Karnataka"
    },
    {
        "id": "755",
        "name": "Rameshwaram",
        "state": "Tamil Nadu"
    },
    {
        "id": "756",
        "name": "Tasgaon",
        "state": "Maharashtra"
    },
    {
        "id": "757",
        "name": "Sikandra Rao",
        "state": "Uttar Pradesh"
    },
    {
        "id": "758",
        "name": "Sihora",
        "state": "Madhya Pradesh"
    },
    {
        "id": "759",
        "name": "Tiruvethipuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "760",
        "name": "Tiruvuru",
        "state": "Andhra Pradesh"
    },
    {
        "id": "761",
        "name": "Mehkar",
        "state": "Maharashtra"
    },
    {
        "id": "762",
        "name": "Peringathur",
        "state": "Kerala"
    },
    {
        "id": "763",
        "name": "Perambalur",
        "state": "Tamil Nadu"
    },
    {
        "id": "764",
        "name": "Manvi",
        "state": "Karnataka"
    },
    {
        "id": "765",
        "name": "Zunheboto",
        "state": "Nagaland"
    },
    {
        "id": "766",
        "name": "Mahnar Bazar",
        "state": "Bihar"
    },
    {
        "id": "767",
        "name": "Attingal",
        "state": "Kerala"
    },
    {
        "id": "768",
        "name": "Shahbad",
        "state": "Haryana"
    },
    {
        "id": "769",
        "name": "Puranpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "770",
        "name": "Nelamangala",
        "state": "Karnataka"
    },
    {
        "id": "771",
        "name": "Nakodar",
        "state": "Punjab"
    },
    {
        "id": "772",
        "name": "Lunawada",
        "state": "Gujarat"
    },
    {
        "id": "773",
        "name": "Murshidabad",
        "state": "West Bengal"
    },
    {
        "id": "774",
        "name": "Mahe",
        "state": "Puducherry"
    },
    {
        "id": "775",
        "name": "Lanka",
        "state": "Assam"
    },
    {
        "id": "776",
        "name": "Rudauli",
        "state": "Uttar Pradesh"
    },
    {
        "id": "777",
        "name": "Tuensang",
        "state": "Nagaland"
    },
    {
        "id": "778",
        "name": "Lakshmeshwar",
        "state": "Karnataka"
    },
    {
        "id": "779",
        "name": "Zira",
        "state": "Punjab"
    },
    {
        "id": "780",
        "name": "Yawal",
        "state": "Maharashtra"
    },
    {
        "id": "781",
        "name": "Thana Bhawan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "782",
        "name": "Ramdurg",
        "state": "Karnataka"
    },
    {
        "id": "783",
        "name": "Pulgaon",
        "state": "Maharashtra"
    },
    {
        "id": "784",
        "name": "Sadasivpet",
        "state": "Telangana"
    },
    {
        "id": "785",
        "name": "Nargund",
        "state": "Karnataka"
    },
    {
        "id": "786",
        "name": "Neem-Ka-Thana",
        "state": "Rajasthan"
    },
    {
        "id": "787",
        "name": "Memari",
        "state": "West Bengal"
    },
    {
        "id": "788",
        "name": "Nilanga",
        "state": "Maharashtra"
    },
    {
        "id": "789",
        "name": "Naharlagun",
        "state": "Arunachal Pradesh"
    },
    {
        "id": "790",
        "name": "Pakaur",
        "state": "Jharkhand"
    },
    {
        "id": "791",
        "name": "Wai",
        "state": "Maharashtra"
    },
    {
        "id": "792",
        "name": "Tarikere",
        "state": "Karnataka"
    },
    {
        "id": "793",
        "name": "Malavalli",
        "state": "Karnataka"
    },
    {
        "id": "794",
        "name": "Raisen",
        "state": "Madhya Pradesh"
    },
    {
        "id": "795",
        "name": "Lahar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "796",
        "name": "Uravakonda",
        "state": "Andhra Pradesh"
    },
    {
        "id": "797",
        "name": "Savanur",
        "state": "Karnataka"
    },
    {
        "id": "798",
        "name": "Sirohi",
        "state": "Rajasthan"
    },
    {
        "id": "799",
        "name": "Udhampur",
        "state": "Jammu and Kashmir"
    },
    {
        "id": "800",
        "name": "Umarga",
        "state": "Maharashtra"
    },
    {
        "id": "801",
        "name": "Pratapgarh",
        "state": "Rajasthan"
    },
    {
        "id": "802",
        "name": "Lingsugur",
        "state": "Karnataka"
    },
    {
        "id": "803",
        "name": "Usilampatti",
        "state": "Tamil Nadu"
    },
    {
        "id": "804",
        "name": "Palia Kalan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "805",
        "name": "Wokha",
        "state": "Nagaland"
    },
    {
        "id": "806",
        "name": "Rajpipla",
        "state": "Gujarat"
    },
    {
        "id": "807",
        "name": "Vijayapura",
        "state": "Karnataka"
    },
    {
        "id": "808",
        "name": "Rawatbhata",
        "state": "Rajasthan"
    },
    {
        "id": "809",
        "name": "Sangaria",
        "state": "Rajasthan"
    },
    {
        "id": "810",
        "name": "Paithan",
        "state": "Maharashtra"
    },
    {
        "id": "811",
        "name": "Rahuri",
        "state": "Maharashtra"
    },
    {
        "id": "812",
        "name": "Patti",
        "state": "Punjab"
    },
    {
        "id": "813",
        "name": "Zaidpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "814",
        "name": "Lalsot",
        "state": "Rajasthan"
    },
    {
        "id": "815",
        "name": "Maihar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "816",
        "name": "Vedaranyam",
        "state": "Tamil Nadu"
    },
    {
        "id": "817",
        "name": "Nawapur",
        "state": "Maharashtra"
    },
    {
        "id": "818",
        "name": "Solan",
        "state": "Himachal Pradesh"
    },
    {
        "id": "819",
        "name": "Vapi",
        "state": "Gujarat"
    },
    {
        "id": "820",
        "name": "Sanawad",
        "state": "Madhya Pradesh"
    },
    {
        "id": "821",
        "name": "Warisaliganj",
        "state": "Bihar"
    },
    {
        "id": "822",
        "name": "Revelganj",
        "state": "Bihar"
    },
    {
        "id": "823",
        "name": "Sabalgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "824",
        "name": "Tuljapur",
        "state": "Maharashtra"
    },
    {
        "id": "825",
        "name": "Simdega",
        "state": "Jharkhand"
    },
    {
        "id": "826",
        "name": "Musabani",
        "state": "Jharkhand"
    },
    {
        "id": "827",
        "name": "Kodungallur",
        "state": "Kerala"
    },
    {
        "id": "828",
        "name": "Phulabani",
        "state": "Odisha"
    },
    {
        "id": "829",
        "name": "Umreth",
        "state": "Gujarat"
    },
    {
        "id": "830",
        "name": "Narsipatnam",
        "state": "Andhra Pradesh"
    },
    {
        "id": "831",
        "name": "Nautanwa",
        "state": "Uttar Pradesh"
    },
    {
        "id": "832",
        "name": "Rajgir",
        "state": "Bihar"
    },
    {
        "id": "833",
        "name": "Yellandu",
        "state": "Telangana"
    },
    {
        "id": "834",
        "name": "Sathyamangalam",
        "state": "Tamil Nadu"
    },
    {
        "id": "835",
        "name": "Pilibanga",
        "state": "Rajasthan"
    },
    {
        "id": "836",
        "name": "Morshi",
        "state": "Maharashtra"
    },
    {
        "id": "837",
        "name": "Pehowa",
        "state": "Haryana"
    },
    {
        "id": "838",
        "name": "Sonepur",
        "state": "Bihar"
    },
    {
        "id": "839",
        "name": "Pappinisseri",
        "state": "Kerala"
    },
    {
        "id": "840",
        "name": "Zamania",
        "state": "Uttar Pradesh"
    },
    {
        "id": "841",
        "name": "Mihijam",
        "state": "Jharkhand"
    },
    {
        "id": "842",
        "name": "Purna",
        "state": "Maharashtra"
    },
    {
        "id": "843",
        "name": "Puliyankudi",
        "state": "Tamil Nadu"
    },
    {
        "id": "844",
        "name": "Shikarpur, Bulandshahr",
        "state": "Uttar Pradesh"
    },
    {
        "id": "845",
        "name": "Umaria",
        "state": "Madhya Pradesh"
    },
    {
        "id": "846",
        "name": "Porsa",
        "state": "Madhya Pradesh"
    },
    {
        "id": "847",
        "name": "Naugawan Sadat",
        "state": "Uttar Pradesh"
    },
    {
        "id": "848",
        "name": "Fatehpur Sikri",
        "state": "Uttar Pradesh"
    },
    {
        "id": "849",
        "name": "Manuguru",
        "state": "Telangana"
    },
    {
        "id": "850",
        "name": "Udaipur",
        "state": "Tripura"
    },
    {
        "id": "851",
        "name": "Pipar City",
        "state": "Rajasthan"
    },
    {
        "id": "852",
        "name": "Pattamundai",
        "state": "Odisha"
    },
    {
        "id": "853",
        "name": "Nanjikottai",
        "state": "Tamil Nadu"
    },
    {
        "id": "854",
        "name": "Taranagar",
        "state": "Rajasthan"
    },
    {
        "id": "855",
        "name": "Yerraguntla",
        "state": "Andhra Pradesh"
    },
    {
        "id": "856",
        "name": "Satana",
        "state": "Maharashtra"
    },
    {
        "id": "857",
        "name": "Sherghati",
        "state": "Bihar"
    },
    {
        "id": "858",
        "name": "Sankeshwara",
        "state": "Karnataka"
    },
    {
        "id": "859",
        "name": "Madikeri",
        "state": "Karnataka"
    },
    {
        "id": "860",
        "name": "Thuraiyur",
        "state": "Tamil Nadu"
    },
    {
        "id": "861",
        "name": "Sanand",
        "state": "Gujarat"
    },
    {
        "id": "862",
        "name": "Rajula",
        "state": "Gujarat"
    },
    {
        "id": "863",
        "name": "Kyathampalle",
        "state": "Telangana"
    },
    {
        "id": "864",
        "name": "Shahabad, Rampur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "865",
        "name": "Tilda Newra",
        "state": "Chhattisgarh"
    },
    {
        "id": "866",
        "name": "Narsinghgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "867",
        "name": "Chittur-Thathamangalam",
        "state": "Kerala"
    },
    {
        "id": "868",
        "name": "Malaj Khand",
        "state": "Madhya Pradesh"
    },
    {
        "id": "869",
        "name": "Sarangpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "870",
        "name": "Robertsganj",
        "state": "Uttar Pradesh"
    },
    {
        "id": "871",
        "name": "Sirkali",
        "state": "Tamil Nadu"
    },
    {
        "id": "872",
        "name": "Radhanpur",
        "state": "Gujarat"
    },
    {
        "id": "873",
        "name": "Tiruchendur",
        "state": "Tamil Nadu"
    },
    {
        "id": "874",
        "name": "Utraula",
        "state": "Uttar Pradesh"
    },
    {
        "id": "875",
        "name": "Patratu",
        "state": "Jharkhand"
    },
    {
        "id": "876",
        "name": "Vijainagar, Ajmer",
        "state": "Rajasthan"
    },
    {
        "id": "877",
        "name": "Periyasemur",
        "state": "Tamil Nadu"
    },
    {
        "id": "878",
        "name": "Pathri",
        "state": "Maharashtra"
    },
    {
        "id": "879",
        "name": "Sadabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "880",
        "name": "Talikota",
        "state": "Karnataka"
    },
    {
        "id": "881",
        "name": "Sinnar",
        "state": "Maharashtra"
    },
    {
        "id": "882",
        "name": "Mungeli",
        "state": "Chhattisgarh"
    },
    {
        "id": "883",
        "name": "Sedam",
        "state": "Karnataka"
    },
    {
        "id": "884",
        "name": "Shikaripur",
        "state": "Karnataka"
    },
    {
        "id": "885",
        "name": "Sumerpur",
        "state": "Rajasthan"
    },
    {
        "id": "886",
        "name": "Sattur",
        "state": "Tamil Nadu"
    },
    {
        "id": "887",
        "name": "Sugauli",
        "state": "Bihar"
    },
    {
        "id": "888",
        "name": "Lumding",
        "state": "Assam"
    },
    {
        "id": "889",
        "name": "Vandavasi",
        "state": "Tamil Nadu"
    },
    {
        "id": "890",
        "name": "Titlagarh",
        "state": "Odisha"
    },
    {
        "id": "891",
        "name": "Uchgaon",
        "state": "Maharashtra"
    },
    {
        "id": "892",
        "name": "Mokokchung",
        "state": "Nagaland"
    },
    {
        "id": "893",
        "name": "Paschim Punropara",
        "state": "West Bengal"
    },
    {
        "id": "894",
        "name": "Sagwara",
        "state": "Rajasthan"
    },
    {
        "id": "895",
        "name": "Ramganj Mandi",
        "state": "Rajasthan"
    },
    {
        "id": "896",
        "name": "Tarakeswar",
        "state": "West Bengal"
    },
    {
        "id": "897",
        "name": "Mahalingapura",
        "state": "Karnataka"
    },
    {
        "id": "898",
        "name": "Dharmanagar",
        "state": "Tripura"
    },
    {
        "id": "899",
        "name": "Mahemdabad",
        "state": "Gujarat"
    },
    {
        "id": "900",
        "name": "Manendragarh",
        "state": "Chhattisgarh"
    },
    {
        "id": "901",
        "name": "Uran",
        "state": "Maharashtra"
    },
    {
        "id": "902",
        "name": "Tharamangalam",
        "state": "Tamil Nadu"
    },
    {
        "id": "903",
        "name": "Tirukkoyilur",
        "state": "Tamil Nadu"
    },
    {
        "id": "904",
        "name": "Pen",
        "state": "Maharashtra"
    },
    {
        "id": "905",
        "name": "Makhdumpur",
        "state": "Bihar"
    },
    {
        "id": "906",
        "name": "Maner",
        "state": "Bihar"
    },
    {
        "id": "907",
        "name": "Oddanchatram",
        "state": "Tamil Nadu"
    },
    {
        "id": "908",
        "name": "Palladam",
        "state": "Tamil Nadu"
    },
    {
        "id": "909",
        "name": "Mundi",
        "state": "Madhya Pradesh"
    },
    {
        "id": "910",
        "name": "Nabarangapur",
        "state": "Odisha"
    },
    {
        "id": "911",
        "name": "Mudalagi",
        "state": "Karnataka"
    },
    {
        "id": "912",
        "name": "Samalkha",
        "state": "Haryana"
    },
    {
        "id": "913",
        "name": "Nepanagar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "914",
        "name": "Karjat",
        "state": "Maharashtra"
    },
    {
        "id": "915",
        "name": "Ranavav",
        "state": "Gujarat"
    },
    {
        "id": "916",
        "name": "Pedana",
        "state": "Andhra Pradesh"
    },
    {
        "id": "917",
        "name": "Pinjore",
        "state": "Haryana"
    },
    {
        "id": "918",
        "name": "Lakheri",
        "state": "Rajasthan"
    },
    {
        "id": "919",
        "name": "Pasan",
        "state": "Madhya Pradesh"
    },
    {
        "id": "920",
        "name": "Puttur",
        "state": "Andhra Pradesh"
    },
    {
        "id": "921",
        "name": "Vadakkuvalliyur",
        "state": "Tamil Nadu"
    },
    {
        "id": "922",
        "name": "Tirukalukundram",
        "state": "Tamil Nadu"
    },
    {
        "id": "923",
        "name": "Mahidpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "924",
        "name": "Mussoorie",
        "state": "Uttarakhand"
    },
    {
        "id": "925",
        "name": "Muvattupuzha",
        "state": "Kerala"
    },
    {
        "id": "926",
        "name": "Rasra",
        "state": "Uttar Pradesh"
    },
    {
        "id": "927",
        "name": "Udaipurwati",
        "state": "Rajasthan"
    },
    {
        "id": "928",
        "name": "Manwath",
        "state": "Maharashtra"
    },
    {
        "id": "929",
        "name": "Adoor",
        "state": "Kerala"
    },
    {
        "id": "930",
        "name": "Uthamapalayam",
        "state": "Tamil Nadu"
    },
    {
        "id": "931",
        "name": "Partur",
        "state": "Maharashtra"
    },
    {
        "id": "932",
        "name": "Nahan",
        "state": "Himachal Pradesh"
    },
    {
        "id": "933",
        "name": "Ladwa",
        "state": "Haryana"
    },
    {
        "id": "934",
        "name": "Mankachar",
        "state": "Assam"
    },
    {
        "id": "935",
        "name": "Nongstoin",
        "state": "Meghalaya"
    },
    {
        "id": "936",
        "name": "Losal",
        "state": "Rajasthan"
    },
    {
        "id": "937",
        "name": "Sri Madhopur",
        "state": "Rajasthan"
    },
    {
        "id": "938",
        "name": "Ramngarh",
        "state": "Rajasthan"
    },
    {
        "id": "939",
        "name": "Mavelikkara",
        "state": "Kerala"
    },
    {
        "id": "940",
        "name": "Rawatsar",
        "state": "Rajasthan"
    },
    {
        "id": "941",
        "name": "Rajakhera",
        "state": "Rajasthan"
    },
    {
        "id": "942",
        "name": "Lar",
        "state": "Uttar Pradesh"
    },
    {
        "id": "943",
        "name": "Lal Gopalganj Nindaura",
        "state": "Uttar Pradesh"
    },
    {
        "id": "944",
        "name": "Muddebihal",
        "state": "Karnataka"
    },
    {
        "id": "945",
        "name": "Sirsaganj",
        "state": "Uttar Pradesh"
    },
    {
        "id": "946",
        "name": "Shahpura",
        "state": "Rajasthan"
    },
    {
        "id": "947",
        "name": "Surandai",
        "state": "Tamil Nadu"
    },
    {
        "id": "948",
        "name": "Sangole",
        "state": "Maharashtra"
    },
    {
        "id": "949",
        "name": "Pavagada",
        "state": "Karnataka"
    },
    {
        "id": "950",
        "name": "Tharad",
        "state": "Gujarat"
    },
    {
        "id": "951",
        "name": "Mansa",
        "state": "Gujarat"
    },
    {
        "id": "952",
        "name": "Umbergaon",
        "state": "Gujarat"
    },
    {
        "id": "953",
        "name": "Mavoor",
        "state": "Kerala"
    },
    {
        "id": "954",
        "name": "Nalbari",
        "state": "Assam"
    },
    {
        "id": "955",
        "name": "Talaja",
        "state": "Gujarat"
    },
    {
        "id": "956",
        "name": "Malur",
        "state": "Karnataka"
    },
    {
        "id": "957",
        "name": "Mangrulpir",
        "state": "Maharashtra"
    },
    {
        "id": "958",
        "name": "Soro",
        "state": "Odisha"
    },
    {
        "id": "959",
        "name": "Shahpura",
        "state": "Rajasthan"
    },
    {
        "id": "960",
        "name": "Vadnagar",
        "state": "Gujarat"
    },
    {
        "id": "961",
        "name": "Raisinghnagar",
        "state": "Rajasthan"
    },
    {
        "id": "962",
        "name": "Sindhagi",
        "state": "Karnataka"
    },
    {
        "id": "963",
        "name": "Sanduru",
        "state": "Karnataka"
    },
    {
        "id": "964",
        "name": "Sohna",
        "state": "Haryana"
    },
    {
        "id": "965",
        "name": "Manavadar",
        "state": "Gujarat"
    },
    {
        "id": "966",
        "name": "Pihani",
        "state": "Uttar Pradesh"
    },
    {
        "id": "967",
        "name": "Safidon",
        "state": "Haryana"
    },
    {
        "id": "968",
        "name": "Risod",
        "state": "Maharashtra"
    },
    {
        "id": "969",
        "name": "Rosera",
        "state": "Bihar"
    },
    {
        "id": "970",
        "name": "Sankari",
        "state": "Tamil Nadu"
    },
    {
        "id": "971",
        "name": "Malpura",
        "state": "Rajasthan"
    },
    {
        "id": "972",
        "name": "Sonamukhi",
        "state": "West Bengal"
    },
    {
        "id": "973",
        "name": "Shamsabad, Agra",
        "state": "Uttar Pradesh"
    },
    {
        "id": "974",
        "name": "Nokha",
        "state": "Bihar"
    },
    {
        "id": "975",
        "name": "PandUrban Agglomeration",
        "state": "West Bengal"
    },
    {
        "id": "976",
        "name": "Mainaguri",
        "state": "West Bengal"
    },
    {
        "id": "977",
        "name": "Afzalpur",
        "state": "Karnataka"
    },
    {
        "id": "978",
        "name": "Shirur",
        "state": "Maharashtra"
    },
    {
        "id": "979",
        "name": "Salaya",
        "state": "Gujarat"
    },
    {
        "id": "980",
        "name": "Shenkottai",
        "state": "Tamil Nadu"
    },
    {
        "id": "981",
        "name": "Pratapgarh",
        "state": "Tripura"
    },
    {
        "id": "982",
        "name": "Vadipatti",
        "state": "Tamil Nadu"
    },
    {
        "id": "983",
        "name": "Nagarkurnool",
        "state": "Telangana"
    },
    {
        "id": "984",
        "name": "Savner",
        "state": "Maharashtra"
    },
    {
        "id": "985",
        "name": "Sasvad",
        "state": "Maharashtra"
    },
    {
        "id": "986",
        "name": "Rudrapur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "987",
        "name": "Soron",
        "state": "Uttar Pradesh"
    },
    {
        "id": "988",
        "name": "Sholingur",
        "state": "Tamil Nadu"
    },
    {
        "id": "989",
        "name": "Pandharkaoda",
        "state": "Maharashtra"
    },
    {
        "id": "990",
        "name": "Perumbavoor",
        "state": "Kerala"
    },
    {
        "id": "991",
        "name": "Maddur",
        "state": "Karnataka"
    },
    {
        "id": "992",
        "name": "Nadbai",
        "state": "Rajasthan"
    },
    {
        "id": "993",
        "name": "Talode",
        "state": "Maharashtra"
    },
    {
        "id": "994",
        "name": "Shrigonda",
        "state": "Maharashtra"
    },
    {
        "id": "995",
        "name": "Madhugiri",
        "state": "Karnataka"
    },
    {
        "id": "996",
        "name": "Tekkalakote",
        "state": "Karnataka"
    },
    {
        "id": "997",
        "name": "Seoni-Malwa",
        "state": "Madhya Pradesh"
    },
    {
        "id": "998",
        "name": "Shirdi",
        "state": "Maharashtra"
    },
    {
        "id": "999",
        "name": "SUrban Agglomerationr",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1000",
        "name": "Terdal",
        "state": "Karnataka"
    },
    {
        "id": "1001",
        "name": "Raver",
        "state": "Maharashtra"
    },
    {
        "id": "1002",
        "name": "Tirupathur",
        "state": "Tamil Nadu"
    },
    {
        "id": "1003",
        "name": "Taraori",
        "state": "Haryana"
    },
    {
        "id": "1004",
        "name": "Mukhed",
        "state": "Maharashtra"
    },
    {
        "id": "1005",
        "name": "Manachanallur",
        "state": "Tamil Nadu"
    },
    {
        "id": "1006",
        "name": "Rehli",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1007",
        "name": "Sanchore",
        "state": "Rajasthan"
    },
    {
        "id": "1008",
        "name": "Rajura",
        "state": "Maharashtra"
    },
    {
        "id": "1009",
        "name": "Piro",
        "state": "Bihar"
    },
    {
        "id": "1010",
        "name": "Mudabidri",
        "state": "Karnataka"
    },
    {
        "id": "1011",
        "name": "Vadgaon Kasba",
        "state": "Maharashtra"
    },
    {
        "id": "1012",
        "name": "Nagar",
        "state": "Rajasthan"
    },
    {
        "id": "1013",
        "name": "Vijapur",
        "state": "Gujarat"
    },
    {
        "id": "1014",
        "name": "Viswanatham",
        "state": "Tamil Nadu"
    },
    {
        "id": "1015",
        "name": "Polur",
        "state": "Tamil Nadu"
    },
    {
        "id": "1016",
        "name": "Panagudi",
        "state": "Tamil Nadu"
    },
    {
        "id": "1017",
        "name": "Manawar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1018",
        "name": "Tehri",
        "state": "Uttarakhand"
    },
    {
        "id": "1019",
        "name": "Samdhan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1020",
        "name": "Pardi",
        "state": "Gujarat"
    },
    {
        "id": "1021",
        "name": "Rahatgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1022",
        "name": "Panagar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1023",
        "name": "Uthiramerur",
        "state": "Tamil Nadu"
    },
    {
        "id": "1024",
        "name": "Tirora",
        "state": "Maharashtra"
    },
    {
        "id": "1025",
        "name": "Rangia",
        "state": "Assam"
    },
    {
        "id": "1026",
        "name": "Sahjanwa",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1027",
        "name": "Wara Seoni",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1028",
        "name": "Magadi",
        "state": "Karnataka"
    },
    {
        "id": "1029",
        "name": "Rajgarh (Alwar)",
        "state": "Rajasthan"
    },
    {
        "id": "1030",
        "name": "Rafiganj",
        "state": "Bihar"
    },
    {
        "id": "1031",
        "name": "Tarana",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1032",
        "name": "Rampur Maniharan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1033",
        "name": "Sheoganj",
        "state": "Rajasthan"
    },
    {
        "id": "1034",
        "name": "Raikot",
        "state": "Punjab"
    },
    {
        "id": "1035",
        "name": "Pauri",
        "state": "Uttarakhand"
    },
    {
        "id": "1036",
        "name": "Sumerpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1037",
        "name": "Navalgund",
        "state": "Karnataka"
    },
    {
        "id": "1038",
        "name": "Shahganj",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1039",
        "name": "Marhaura",
        "state": "Bihar"
    },
    {
        "id": "1040",
        "name": "Tulsipur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1041",
        "name": "Sadri",
        "state": "Rajasthan"
    },
    {
        "id": "1042",
        "name": "Thiruthuraipoondi",
        "state": "Tamil Nadu"
    },
    {
        "id": "1043",
        "name": "Shiggaon",
        "state": "Karnataka"
    },
    {
        "id": "1044",
        "name": "Pallapatti",
        "state": "Tamil Nadu"
    },
    {
        "id": "1045",
        "name": "Mahendragarh",
        "state": "Haryana"
    },
    {
        "id": "1046",
        "name": "Sausar",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1047",
        "name": "Ponneri",
        "state": "Tamil Nadu"
    },
    {
        "id": "1048",
        "name": "Mahad",
        "state": "Maharashtra"
    },
    {
        "id": "1049",
        "name": "Lohardaga",
        "state": "Jharkhand"
    },
    {
        "id": "1050",
        "name": "Tirwaganj",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1051",
        "name": "Margherita",
        "state": "Assam"
    },
    {
        "id": "1052",
        "name": "Sundarnagar",
        "state": "Himachal Pradesh"
    },
    {
        "id": "1053",
        "name": "Rajgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1054",
        "name": "Mangaldoi",
        "state": "Assam"
    },
    {
        "id": "1055",
        "name": "Renigunta",
        "state": "Andhra Pradesh"
    },
    {
        "id": "1056",
        "name": "Longowal",
        "state": "Punjab"
    },
    {
        "id": "1057",
        "name": "Ratia",
        "state": "Haryana"
    },
    {
        "id": "1058",
        "name": "Lalgudi",
        "state": "Tamil Nadu"
    },
    {
        "id": "1059",
        "name": "Shrirangapattana",
        "state": "Karnataka"
    },
    {
        "id": "1060",
        "name": "Niwari",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1061",
        "name": "Natham",
        "state": "Tamil Nadu"
    },
    {
        "id": "1062",
        "name": "Unnamalaikadai",
        "state": "Tamil Nadu"
    },
    {
        "id": "1063",
        "name": "PurqUrban Agglomerationzi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1064",
        "name": "Shamsabad, Farrukhabad",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1065",
        "name": "Mirganj",
        "state": "Bihar"
    },
    {
        "id": "1066",
        "name": "Todaraisingh",
        "state": "Rajasthan"
    },
    {
        "id": "1067",
        "name": "Warhapur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1068",
        "name": "Rajam",
        "state": "Andhra Pradesh"
    },
    {
        "id": "1069",
        "name": "Urmar Tanda",
        "state": "Punjab"
    },
    {
        "id": "1070",
        "name": "Lonar",
        "state": "Maharashtra"
    },
    {
        "id": "1071",
        "name": "Powayan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1072",
        "name": "P.N.Patti",
        "state": "Tamil Nadu"
    },
    {
        "id": "1073",
        "name": "Palampur",
        "state": "Himachal Pradesh"
    },
    {
        "id": "1074",
        "name": "Srisailam Project (Right Flank Colony) Township",
        "state": "Andhra Pradesh"
    },
    {
        "id": "1075",
        "name": "Sindagi",
        "state": "Karnataka"
    },
    {
        "id": "1076",
        "name": "Sandi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1077",
        "name": "Vaikom",
        "state": "Kerala"
    },
    {
        "id": "1078",
        "name": "Malda",
        "state": "West Bengal"
    },
    {
        "id": "1079",
        "name": "Tharangambadi",
        "state": "Tamil Nadu"
    },
    {
        "id": "1080",
        "name": "Sakaleshapura",
        "state": "Karnataka"
    },
    {
        "id": "1081",
        "name": "Lalganj",
        "state": "Bihar"
    },
    {
        "id": "1082",
        "name": "Malkangiri",
        "state": "Odisha"
    },
    {
        "id": "1083",
        "name": "Rapar",
        "state": "Gujarat"
    },
    {
        "id": "1084",
        "name": "Mauganj",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1085",
        "name": "Todabhim",
        "state": "Rajasthan"
    },
    {
        "id": "1086",
        "name": "Srinivaspur",
        "state": "Karnataka"
    },
    {
        "id": "1087",
        "name": "Murliganj",
        "state": "Bihar"
    },
    {
        "id": "1088",
        "name": "Reengus",
        "state": "Rajasthan"
    },
    {
        "id": "1089",
        "name": "Sawantwadi",
        "state": "Maharashtra"
    },
    {
        "id": "1090",
        "name": "Tittakudi",
        "state": "Tamil Nadu"
    },
    {
        "id": "1091",
        "name": "Lilong",
        "state": "Manipur"
    },
    {
        "id": "1092",
        "name": "Rajaldesar",
        "state": "Rajasthan"
    },
    {
        "id": "1093",
        "name": "Pathardi",
        "state": "Maharashtra"
    },
    {
        "id": "1094",
        "name": "Achhnera",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1095",
        "name": "Pacode",
        "state": "Tamil Nadu"
    },
    {
        "id": "1096",
        "name": "Naraura",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1097",
        "name": "Nakur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1098",
        "name": "Palai",
        "state": "Kerala"
    },
    {
        "id": "1099",
        "name": "Morinda, India",
        "state": "Punjab"
    },
    {
        "id": "1100",
        "name": "Manasa",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1101",
        "name": "Nainpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1102",
        "name": "Sahaspur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1103",
        "name": "Pauni",
        "state": "Maharashtra"
    },
    {
        "id": "1104",
        "name": "Prithvipur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1105",
        "name": "Ramtek",
        "state": "Maharashtra"
    },
    {
        "id": "1106",
        "name": "Silapathar",
        "state": "Assam"
    },
    {
        "id": "1107",
        "name": "Songadh",
        "state": "Gujarat"
    },
    {
        "id": "1108",
        "name": "Safipur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1109",
        "name": "Sohagpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1110",
        "name": "Mul",
        "state": "Maharashtra"
    },
    {
        "id": "1111",
        "name": "Sadulshahar",
        "state": "Rajasthan"
    },
    {
        "id": "1112",
        "name": "Phillaur",
        "state": "Punjab"
    },
    {
        "id": "1113",
        "name": "Sambhar",
        "state": "Rajasthan"
    },
    {
        "id": "1114",
        "name": "Prantij",
        "state": "Rajasthan"
    },
    {
        "id": "1115",
        "name": "Nagla",
        "state": "Uttarakhand"
    },
    {
        "id": "1116",
        "name": "Pattran",
        "state": "Punjab"
    },
    {
        "id": "1117",
        "name": "Mount Abu",
        "state": "Rajasthan"
    },
    {
        "id": "1118",
        "name": "Reoti",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1119",
        "name": "Tenu dam-cum-Kathhara",
        "state": "Jharkhand"
    },
    {
        "id": "1120",
        "name": "Panchla",
        "state": "West Bengal"
    },
    {
        "id": "1121",
        "name": "Sitarganj",
        "state": "Uttarakhand"
    },
    {
        "id": "1122",
        "name": "Pasighat",
        "state": "Arunachal Pradesh"
    },
    {
        "id": "1123",
        "name": "Motipur",
        "state": "Bihar"
    },
    {
        "id": "1124",
        "name": "O' Valley",
        "state": "Tamil Nadu"
    },
    {
        "id": "1125",
        "name": "Raghunathpur",
        "state": "West Bengal"
    },
    {
        "id": "1126",
        "name": "Suriyampalayam",
        "state": "Tamil Nadu"
    },
    {
        "id": "1127",
        "name": "Qadian",
        "state": "Punjab"
    },
    {
        "id": "1128",
        "name": "Rairangpur",
        "state": "Odisha"
    },
    {
        "id": "1129",
        "name": "Silvassa",
        "state": "Dadra and Nagar Haveli"
    },
    {
        "id": "1130",
        "name": "Nowrozabad (Khodargama)",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1131",
        "name": "Mangrol",
        "state": "Rajasthan"
    },
    {
        "id": "1132",
        "name": "Soyagaon",
        "state": "Maharashtra"
    },
    {
        "id": "1133",
        "name": "Sujanpur",
        "state": "Punjab"
    },
    {
        "id": "1134",
        "name": "Manihari",
        "state": "Bihar"
    },
    {
        "id": "1135",
        "name": "Sikanderpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1136",
        "name": "Mangalvedhe",
        "state": "Maharashtra"
    },
    {
        "id": "1137",
        "name": "Phulera",
        "state": "Rajasthan"
    },
    {
        "id": "1138",
        "name": "Ron",
        "state": "Karnataka"
    },
    {
        "id": "1139",
        "name": "Sholavandan",
        "state": "Tamil Nadu"
    },
    {
        "id": "1140",
        "name": "Saidpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1141",
        "name": "Shamgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1142",
        "name": "Thammampatti",
        "state": "Tamil Nadu"
    },
    {
        "id": "1143",
        "name": "Maharajpur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1144",
        "name": "Multai",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1145",
        "name": "Mukerian",
        "state": "Punjab"
    },
    {
        "id": "1146",
        "name": "Sirsi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1147",
        "name": "Purwa",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1148",
        "name": "Sheohar",
        "state": "Bihar"
    },
    {
        "id": "1149",
        "name": "Namagiripettai",
        "state": "Tamil Nadu"
    },
    {
        "id": "1150",
        "name": "Parasi",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1151",
        "name": "Lathi",
        "state": "Gujarat"
    },
    {
        "id": "1152",
        "name": "Lalganj",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1153",
        "name": "Narkhed",
        "state": "Maharashtra"
    },
    {
        "id": "1154",
        "name": "Mathabhanga",
        "state": "West Bengal"
    },
    {
        "id": "1155",
        "name": "Shendurjana",
        "state": "Maharashtra"
    },
    {
        "id": "1156",
        "name": "Peravurani",
        "state": "Tamil Nadu"
    },
    {
        "id": "1157",
        "name": "Mariani",
        "state": "Assam"
    },
    {
        "id": "1158",
        "name": "Phulpur",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1159",
        "name": "Rania",
        "state": "Haryana"
    },
    {
        "id": "1160",
        "name": "Pali",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1161",
        "name": "Pachore",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1162",
        "name": "Parangipettai",
        "state": "Tamil Nadu"
    },
    {
        "id": "1163",
        "name": "Pudupattinam",
        "state": "Tamil Nadu"
    },
    {
        "id": "1164",
        "name": "Panniyannur",
        "state": "Kerala"
    },
    {
        "id": "1165",
        "name": "Maharajganj",
        "state": "Bihar"
    },
    {
        "id": "1166",
        "name": "Rau",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1167",
        "name": "Monoharpur",
        "state": "West Bengal"
    },
    {
        "id": "1168",
        "name": "Mandawa",
        "state": "Rajasthan"
    },
    {
        "id": "1169",
        "name": "Marigaon",
        "state": "Assam"
    },
    {
        "id": "1170",
        "name": "Pallikonda",
        "state": "Tamil Nadu"
    },
    {
        "id": "1171",
        "name": "Pindwara",
        "state": "Rajasthan"
    },
    {
        "id": "1172",
        "name": "Shishgarh",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1173",
        "name": "Patur",
        "state": "Maharashtra"
    },
    {
        "id": "1174",
        "name": "Mayang Imphal",
        "state": "Manipur"
    },
    {
        "id": "1175",
        "name": "Mhowgaon",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1176",
        "name": "Guruvayoor",
        "state": "Kerala"
    },
    {
        "id": "1177",
        "name": "Mhaswad",
        "state": "Maharashtra"
    },
    {
        "id": "1178",
        "name": "Sahawar",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1179",
        "name": "Sivagiri",
        "state": "Tamil Nadu"
    },
    {
        "id": "1180",
        "name": "Mundargi",
        "state": "Karnataka"
    },
    {
        "id": "1181",
        "name": "Punjaipugalur",
        "state": "Tamil Nadu"
    },
    {
        "id": "1182",
        "name": "Kailasahar",
        "state": "Tripura"
    },
    {
        "id": "1183",
        "name": "Samthar",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1184",
        "name": "Sakti",
        "state": "Chhattisgarh"
    },
    {
        "id": "1185",
        "name": "Sadalagi",
        "state": "Karnataka"
    },
    {
        "id": "1186",
        "name": "Silao",
        "state": "Bihar"
    },
    {
        "id": "1187",
        "name": "Mandalgarh",
        "state": "Rajasthan"
    },
    {
        "id": "1188",
        "name": "Loha",
        "state": "Maharashtra"
    },
    {
        "id": "1189",
        "name": "Pukhrayan",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1190",
        "name": "Padmanabhapuram",
        "state": "Tamil Nadu"
    },
    {
        "id": "1191",
        "name": "Belonia",
        "state": "Tripura"
    },
    {
        "id": "1192",
        "name": "Saiha",
        "state": "Mizoram"
    },
    {
        "id": "1193",
        "name": "Srirampore",
        "state": "West Bengal"
    },
    {
        "id": "1194",
        "name": "Talwara",
        "state": "Punjab"
    },
    {
        "id": "1195",
        "name": "Puthuppally",
        "state": "Kerala"
    },
    {
        "id": "1196",
        "name": "Khowai",
        "state": "Tripura"
    },
    {
        "id": "1197",
        "name": "Vijaypur",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1198",
        "name": "Takhatgarh",
        "state": "Rajasthan"
    },
    {
        "id": "1199",
        "name": "Thirupuvanam",
        "state": "Tamil Nadu"
    },
    {
        "id": "1200",
        "name": "Adra",
        "state": "West Bengal"
    },
    {
        "id": "1201",
        "name": "Piriyapatna",
        "state": "Karnataka"
    },
    {
        "id": "1202",
        "name": "Obra",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1203",
        "name": "Adalaj",
        "state": "Gujarat"
    },
    {
        "id": "1204",
        "name": "Nandgaon",
        "state": "Maharashtra"
    },
    {
        "id": "1205",
        "name": "Barh",
        "state": "Bihar"
    },
    {
        "id": "1206",
        "name": "Chhapra",
        "state": "Gujarat"
    },
    {
        "id": "1207",
        "name": "Panamattom",
        "state": "Kerala"
    },
    {
        "id": "1208",
        "name": "Niwai",
        "state": "Uttar Pradesh"
    },
    {
        "id": "1209",
        "name": "Bageshwar",
        "state": "Uttarakhand"
    },
    {
        "id": "1210",
        "name": "Tarbha",
        "state": "Odisha"
    },
    {
        "id": "1211",
        "name": "Adyar",
        "state": "Karnataka"
    },
    {
        "id": "1212",
        "name": "Narsinghgarh",
        "state": "Madhya Pradesh"
    },
    {
        "id": "1213",
        "name": "Warud",
        "state": "Maharashtra"
    },
    {
        "id": "1214",
        "name": "Asarganj",
        "state": "Bihar"
    },
    {
        "id": "1215",
        "name": "Sarsod",
        "state": "Haryana"
    },
    {
        "id": "1216",
        "name": "Gandhinagar",
        "state": "Gujarat"
    },
    {
        "id":"1217",
        "name":"Kullu",
        "state":"Himachal Pradesh"
    },
    {
        "id":"1218",
        "name":"Manali",
        "state":"Himachal Praddesh"
    },
    {
        "id":"1219",
        "name":"Mirzapur",
        "state":"Uttar Pradesh"
    },
    {
        "id":"1220",
        "name":"Kota",
        "state":"Rajasthan"
    },
    {
      "id":"1221",
       "name":"Dispur",
       "state":"Assam"
    }
]

export const schoolsConstant =[
    {
        "name": "MURADI S R B P HIGH SCHOOL"
    },
    {
        "name": "MURADI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "MUNSIFDANGA HIGH SCHOOL"
    },
    {
        "name": "MUNDAMARI USHANANDA VIDYAPITH"
    },
    {
        "name": "MULTIPURPOSE GOVT. GIRLS&#8217; SCHOOL, ALIPORE"
    },
    {
        "name": "MUDALI R.N.M. HIGH SCHOOL"
    },
    {
        "name": "MSB EDUCATIONAL INSTITUTE"
    },
    {
        "name": "MOZAMPUR H.S.S.B. HIGH SCHOOL"
    },
    {
        "name": "MOYNA VIVEKANANDA KANYA VIDYAPITH"
    },
    {
        "name": "MOUTORH M.S. HIGH SCHOOL"
    },
    {
        "name": "MOUPAL DESHAPRAN VIDYAPITH"
    },
    {
        "name": "MOUNT LITERA ZEE SCHOOL[2]"
    },
    {
        "name": "MOUNT LITERA ZEE SCHOOL BAHARAMPUR"
    },
    {
        "name": "MOSTAFAPUR GANDHI HIGH SCHOOL HS"
    },
    {
        "name": "MOSHIARA HIGH SCHOOL"
    },
    {
        "name": "MORNING STAR MODEL SCHOOL"
    },
    {
        "name": "MORNING BELLS ACADEMY HIGH SCHOOL"
    },
    {
        "name": "MORAR SAMMILANI HIGH SCHOOL"
    },
    {
        "name": "MOMIN HIGH SCHOOL"
    },
    {
        "name": "MOHAR DINABANDHU KANYA VIDYAYATAN"
    },
    {
        "name": "MOHANPARA NIBEDITA BALIKA VIDYALAYA"
    },
    {
        "name": "MOHABALA OJIA HIGH MADRASAH"
    },
    {
        "name": "MODERN HIGH SCHOOL FOR GIRLS"
    },
    {
        "name": "MODERN ENGLISH SCHOOL, BURMAN STREET.(NURSERY &#038; PRIMARY)"
    },
    {
        "name": "MITRA INSTITUTION (MAIN)"
    },
    {
        "name": "MIRIK HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "MIRANDA HOUSE, JORASANKO"
    },
    {
        "name": "MILKI HIGH SCHOOL HS"
    },
    {
        "name": "MILANPALLI HIGH SCHOOL"
    },
    {
        "name": "MIDNAPORE TOWN SCHOOL FOR GIRLS"
    },
    {
        "name": "MIDNAPORE TOWN SCHOOL, HERITAGE"
    },
    {
        "name": "MIDNAPORE COLLEGIATE  SCHOOL"
    },
    {
        "name": "METYAL SAHAR HIGH SCHOOL"
    },
    {
        "name": "MEREND HIGH SCHOOL"
    },
    {
        "name": "MENKAPUR KRISHNA PRASAD UCHCHA VIDYALAYA"
    },
    {
        "name": "MEMARI CRYSTAL MODEL SCHOOL"
    },
    {
        "name": "MEKHLIGANJ HIGH SCHOOL"
    },
    {
        "name": "MEGHMALA ROY EDUCATION CENTRE"
    },
    {
        "name": "MEARBERH RAMKRISHNA SIKSHANIKETAN"
    },
    {
        "name": "MDB DAV PUBLIC SCHOOL"
    },
    {
        "name": "MD JAN HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "MAYNAGURI ROAD HIGH SCHOOL"
    },
    {
        "name": "MATIKUNDA HIGH SCHOOL"
    },
    {
        "name": "MATHURAPUR B.S.S. HIGH SCHOOL"
    },
    {
        "name": "MATHURAPUR ARYA VIDYAPITH"
    },
    {
        "name": "MATHABHANGA HIGH SCHOOL"
    },
    {
        "name": "MATHABHANGA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "MATANGINI HAZRA BALIKA VIDYALAYA"
    },
    {
        "name": "MASAT APTAP MITRA HIGH SCHOOL"
    },
    {
        "name": "MASANJHAR ADIBASI HIGH SCHOOL"
    },
    {
        "name": "MARWARI BALIKA VIDYALAYA"
    },
    {
        "name": "MARH GOPALPUR SINDHUBALA HIGH SCHOOL"
    },
    {
        "name": "MARGRAM HIGH SCHOOL"
    },
    {
        "name": "MARBEDIA HIGH SCHOOL"
    },
    {
        "name": "MANSUR HABIBULLAH MEMORIAL (SOUTH END) SCHOOL"
    },
    {
        "name": "MANOHARPUR SRI RAMKRISHNA HIGH SCHOOL"
    },
    {
        "name": "MANIPUR S.V. HIGH SCHOOL"
    },
    {
        "name": "MANIKORA HIGH SCHOOL"
    },
    {
        "name": "MANIKO ADIBASI HIGH SCHOOL"
    },
    {
        "name": "MANIHARA HIGH SCHOOL"
    },
    {
        "name": "MANIGRAM HIGH SCHOOL"
    },
    {
        "name": "MANIDAHA HIGH SCHOOL"
    },
    {
        "name": "MANGURIA S.S.B.M. VIDYAMANDIR"
    },
    {
        "name": "MANGRUL BISWESWAR HIGH SCHOOL"
    },
    {
        "name": "MANGALDA B.N.J HIGH SCHOOL"
    },
    {
        "name": "MANGALAPORE SARAT CHANDRA SIKSHANIKETAN"
    },
    {
        "name": "MANGALAM VIDYA NIKETAN"
    },
    {
        "name": "MANDRA A.K. HIGH SCHOOL"
    },
    {
        "name": "MANBAZAR R.M. INSTITUTION"
    },
    {
        "name": "MANBAZAR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "MALTHORE HIGH SCHOOL"
    },
    {
        "name": "MALIARA RAJ NARAYAN HIGH SCHOOL"
    },
    {
        "name": "MALGAON TMO SENIOR MADRASAH (FAZIL)"
    },
    {
        "name": "MALDANGA R M INSTITUTION"
    },
    {
        "name": "MALADANG SEHARAKURI B.D HIGH SCHOOL"
    },
    {
        "name": "MAL ADARSHA BIDYABHABAN"
    },
    {
        "name": "MAKARDAH BAMASUNDARI INSTITUTION"
    },
    {
        "name": "MAJILPUR SHYAMSUNDAR BALIKA VIDYALAYA"
    },
    {
        "name": "MAJILPUR J. M. TRAINING SCHOOL"
    },
    {
        "name": "MAJILPUR ATUL KRISHNA VINODINI BHATTACHARYA VIDYAPITH"
    },
    {
        "name": "MAJILAPUR BIRENDRA VIDYAPITH"
    },
    {
        "name": "MAHISHBATHAN MANOJ MOHAN VIDYAMANDIR"
    },
    {
        "name": "MAHESHWARI GIRLS&#8217; SCHOOL"
    },
    {
        "name": "MAHESHPUR HIGH SCHOOL"
    },
    {
        "name": "MAHESHMATI D N SAHA VIDYABHABAN"
    },
    {
        "name": "MAHATMA GANDHI HIGH SCHOOL HS"
    },
    {
        "name": "MAHARISHI VIDYA MANDIR"
    },
    {
        "name": "MAHARAJAHAT HIGH SCHOOL"
    },
    {
        "name": "MAHARA HIGH SCHOOL"
    },
    {
        "name": "MAHANADI HIGH SCHOOL"
    },
    {
        "name": "MAHAMAYAPUR ADARSHA VIDYAPITH"
    },
    {
        "name": "MAHAKALI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "MAHADEVI BIRLA WORLD ACADEMY"
    },
    {
        "name": "MAGRAHAT ANGLO ORIENTAL INSTITUTION"
    },
    {
        "name": "MADRASAH ISLAMIA HIGH MADRASAH PURULIA"
    },
    {
        "name": "MADPUR BALIKA VIDYALAYA"
    },
    {
        "name": "MADHUTATI HIGH  SCHOOL"
    },
    {
        "name": "MADHUSUDANPUR LAKSHMIPUR RADHAKANTA ACADEMY"
    },
    {
        "name": "MADHURPUR HIGH SCHOOL"
    },
    {
        "name": "MADARGACHI HIGH SCHOOL"
    },
    {
        "name": "MADARAT POPULAR ACADEMY"
    },
    {
        "name": "MADANPUR KENDRIYA ADARSHA VIDYALAYA"
    },
    {
        "name": "MADANMOHANPUR S. K. BD. HIGH SCHOOL"
    },
    {
        "name": "M.L JUBILEE INSTITUTION (SURYA SEN STREET)"
    },
    {
        "name": "M. P. BIRLA FOUNDATION HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "M. C. KEJRIWAL VIDYAPEETH"
    },
    {
        "name": "LOYOLA HIGH SCHOOL"
    },
    {
        "name": "LORETO HOUSE"
    },
    {
        "name": "LORETO DAY SCHOOL-DHARMATALLA"
    },
    {
        "name": "LORETO DAY SCHOOL- SEALDAH"
    },
    {
        "name": "LORETO DAY SCHOOL ELLIOT ROAD"
    },
    {
        "name": "LORETO DAY SCHOOL- BOWBAZAR"
    },
    {
        "name": "LORETO CONVENT ENTALLY"
    },
    {
        "name": "LOLAY SAMPU H.S SCHOOL"
    },
    {
        "name": "LODNA MANKHAMAR VIVEKANANDA VIDYAMANDIR"
    },
    {
        "name": "LITTLE STAR HIGH SCHOOL"
    },
    {
        "name": "LITTLE STAR (PRIMARY SCHOOL), BANSDRONI"
    },
    {
        "name": "LIONS CALCUTTA GREATER VIDYA MANDIR"
    },
    {
        "name": "LING LIANG HIGH SCHOOL, BB GANGULY STREET"
    },
    {
        "name": "LENIN PRATHAMIK VIDYALAYA (GOVT. AIDED)"
    },
    {
        "name": "LAWRENCE DAY SCHOOL"
    },
    {
        "name": "LAVA H. S. SCHOOL"
    },
    {
        "name": "LAULARA R.C.ACADEMY"
    },
    {
        "name": "LATPADA HIGH SCHOOL"
    },
    {
        "name": "LASKARPUR HIGH SCHOOL"
    },
    {
        "name": "LALIT MOHAN SHYAM MOHINI HIGH SCHOOL( H.S) ,MALDA"
    },
    {
        "name": "LAKSHMIPUR HIGH SCHOOL"
    },
    {
        "name": "LAKSHMIPAT SINGHANIA ACADEMY"
    },
    {
        "name": "LAKHRA UPENDRA NATH HIGH SCHOOL"
    },
    {
        "name": "LAKHRA UPENDRA NATH HIGH SCHOOL"
    },
    {
        "name": "LAKE VIEW HIGH SCHOOL (GIRLS)"
    },
    {
        "name": "LAKE VIEW HIGH SCHOOL (BOYS)"
    },
    {
        "name": "LAKE TOWN GOVT. SPONS. GIRLS SCHOOL"
    },
    {
        "name": "LAJPAT INSTITUTION (KHIDDERPORE, BABUBAZAR)"
    },
    {
        "name": "LAJPAT HINDI HIGH SCHOOL"
    },
    {
        "name": "LAHIL HIGH SCHOOL"
    },
    {
        "name": "LADHURKA HIGH SCHOOL"
    },
    {
        "name": "LADHURAM TOSHNIWAL SARADA VIDYA MANDIR"
    },
    {
        "name": "LACHIPUR BINAPANI HIGH SCHOOL"
    },
    {
        "name": "LABAN HRAD VIDYAPITH, SALT LAKE"
    },
    {
        "name": "LA MATERNELLE PRIMARY &#038; HIGH SCHOOL"
    },
    {
        "name": "LA MARTINIERE FOR BOYS"
    },
    {
        "name": "KV NTPC FARAKKA"
    },
    {
        "name": "KV GC CRPF SILIGURI"
    },
    {
        "name": "KV BSF KRISHNANAGAR"
    },
    {
        "name": "KV AFS BAGDOGRA"
    },
    {
        "name": "KUTIDIH HIGH SCHOOL"
    },
    {
        "name": "KUSUMJURIA GIRIBALA HIGH SCHOOL"
    },
    {
        "name": "KUSTAR HIGH SCHOOL"
    },
    {
        "name": "KUSHMARH TENTULIA HIGHSCHOOL"
    },
    {
        "name": "KUSHI HIGH SCHOOL"
    },
    {
        "name": "KURUKTOPA HIGH SCHOOL"
    },
    {
        "name": "KUNOIR K.C. HIGH SCHOOL"
    },
    {
        "name": "KUNCHIA HIGH SCHOOL"
    },
    {
        "name": "KUMARHAT HIGH SCHOOL"
    },
    {
        "name": "KUMARGANJ HIGH SCHOOL"
    },
    {
        "name": "KUMARCHAK MAHENDRA HIGH SCHOOL"
    },
    {
        "name": "KUMAR ASHUTOSH INSTITUTION FOR MAIN BOYS"
    },
    {
        "name": "KULTIKRI S.C. HIGH SCHOOL"
    },
    {
        "name": "KULPI KALIKA VIDYAPITH"
    },
    {
        "name": "KULABAHAL P. Y. VIDYAPITH"
    },
    {
        "name": "KUILAPAL S.S.HIGH SCHOOL"
    },
    {
        "name": "KUCHIAKOL R B INSTITUTION"
    },
    {
        "name": "KRISHNATH COLLEGE SCHOOL"
    },
    {
        "name": "KRISHNAPUR ADARSHA VIDYAMANDIR, DUM DUM PARK"
    },
    {
        "name": "KRISHNA BHABINI NARI SIKSHA MANDIR"
    },
    {
        "name": "KOYTHA SS HIGH MADRASAH"
    },
    {
        "name": "KOTSHILA GIRLS HIGH SCHOOL H.S"
    },
    {
        "name": "KOTEPADA HIGH SCHOOL"
    },
    {
        "name": "KOTASUR HIGH SCHOOL"
    },
    {
        "name": "KONGORERAIMA HIGH SCHOOL"
    },
    {
        "name": "KONAPARA HIGH SCHOOL"
    },
    {
        "name": "KOLAGHAT THERMAL POWER PLANT HIGH SCHOOL"
    },
    {
        "name": "KOKOND KALIKA SIKSHA SADAN"
    },
    {
        "name": "KISMOT DIRGHAGRAM HIGH SCHOOL"
    },
    {
        "name": "KISHOREPUR GIRLS HIGH SCHOOL"
    },
    {
        "name": "KISHORE VIDYAPITH, 27B/3A, CHAULPATTY ROAD, KOL-10"
    },
    {
        "name": "KIDDERPORE MUSLIM HIGH SCHOOL"
    },
    {
        "name": "KIDDERPORE ACADEMY, RAM KAMAL STREET"
    },
    {
        "name": "KICHAKTOLA HIGH SCHOOL"
    },
    {
        "name": "KHUDIBANDH HIGH SCHOOL"
    },
    {
        "name": "KHORIBARI HIGH SCHOOL"
    },
    {
        "name": "KHIRPAI HIGH SCHOOL"
    },
    {
        "name": "KHIRPAI DR SKBM GIRLS HIGH SCHOOL"
    },
    {
        "name": "KHATRA HIGH SCHOOL"
    },
    {
        "name": "KHASKOLE HIGH SCHOOL"
    },
    {
        "name": "KHARIDUARA HIGH SCHOOL"
    },
    {
        "name": "KHARGAPUR SCHOOL"
    },
    {
        "name": "KHARBA H N AGRIL HIGH SCHOOL"
    },
    {
        "name": "KHARAR S.K.H.H.BALIKA VIDYALAYA"
    },
    {
        "name": "KHANJAPUR UNION HIGH SCHOOL"
    },
    {
        "name": "KHANDIBANDH KOKILMONI HIGH SCHOOL"
    },
    {
        "name": "KHANAKUL KRISHNA VAMINI GIRLS HIGH SCHOOL"
    },
    {
        "name": "KHAMRA BHABKI HIGH SCHOOL"
    },
    {
        "name": "KHAMARGACHI HIGH SCHOOL(CO-ED)"
    },
    {
        "name": "KHAMAR HIGH SCHOOL"
    },
    {
        "name": "KHALSAMARI HIGH SCHOOL"
    },
    {
        "name": "KHALSA MODEL SENIOR SECONDARY SCHOOL"
    },
    {
        "name": "KHALISANI VIDYAMANDIR"
    },
    {
        "name": "KHALISANI JATIYA VIDYAPITH HIGH SCHOOL"
    },
    {
        "name": "KHAJRA SATISH CHANDRA MEMORIAL HIGH SCHOOL"
    },
    {
        "name": "KHAIRIPIHIRA NETAJEE VIDYAPITH"
    },
    {
        "name": "KESHPUR L.N.HIGH SCHOOL"
    },
    {
        "name": "KESHABCHAK DESHGOURAB HIGH SCHOOL"
    },
    {
        "name": "KENDRIYA VIDYALAYA SANTRAGACHI"
    },
    {
        "name": "KENDRIYA VIDYALAYA RBNM SALBONI"
    },
    {
        "name": "KENDRIYA VIDYALAYA RAIGANJ"
    },
    {
        "name": "KENDRIYA VIDYALAYA ORDNANCE FACTORY"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO.2, SALT LAKE"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO.2 ISHAPORE"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO.2"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO.1, ISHAPORE"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO.1 SALT LAKE"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO. 2, SALT LAKE KOLKATA"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO 2 BINNAGURI CANTT"
    },
    {
        "name": "KENDRIYA VIDYALAYA NO -1, AFS, KALAIKUNDA"
    },
    {
        "name": "KENDRIYA VIDYALAYA KANKINARA"
    },
    {
        "name": "KENDRIYA VIDYALAYA IOC HALDIA"
    },
    {
        "name": "KENDRIYA VIDYALAYA GARDENREACH"
    },
    {
        "name": "KENDRIYA VIDYALAYA FORT WILLIAM"
    },
    {
        "name": "KENDRIYA VIDYALAYA CRPF DURGAPUR"
    },
    {
        "name": "KENDRIYA VIDYALAYA COSSIPORE"
    },
    {
        "name": "KENDRIYA VIDYALAYA COMMAND HOSPITAL"
    },
    {
        "name": "KENDRIYA VIDYALAYA BURDWAN"
    },
    {
        "name": "KENDRIYA VIDYALAYA BIRBHUM"
    },
    {
        "name": "KENDRIYA VIDYALAYA BERHAMPORE"
    },
    {
        "name": "KENDRIYA VIDYALAYA BAMANGACHI"
    },
    {
        "name": "KENDRIYA VIDYALAYA BALLYGUNGE"
    },
    {
        "name": "KENDRIYA VIDYALAYA AFS SALUA"
    },
    {
        "name": "KENDRIYA VIDYALAYA ADRA"
    },
    {
        "name": "KENDRIYA VIDYALAYA"
    },
    {
        "name": "KENDRIYA VIDYALAY NO.1, IITKHARAGPUR"
    },
    {
        "name": "KENDRIYA VIDAYALAYA ANDAL"
    },
    {
        "name": "KENDRIA VIDYALAYA SEVOKE ROAD"
    },
    {
        "name": "KENDA ATGSHS SCHOOL FOR GIRLS"
    },
    {
        "name": "KAZIGRAM HIGH SCHOOL"
    },
    {
        "name": "KATWA KASHIRAM DAS INSTITUTION"
    },
    {
        "name": "KATJUNAGAR SWARNAMAYEE VIDYAPITH"
    },
    {
        "name": "KATIAHAT B.K.A.P. GIRLS&#8217; HIGH SCHOOL H.S"
    },
    {
        "name": "KASTURBA HINDI BALIKA VIDYALAYA"
    },
    {
        "name": "KASHIPUR J.K.M GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "KASHIDIH C R C G VIDYAPITH"
    },
    {
        "name": "KARTIK CHANDRA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "KARMATARH HIGH SCHOOL"
    },
    {
        "name": "KARIMPUR JAGANNATH HIGH SCHOOL"
    },
    {
        "name": "KARBALA HIGH SCHOOL"
    },
    {
        "name": "KARANDIGHI GIRLS HIGH SCHOOL"
    },
    {
        "name": "KAPASIA A. M. HIGH SCHOOL(XII CLASS)"
    },
    {
        "name": "KANUIBANKA HIGH SCHOOL"
    },
    {
        "name": "KANTORE MAHADEB HIGH SCHOOL"
    },
    {
        "name": "KANTAMARI CHURAMONI HIGH SCHOOL"
    },
    {
        "name": "KANTALIA HIGH SCHOOL"
    },
    {
        "name": "KANTALI HIGH SCHOOL"
    },
    {
        "name": "KANTAKHALI PUTHIA HIGH SCHOOL"
    },
    {
        "name": "KANTADIH SS HIGH SCHOOL"
    },
    {
        "name": "KANPUR KRISHNABATI VIVEKANANDA INSTITUTION"
    },
    {
        "name": "KANKINARA URDU GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "KANKI SHREE JAIN VIDYA MANDIR"
    },
    {
        "name": "KANCHANTALA J.D.J. INSTITUTION"
    },
    {
        "name": "KANCHANNAGAR D.N DAS HIGH SCHOOL"
    },
    {
        "name": "KANASOLE HIGH SCHOOL"
    },
    {
        "name": "KANAKNAGAR SD INSTITUTION H.S."
    },
    {
        "name": "KAMALPORE HIGH SCHOOL"
    },
    {
        "name": "KAMALA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "KAMALA CHATTERJEE SCHOOL"
    },
    {
        "name": "KALYANI CENTRAL MODEL SCHOOL"
    },
    {
        "name": "KALYANBRATA SANGHA HIGH SCHOOL"
    },
    {
        "name": "KALSHIBHANGA DESHBANDHU HIGH SCHOOL"
    },
    {
        "name": "KALNA MAHARAJA&#8217;S HIGH SCHOOL"
    },
    {
        "name": "KALNA HINDU GIRLS HIGH SCHOOL"
    },
    {
        "name": "KALMA BANDULAHAR HIGH SCHOOL"
    },
    {
        "name": "KALIYAGANJ SARALA SUNDARI HIGH SCHOOL"
    },
    {
        "name": "KALIYAGANJ MONMOHAN G.H.S"
    },
    {
        "name": "KALITALA  L. K. HIGH SCHOOL"
    },
    {
        "name": "KALINDRI HIGH SCHOOL"
    },
    {
        "name": "KALIMPONG GIRLS&#8217; HIGH HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "KALIMP[ONG GOVT. HIGH SCHOOL"
    },
    {
        "name": "KALIKRISHNA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "KALIKATALA MILAN VIDYAPITH"
    },
    {
        "name": "KALIGANJ HIGH SCHOOL"
    },
    {
        "name": "KALIDHAN INSTITUTION, SOUTHERN AVENUE"
    },
    {
        "name": "KALIDAHA ANCHAL HIGH SCHOOL"
    },
    {
        "name": "KALIABASA HIGH SCHOOL"
    },
    {
        "name": "KALASIMA SWAMI VIVEKANANDA VIDYAPITH"
    },
    {
        "name": "KALANAGIN MADHULAL SMRITI HIGH SCHOOL"
    },
    {
        "name": "KALAIMURI NETAJI SUBHAS VIDYALAYA"
    },
    {
        "name": "KALAGACHIA KAILASH KAMINI HIGH SCHOOL  )"
    },
    {
        "name": "KALABATI PERUABAD HIGH SCHOOL"
    },
    {
        "name": "KAKDWIP SHISHU SHIKSHAYATAN HIGH SCHOOL"
    },
    {
        "name": "KAIYANSHREE JNANADA DEVI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "KAIRAPUR VIDYASAGAR VIDYAPITH"
    },
    {
        "name": "KAILASH VIDYAMANDIR, CHETLA, KOLKATA"
    },
    {
        "name": "KAIJURI VIDYASAGAR HIGH SCHOOL"
    },
    {
        "name": "KAIJULI HEMCHANDRA HIGH SCHOOL"
    },
    {
        "name": "KAIGERIA S.S.D.BALIKA VIDYAPITH HIGH SCHOOL"
    },
    {
        "name": "KACHUA BOALMARI HIGH SCHOOL"
    },
    {
        "name": "K.E. CARMEL SCHOOL, AMTALA"
    },
    {
        "name": "K.D.P.LALBAHADUR SASTRI VIDYAPITH H.S."
    },
    {
        "name": "JYOTINAGAR BIDYASHREE NIKETAN, BARANAGAR"
    },
    {
        "name": "JULPIA ANDHARMANIK HIGH SCHOOL"
    },
    {
        "name": "JULIEN DAY SCHOOL"
    },
    {
        "name": "JUJERSA P.N.MANNA INSTITUTION"
    },
    {
        "name": "JUDHABIR HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "JOYPUR S.T.,S.C. &#038; B.C. HIGH SCHOOL"
    },
    {
        "name": "JOYKRISHNAPUR HIGH SCHOOL"
    },
    {
        "name": "JOTEKANURAMGARH K.N.PAUL HIGH SCHOOL"
    },
    {
        "name": "JOTEKAMAL HIGH SCHOOL"
    },
    {
        "name": "JOTEBHAGABAN HIGH SCHOOL"
    },
    {
        "name": "JOTE ARAPUR P.N. HIGH SCHOOL"
    },
    {
        "name": "JOREPATKI HIGH SCHOOL"
    },
    {
        "name": "JORADIH RATANPUR HIGH SCHOOL"
    },
    {
        "name": "JODHPUR PARK GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "JODHPUR PARK BOYS&#8217; SCHOOL"
    },
    {
        "name": "JNV DALKHOLA UTTAR DINAJPUR"
    },
    {
        "name": "JNV DALKHOLA UTTAR DINAJPUR"
    },
    {
        "name": "JIUDARU HIGH SCHOOL"
    },
    {
        "name": "JITUJURI DEBASISH HIGH SCHOOL"
    },
    {
        "name": "JIRAT COLONY HIGH SCHOOL"
    },
    {
        "name": "JINGAON TN HIGH SCHOOL"
    },
    {
        "name": "JILLING HIGH SCHOOL"
    },
    {
        "name": "JIBREEL INTERNATIONAL SCHOOL"
    },
    {
        "name": "JIAGANJ SURENDRA NARAYAN GIRLS HIGH SCHOOL"
    },
    {
        "name": "JHUNJKA HIGH SCHOOL"
    },
    {
        "name": "JHARGRAM KUMUD KUMARI INSTITUTION"
    },
    {
        "name": "JHARBAGDA HIGH SCHOOL"
    },
    {
        "name": "JHAPRA HIGH SCHOOL"
    },
    {
        "name": "JHALDA SATYABHAMA VIDYAPITH"
    },
    {
        "name": "JHALDA HINDI HIGH SCHOOL"
    },
    {
        "name": "JHALDA HIGH SCHOOL"
    },
    {
        "name": "JHALDA GIRLS HIGH HS SCHOOL"
    },
    {
        "name": "JHAKRA HIGH SCHOOL"
    },
    {
        "name": "JEWISH GIRLS SCHOOL, PARK STREET"
    },
    {
        "name": "JESUS CHRIST MISSION SCHOOL"
    },
    {
        "name": "JENKINS SCHOOL"
    },
    {
        "name": "JAYNAGAR INSTITUTION FOR GIRLS"
    },
    {
        "name": "JAYNAGAR INSTITUTION"
    },
    {
        "name": "JAWAHAR NAVODAYA VIDYALAYA-II"
    },
    {
        "name": "JAWAHAR NAVODAYA VIDYALAYA, PASCHIM MEDINIPUR"
    },
    {
        "name": "JAWAHAR NAVODAYA VIDYALAYA, DURGAPUR"
    },
    {
        "name": "JAWAHAR NAVODAYA VIDYALAYA PURBA MEDINIPUR"
    },
    {
        "name": "JAWAHAR NAVODAYA VIDYALAYA"
    },
    {
        "name": "JARA JAGANMOHINI BALIKA VIDYALAYA"
    },
    {
        "name": "JANGALPARA K.D.HIGH SCHOOL"
    },
    {
        "name": "JANARDANDIH HIGH SCHOOL"
    },
    {
        "name": "JAMGRAM JANARDAN INSTITUTION"
    },
    {
        "name": "JAMBAD HIGH SCHOOL"
    },
    {
        "name": "JAMBAD ANCHALIK HIGH SCHOOL"
    },
    {
        "name": "JAMAIBATI HIGH SCHOOL"
    },
    {
        "name": "JALSARA RAMKRISHNA HIGH SCHOOL"
    },
    {
        "name": "JALCHAK BALIKA VIDYALYA"
    },
    {
        "name": "JALALPUR HIGH SCHOOL"
    },
    {
        "name": "JAJUR KARUNAMOYEE MUKHOPADHYAY BALIKA VIDYALAYA"
    },
    {
        "name": "JAIPUR GIRLS HIGH SCHOOL"
    },
    {
        "name": "JAHANGIRPUR HIGH SCHOOL"
    },
    {
        "name": "JAGATPUR RUKMINI VIDYAMANDIR"
    },
    {
        "name": "JAGATPUR HIGH SCHOOL"
    },
    {
        "name": "JAGADISHPUR S K INSTITUTION"
    },
    {
        "name": "JAGADBANDHU INSTITUTION"
    },
    {
        "name": "JADAVPUR VIDYAPITH"
    },
    {
        "name": "JADAVPUR SAMMILITA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "JADAVPUR N K PAL ADARSHA SIKSHAYATAN"
    },
    {
        "name": "JADAVPUR HIGH SCHOOL"
    },
    {
        "name": "JADAVPUR BAGHAJATIN HIGH SCHOOL"
    },
    {
        "name": "JABARRAH HIGH SCHOOL"
    },
    {
        "name": "ISPAT NAGARI MEGHNAD SAHA MADHYAMIK VIDYALAYA [+2]"
    },
    {
        "name": "ISLAMPUR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "ISLAMPUR GIRLS HIGH SCHOOL"
    },
    {
        "name": "ISLAMPUR D.U.F.G. HIGH MADRASAH"
    },
    {
        "name": "ISHAQUE MAJLISPUR HIGH SCHOOL"
    },
    {
        "name": "INTERNATIONAL PUBLIC SCHOOL, JESSORE ROAD"
    },
    {
        "name": "INFOCUS INDIA PUBLIC SCHOOL"
    },
    {
        "name": "INFOCUS INDIA PUBLIC SCHOOL"
    },
    {
        "name": "INDUS VALLEY WORLD SCHOOL"
    },
    {
        "name": "INDUMATI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "INDPUR SAROJINI BALIKA VIDYALAYA"
    },
    {
        "name": "INDO TIBETAN HIGHER SECONDARY SCHOOL KALIMPONG"
    },
    {
        "name": "INDIRA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "INDIRA GANDHI MEMORIAL HIGH SCHOOL, DUM DUM"
    },
    {
        "name": "INDIAN BOARD OF SCHOOL EDUCATION, HOWRAH"
    },
    {
        "name": "INDIA INTERNATIONAL SCHOOL"
    },
    {
        "name": "ILSOBA MONDLAI GIRL&#8217;S HIGH SCHOOL"
    },
    {
        "name": "ICHHAR HIGH SCHOOL"
    },
    {
        "name": "ICHAPUR N.C HIGH SCHOOL"
    },
    {
        "name": "ICHAG HIGH SCHOOL"
    },
    {
        "name": "I B MEMORIAL INSTITUTE"
    },
    {
        "name": "HUTMURA HIGH SCHOOL"
    },
    {
        "name": "HUTMURA HARIMATI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "HURA HIGH SCHOOL"
    },
    {
        "name": "HURA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "HOWRI DINANATH HIGH SCHOOL"
    },
    {
        "name": "HOWRAH VIVEKANANDA INSTITUTION"
    },
    {
        "name": "HOWRAH SANGHA ADARSHA BALIKA VIDYALAYA"
    },
    {
        "name": "HOWRAH JOGESH CHANDRA GIRLS SCHOOL"
    },
    {
        "name": "HOWARD MEMORIAL SCHOOL"
    },
    {
        "name": "HOOMGARH GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "HOOGHLY GOURHARI HARIJAN VIDYAMANDIR"
    },
    {
        "name": "HOOGHLY GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "HOLY PALACE MULTIPURPOSE INSTITUTE, TEGHORIA"
    },
    {
        "name": "HOLY CHRIST SCHOOL, TOBIN ROAD"
    },
    {
        "name": "HOLY CHILD INSTITUTE GIRLS&#8217; HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "HOLY CHILD GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "HOLY ANGELS&#8217; SCHOOL"
    },
    {
        "name": "HIRENDRA LEELA PATRANAVIS SCHOOL"
    },
    {
        "name": "HINDU SCHOOL"
    },
    {
        "name": "HINDU MAHILA VIDYALAYA"
    },
    {
        "name": "HILI RAMANATH HIGH SCHOOL"
    },
    {
        "name": "HIJULI HIGH SCHOOL"
    },
    {
        "name": "HETGUGUI HIGH SCHOOL"
    },
    {
        "name": "HESHAHATU FATE SING HIGH SCHOOL"
    },
    {
        "name": "HERITAGE SCHOOL"
    },
    {
        "name": "HERITAGE ACADEMY HIGH SCHOOL"
    },
    {
        "name": "HERIADAHA SUDHA PRASAD BALIKA VIDYAMANDIR"
    },
    {
        "name": "HAZI OMAR ALI SMRITI VIDYALAYA"
    },
    {
        "name": "HAZI MD. ALI HIGH SCHOOL"
    },
    {
        "name": "HATIMARI HIGH SCHOOL"
    },
    {
        "name": "HARYANA VIDYA MANDIR, SALT LAKE."
    },
    {
        "name": "HARVARD HOUSE HIGH SCHOOL"
    },
    {
        "name": "HARTLEY&#8217;S HIGH SCHOOL, SARAT BOSE ROAD"
    },
    {
        "name": "HARROW HALL, PARK STREET"
    },
    {
        "name": "HAROWA HIGH SCHOOL"
    },
    {
        "name": "HARIYANA VIDYA MANDIR"
    },
    {
        "name": "HARIPUR DURGAPADA MEMORIAL HIGH SCHOOL"
    },
    {
        "name": "HARINDANGA HIGH SCHOOL"
    },
    {
        "name": "HARINAVI DVAS HIGH SCHOOL"
    },
    {
        "name": "HARIMARA KONARPUR S S C HIGH SCHOOL"
    },
    {
        "name": "HARIJHAMA HIGH SCHOOL"
    },
    {
        "name": "HARE SCHOOL"
    },
    {
        "name": "HARDA RAMKRISHNA HIGH SCHOOL H.S"
    },
    {
        "name": "HARAL DASPUR BALIKA VIDYALAYA"
    },
    {
        "name": "HALDIBARI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "HALDIA GOVT. SPONS. VIVEKANANDA VIDYABHAWAN"
    },
    {
        "name": "HALDIA GOVERNMENT SPONSORED X CLASS SECONDARY SCHOOL"
    },
    {
        "name": "HADALDA U.N. HIGH SCHOOL"
    },
    {
        "name": "H.M. EDUCATION CENTRE"
    },
    {
        "name": "GYAN BHARATI VIDYALAYA"
    },
    {
        "name": "GUTHIN HIGH SCHOOL"
    },
    {
        "name": "GUSHKARA BALIKA VIDYALAYA"
    },
    {
        "name": "GURUKUL VIDYA MANDIR, JOKA"
    },
    {
        "name": "GURUKUL PUBLIC SCHOOL"
    },
    {
        "name": "GURGURIPAL HIGH SCHOOL"
    },
    {
        "name": "GURBARI M.B.A.C. HIGH SCHOOL"
    },
    {
        "name": "GURBACHAN SINGH SONDHI GIRLS SCHOOL"
    },
    {
        "name": "GURAP R K INSTITUTION"
    },
    {
        "name": "GUMRA C.B.A. HIGH SCHOOL"
    },
    {
        "name": "GRIFFITH&#8217;S  HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "GRIFFINS INTERNATIONAL SCHOOL"
    },
    {
        "name": "GRAMYA HITAKARI GIRLS&#8217; SCHOOL"
    },
    {
        "name": "GRACE LING LIANG ENGLISH SCHOOL"
    },
    {
        "name": "GOVT. SPONSORED MULTIPURPOSE SCHOOL (BOYS) TAKI HOUSE"
    },
    {
        "name": "GOVINDAPUR HIGH SCHOOL"
    },
    {
        "name": "GOVERNMENT MODEL  SCHOOL, SITALKUCHI"
    },
    {
        "name": "GOUTAM SMRITI SATPATI BINAPANI VIDYAMANDIR"
    },
    {
        "name": "GOURNAGAR HIGH SCHOOL"
    },
    {
        "name": "GOURIO HIGH SCHOOL"
    },
    {
        "name": "GOURBAZAR RAMPADA HIGH SCHOOL"
    },
    {
        "name": "GOPALPUR UNION RAKHAL VIDYALAYA"
    },
    {
        "name": "GOPALPUR HIGH SCHOOL"
    },
    {
        "name": "GOPALPUR DESHBANDHU CHITTARANJAN HIGH SCHOOL"
    },
    {
        "name": "GOPALGANJ PRIYANATH BANIBHABAN"
    },
    {
        "name": "GOLPUR SARADAMONI HIGH SCHOOL"
    },
    {
        "name": "GOKHALE MEMORIAL GIRLS HIGH SCHOOL"
    },
    {
        "name": "GOGHAT BHAGABATI BALIKA VIDYALAYA"
    },
    {
        "name": "GODIBERO SRCA VIDYALAYA"
    },
    {
        "name": "GODIBERO S. J. GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "GODDIH LAGUDIH HIGH SCHOOL"
    },
    {
        "name": "GOBINDAPUR R. K. HIGH SCHOOL"
    },
    {
        "name": "GOBINDAPUR MAKRAMPUR S.S.S NIKETAN"
    },
    {
        "name": "GOBINDAPUR HIGH SCHOOL"
    },
    {
        "name": "GOALTORE HIGH SCHOOL"
    },
    {
        "name": "GOALTORE GIRL&#8217;S HIGH SCHOOL"
    },
    {
        "name": "GOALPARA HIGH SCHOOL"
    },
    {
        "name": "GK URDU HIGH SCHOOL"
    },
    {
        "name": "GITDABLING HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "GITARAM HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "GIRIA HIGH SCHOOL"
    },
    {
        "name": "GHUTIABAZAR MALLICKBATI PATHSALA"
    },
    {
        "name": "GHUGHUMARI HIGH SCHOOL"
    },
    {
        "name": "GHOSHPUR PALLISHREE GIRLS HIGH SCHOOL"
    },
    {
        "name": "GHOSHPARA SARVAPALLI VIDYANIKETAN"
    },
    {
        "name": "GHOSHPARA NISCHINDA BALIKA VIDYAPITH"
    },
    {
        "name": "GHOSHKIRA HIGH SCHOOL"
    },
    {
        "name": "GHONGA HIGH SCHOOL"
    },
    {
        "name": "GHATIHARANIA HIGH SCHOOL"
    },
    {
        "name": "GHATESWAR BALIKA VIDYALAYA"
    },
    {
        "name": "GHATBERA KEROWA HIGH SCHOOL"
    },
    {
        "name": "GHATAL BASANTA KUMARI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "GHAT RANGAMATI HIGH SCHOOL"
    },
    {
        "name": ""
    },
    {
        "name": "GHANASHYAMBATI SAYADATIYA SENIOR MADRASAH"
    },
    {
        "name": "GHANARAMPUR SARADA VIDYAPITH HIGH SCHOOL"
    },
    {
        "name": ""
    },
    {
        "name": "GEMS AKADEMIA INTERNATIONAL SCHOOL"
    },
    {
        "name": "GARIA HARIMATI DEVI UCHCHA BALIKA VIDYALAYA"
    },
    {
        "name": "GARIA BARADA PRASAD HIGH SCHOOL"
    },
    {
        "name": "GARHBETA UMA DEVI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "GARHBETA UMA DEVI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "GARFA DHIRENDRA NATH MEMORIAL GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "GARFA DHIRENDRA NATH MEMORIAL BOYS&#8217; HIGH SCHOOL"
    },
    {
        "name": "GARDEN HIGH SCHOOL IISER KOLKATA CAMPUS"
    },
    {
        "name": "GARDEN HIGH SCHOOL"
    },
    {
        "name": "GARAPHUSRA HIGH SCHOOL"
    },
    {
        "name": "GANGARAMPUR HIGH SCHOOL"
    },
    {
        "name": "GANDHI HIGH VIDYALAYA"
    },
    {
        "name": "GANDHI COLONY MADHYAMIK VIDYALAYA BOY&#8217;S (H.S.)"
    },
    {
        "name": "GAITA HIGH SCHOOL"
    },
    {
        "name": "GAIRKATA HIGH SCHOOL"
    },
    {
        "name": "GAHAMI SIKHAR BASINI BALIKA VIDYALAYA"
    },
    {
        "name": "GAGNABAD HIGH SCHOOL"
    },
    {
        "name": ""
    },
    {
        "name": "G.D.BIRLA CENTRE FOR EDUCATION"
    },
    {
        "name": "G.B. MEMORIAL INSTITUTION"
    },
    {
        "name": "G. D. GOENKA PUBLIC SCHOOL"
    },
    {
        "name": "G S S GIRLS SCHOOL, MUDIALI"
    },
    {
        "name": "FUTURE HOPE SCHOOL"
    },
    {
        "name": "FUTURE CAMPUS SCHOOL, SONARPUR"
    },
    {
        "name": "FRANK ANTHONY PUBLIC SCHOOL"
    },
    {
        "name": "FILIX SCHOOL OF EDUCATION"
    },
    {
        "name": "FATEPUR C.D HIGH SCHOOL"
    },
    {
        "name": "FATAPUKUR SARADAMONI VIDYALAYA"
    },
    {
        "name": "FALAKATA HIGH SCHOOL"
    },
    {
        "name": "ELLIOT DAY SCHOOL"
    },
    {
        "name": "ELITE CO-ED H.S SCHOOL"
    },
    {
        "name": "ELIAS MEYER FREE SCHOOL &#038; TT, B.B. GANGULY STREET"
    },
    {
        "name": "ELAHI BAKHSH HIGH SCHOOL"
    },
    {
        "name": "EKDUARA HIGH SCHOOL"
    },
    {
        "name": "EAST CALCUTTA NATIONAL SCHOOL"
    },
    {
        "name": "DURKU SRI ARABINDA VIDYAPITH"
    },
    {
        "name": "DURBACHATI MILAN VIDYAPITH"
    },
    {
        "name": "DUMURDIH BARAMUKRU HIGH SCHOOL"
    },
    {
        "name": "DUM DUM KISHORE BHARATI HIGH SCHOOL"
    },
    {
        "name": "DUM DUM K K HINDU ACADEMY"
    },
    {
        "name": "DUFF HIGH SCHOOL FOR GIRLS&#8217;"
    },
    {
        "name": "DUILLYA PANCHPARA HIGH SCHOOL"
    },
    {
        "name": "DUFF HIGH SCHOOL FOR GIRLS&#8217;"
    },
    {
        "name": "DUDHUNDA ALOKE TIRTHA VIDYAPITH"
    },
    {
        "name": "DUBRA HIGH SCHOOL"
    },
    {
        "name": "DUBRA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "DSK DAV PUBLIC SCHOOL"
    },
    {
        "name": "DREAMLAND SCHOOL, MAKHLA"
    },
    {
        "name": "DREAM ANGELS ACADEMY"
    },
    {
        "name": "DR.SUDHAKRISHNA HIGH SCHOOL"
    },
    {
        "name": "DR.A.N.JHA HIGH SCHOOL"
    },
    {
        "name": "DR. RAJENDRA PRASAD GIURLS HIGH SACHOOL"
    },
    {
        "name": "DR I B THAPA NEPALI VIDYALAYA"
    },
    {
        "name": "DOUGLAS MEMORIAL HIGHER SECONDARY SCHOOL, BARRACKPORE"
    },
    {
        "name": "DORODIH H.G.K. VIDYAPITH"
    },
    {
        "name": "DORI AYODHYA K C HIGH SCHOOL"
    },
    {
        "name": "DON BOSCO SCHOOL, PARK CIRCUS"
    },
    {
        "name": "DON BOSCO SCHOOL, BANDEL"
    },
    {
        "name": "DOLNA DAY SCHOOL"
    },
    {
        "name": "DISARI PUBLIC SCHOOL"
    },
    {
        "name": "DINHATA SONI DEBI JAIN HIGH SCHOOL"
    },
    {
        "name": "DINHATA GIRLS HIGH SCHOOL"
    },
    {
        "name": "DIGSUI SADHANA BANGA VIDYALAYA H.S."
    },
    {
        "name": "DIGNA HIGH SCHOOL"
    },
    {
        "name": "DIGHI HIGH SCHOOL"
    },
    {
        "name": "DIBAKARPUR HIGH SCHOOL"
    },
    {
        "name": "DHULAURI HIGH SCHOOL"
    },
    {
        "name": "DHERUA ANCHAL SATABALA HIGH SCHOOL"
    },
    {
        "name": "DHARMADANGA HIGH SCHOOL"
    },
    {
        "name": "DHARMADANGA HIGH 6"
    },
    {
        "name": "DHARENDA HIGH SCHOOL"
    },
    {
        "name": "DHARAMPUR HIGH SCHOOL"
    },
    {
        "name": "DHARAMPUR GIRLS HIGH SCHOOL"
    },
    {
        "name": "DHANYAGACHHI HIGH SCHOOL"
    },
    {
        "name": "DHANUDIH HIGH SCHOOL"
    },
    {
        "name": "DHANARA ASHRAM U.M VIDYAPITH  H.S"
    },
    {
        "name": "DHANARA ASHRAM U.M VIDYAPITH"
    },
    {
        "name": "DHAMSA P C SEN INSTITUTION"
    },
    {
        "name": "DHAKSHILA HIGH SCHOOL"
    },
    {
        "name": "DHAGARA ADIBASI HIGH SCHOOL"
    },
    {
        "name": "DHADIKA HIGH SCHOOL"
    },
    {
        "name": "DEULPARA B.N VIDYANIKETAN"
    },
    {
        "name": "DHABLAT LAKSHMAN PARABESH HIGH SCHOOL"
    },
    {
        "name": "DEULPARA B.N VIDYANIKETAN"
    },
    {
        "name": "DEULI KALASBARH RAMKRISHNA VIDYAPITH"
    },
    {
        "name": "DEULI HIGH SCHOOL"
    },
    {
        "name": "DEROZIO MISSION HIGH SCHOOL"
    },
    {
        "name": "DEOLI PAHARGORA HIGH SCHOOL H.S"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), SILIGURI"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), DOOARS"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), RUBY PARK"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), NORTH KOLKATA"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), NEWTOWN"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), MEGACITY"
    },
    {
        "name": "DELHI PUBLIC SCHOOL (DPS), JOKA"
    },
    {
        "name": "DELHI PUBLIC SCHOOL"
    },
    {
        "name": "DEBIGANJ VIVEKANANDA HIGH SCHOOL"
    },
    {
        "name": "DEBAGRAM MAHADEB HIGH SCHOOL"
    },
    {
        "name": "DE PAUL SCHOOL, GARIA"
    },
    {
        "name": "DE PAUL SCHOOL, BANSDRONI"
    },
    {
        "name": "DAYANAND ANGLO-VEDIC PUBLIC SCHOOL"
    },
    {
        "name": "DE PAUL SCHOOL, BANSDRONI"
    },
    {
        "name": "DAYANAND ANGLO-VEDIC PUBLIC SCHOOL"
    },
    {
        "name": "DAV SCHOOL"
    },
    {
        "name": "DAV PUBLIC SCHOOL, MIDNAPORE"
    },
    {
        "name": "DAV PUBLIC SCHOOL, MIDNAPORE"
    },
    {
        "name": "DAV PUBLIC SCHOOL, HALDIA"
    },
    {
        "name": "DAV PUBLIC SCHOOL, ECL.JHANJRA"
    },
    {
        "name": "DAV PUBLIC SCHOOL DVC MTPS"
    },
    {
        "name": "DAV MODEL SCHOOL, KSTP, ASANSOL"
    },
    {
        "name": "DAV MODEL SCHOOL, DURGAPUR"
    },
    {
        "name": "DAV MODEL SCHOOL, DURGAPUR"
    },
    {
        "name": "DAV MODEL SCHOOL IIT KHARAGPUR"
    },
    {
        "name": "DAULATNAGAR HIGH SCHOOL"
    },
    {
        "name": "DASPARA HIGH SCHOOL"
    },
    {
        "name": "DASAGRAM SATISHCHANDRA SARBARTHASADHAK SIKSHASADAN"
    },
    {
        "name": "DARJEELING PUBLIC SCHOOL"
    },
    {
        "name": "DARIMANPUR BSC HIGH SCHOOL"
    },
    {
        "name": "DARDA KHELU HEMBRAM HIGH SCHOOL"
    },
    {
        "name": "DAR AL-ARQAM INTERNATIONAL SCHOOL"
    },
    {
        "name": "DANTON H. S. MULTIPURPOSE SCHOOL"
    },
    {
        "name": "DANDUDIH S.N HIGH SCHOOL"
    },
    {
        "name": "DANDIPUR MANMATHA HAZRA VIDYAMANDIR"
    },
    {
        "name": "DAMODARPU HIGH SCHOOL"
    },
    {
        "name": "DAMDAMA N. M. HIGH SCHOOL"
    },
    {
        "name": "DAMDAMA HIGH SCHOOL"
    },
    {
        "name": "DALKHOLA HIGH SCHOOL"
    },
    {
        "name": "DALKHOLA GIRLS HIGH SCHOOL"
    },
    {
        "name": "DALIMGAON HIGH SCHOOL"
    },
    {
        "name": "DALDALI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "DAKSHIN RASULPUR HIGH SCHOOL"
    },
    {
        "name": "DABRI S.S.B. HIGH SCHOOL"
    },
    {
        "name": "DABCHA NABAKOLA HIGH SCHOOL"
    },
    {
        "name": "D.A.V PUBLIC SCHOOL (DAV), TARATALA"
    },
    {
        "name": "CONVENT OF OUR LADY OF PROVIDENCE GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "COMPANYCHAR MAHESWARI HIGH SCHOOL"
    },
    {
        "name": "COLLINS INST., LENIN SARANI"
    },
    {
        "name": "CITY ENGLISH SCHOOL"
    },
    {
        "name": "CHRISTOPHER DAY SCHOOL"
    },
    {
        "name": "CHRIST CHURCH GIRLS HIGH SCHOOL, DUM DUM"
    },
    {
        "name": "CHOWRINGHEE HIGH SCHOOL"
    },
    {
        "name": "CHOWBERIA ANNADA SUNDARI MITRA BALIKA VIDYALAYA"
    },
    {
        "name": "CHOROLMONI HIGH SCHOOL"
    },
    {
        "name": "CHOPRA HIGH SCHOOL"
    },
    {
        "name": "CHOA B B PAL VIDYANIKETAN"
    },
    {
        "name": "CHITTARANJON COLONY HINDU VIDYAPITH FOR GIRLS&#8217; H.S"
    },
    {
        "name": "CHITTARANJAN GIRLS HIGH SCHOOL"
    },
    {
        "name": "CHITTARANJAN COLONY HINDU VIDYAPITH, DESHBANDHU NAGAR"
    },
    {
        "name": "CHITTA MAHATO ILLU HIGH BALIKA VIDYALAYA"
    },
    {
        "name": "CHIRUDIH VIVEKANANDA HIGH SCHOOL"
    },
    {
        "name": "CHIRANTANI VIDYAPITH FOR GIRLS'(HIGH)"
    },
    {
        "name": "CHINSURAH BALIKA SIKSHA MANDIR HIGH SCHOOL"
    },
    {
        "name": "CHILDREN&#8217;S WELFARE ASSOCIATION HIGH SCHOOL FOR GIRLS&#8217;"
    },
    {
        "name": "CHILDREN&#8217;S FOUNDATION SCHOOL, GOPALPUR, BUDGE BUDGE ROAD"
    },
    {
        "name": "CHILDREN ACADEMY WBBSE HIGH SCHOOL, THAKURPUKUR"
    },
    {
        "name": "CHILADANGI RABINDRA VIDYABITHI"
    },
    {
        "name": "CHHOTAKHELNA SURENDRA SMRITY VIDYAMANDIR"
    },
    {
        "name": "CHHIRUDIH RAGHUNATH JEW HIGH SCHOOL"
    },
    {
        "name": "CHHILIMPUR HIGH SCHOOL"
    },
    {
        "name": "CHHENDAPATHAR SKST HIGH SCHOOL"
    },
    {
        "name": "CHHANDRA HIGH SCHOOL"
    },
    {
        "name": "CHETLA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "CHETLA BOYS&#8217; HIGH SCHOOL"
    },
    {
        "name": "CHENGMARI(WME)HIGH SCHOOL"
    },
    {
        "name": "CHELYAMA B.P. HIGH SCHOOL"
    },
    {
        "name": "CHELYAMA B.C GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "CHATTERJEE NOBLE SCHOOL"
    },
    {
        "name": "CHATRA RAJBANDH RADHA DAMODAR HIGH SCHOOL"
    },
    {
        "name": "CHATRA NETAJI BALIKA SIKSHA NIKETAN"
    },
    {
        "name": "CHATRA HIGH SCHOOL"
    },
    {
        "name": "CHARSUJAPUR HIGH SCHOOL"
    },
    {
        "name": "CHANDRAKONA ROAD SARADAMOYEE HIGH SCHOOL"
    },
    {
        "name": "CHANDRA HIGH SCHOOL"
    },
    {
        "name": "CHANDPARA HIGH SCHOOL"
    },
    {
        "name": "CHANDMARI NAGENDRABALA BALIKA VIDYALAYA"
    },
    {
        "name": "CHANDITALA SIKSHA NIKETAN HIGH SCHOOL HS"
    },
    {
        "name": "CHANDIPUR BRS HIGH SCHOOL"
    },
    {
        "name": "CHANDERNAGORE KANAILAL VIDYAMANDIR (FRENCH SECTION)"
    },
    {
        "name": "CHANDERNAGORE BANGA VIDYALAYA"
    },
    {
        "name": "CHANDANPUR SV HIGH SCHOOL"
    },
    {
        "name": "CHANDANPUR HIGH SCHOOL"
    },
    {
        "name": "CHAMTA ADARSHA HIGH SCHOOL"
    },
    {
        "name": "CHAMPDANI NIBARAN MUKHOPADHYAY VIDYAMANDIR"
    },
    {
        "name": "CHAMPAPUKUR MADHYAMIK VIDYALAYA"
    },
    {
        "name": "CHAKRA BERIA HIGH SCHOOL (H.S)"
    },
    {
        "name": "CHAKIRBON HIGH SCHOOL"
    },
    {
        "name": "CHAKDAH PURBACHAL VIDYAPITH"
    },
    {
        "name": "CHAKBAD HIGH SCHOOL"
    },
    {
        "name": "CHAKALTA BHAGABANDH HIGH SCHOOL"
    },
    {
        "name": "CHAIPAT HIGH SCHOOL"
    },
    {
        "name": "CHAIPAT GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "CHAINPUR HIGH SCHOOL"
    },
    {
        "name": "CENTRAL MODERN SCHOOL"
    },
    {
        "name": "CENTRAL MODEL SCHOOL, A J C BOSE ROAD"
    },
    {
        "name": "CENTRAL MODEL SCHOOL (BARRACKPORE)"
    },
    {
        "name": "CENTRAL COLLEGIATE SCHOOL (BOYS`), BIDHAN SARANI"
    },
    {
        "name": "CATHEDRAL MISSION HIGH SCHOOL"
    },
    {
        "name": "CARMEL SCHOOL"
    },
    {
        "name": "CAMPION INTERNATIONAL SCHOOL"
    },
    {
        "name": "CALCUTTA TRAINING ACADEMY"
    },
    {
        "name": "CALCUTTA PUBLIC SCHOOL KALIKAPUR"
    },
    {
        "name": "CALCUTTA PUBLIC SCHOOL BIDHAN PARK"
    },
    {
        "name": "CALCUTTA INTERNATIONAL SCHOOL"
    },
    {
        "name": "CALCUTTA INTERNATIONAL SCHOOL"
    },
    {
        "name": "CALCUTTA GIRLS HIGH SCHOOL"
    },
    {
        "name": "CALCUTTA DAY SCHOOL"
    },
    {
        "name": "CALCUTTA AIRPORT ENGLISH HIGH SCHOOL"
    },
    {
        "name": "CAESAR SCHOOL"
    },
    {
        "name": "BURUL GIRLS HIGH SCHOOL"
    },
    {
        "name": "BURNPUR RIVERSIDE SCHOOL, CHITTARANJAN"
    },
    {
        "name": "BURNPUR RIVERSIDE SCHOOL, BURNPUR"
    },
    {
        "name": "BURDWAN MUNICIPAL HIGH SCHOOL"
    },
    {
        "name": "BURDWAN ADARSHA VIDYALAYA(HIGH SCHOOL)"
    },
    {
        "name": "BUDHIA HIGH MADRASAH"
    },
    {
        "name": "BUDHIA HIGH MADRASAH"
    },
    {
        "name": "BUD BUD CHATTI HINDI HIGH SCHOOL"
    },
    {
        "name": "BRIDGE INTERNATIONAL SCHOOL"
    },
    {
        "name": "BRATACHARI VIDYASRAM"
    },
    {
        "name": "BRAHMANPARA CHINTAMONI BALIKA VIDYALAYA"
    },
    {
        "name": "BOXIRHAT GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BORUNA SATSANGA HIGH SCHOOL H.S."
    },
    {
        "name": "BORAM HIGH SCHOOL"
    },
    {
        "name": "BORAL SWAMIJI VIDYAPITH HIGH SCHOOL (H.S)"
    },
    {
        "name": "BONGABARI GIRLS HIGH SCHOOL"
    },
    {
        "name": "BOLDIABASHA HIGH SCHOOL"
    },
    {
        "name": "BODRA HIGH SCHOOL"
    },
    {
        "name": "BODHICARIYA SENIOR SECONDARY SCHOOL"
    },
    {
        "name": "BODHI BHAVANS COLLEGIATE SCHOOL"
    },
    {
        "name": "BLOOMING BUDS SCHOOL"
    },
    {
        "name": "ZILLA PUBLIC SCHOOL"
    },
    {
        "name": "ZENITH WORLD SCHOOL"
    },
    {
        "name": "BISRI ANCHALIK PDGM HIGH SCHOOL"
    },
    {
        "name": "YOGODA SATSANGA KSHIRODAMOYEE VIDYAPITH"
    },
    {
        "name": "BISHRAIL HIGH SCHOOL"
    },
    {
        "name": "YOGODA SAT SANGA KANYA VIDYALAYA"
    },
    {
        "name": "WWA COSSIPORE ENGLISH SCHOOL"
    },
    {
        "name": "WHEATON INTERNATIONAL SCHOOL"
    },
    {
        "name": "BISHPURIA HIGH SCHOOL"
    },
    {
        "name": "WEST POINT SCHOOL"
    },
    {
        "name": "WELLAND GOULDSMITH SCHOOL"
    },
    {
        "name": "BISHOP GEORGE MISSION SCHOOL, CIT ROAD, BELIAGHATA"
    },
    {
        "name": "VIVEKANANDA VIDYAPEETH"
    },
    {
        "name": "VIVEKANANDA MISSION HIGH SCHOOL"
    },
    {
        "name": "BISHNUPUR HERITAGE SCHOOL"
    },
    {
        "name": "BIRSINGHA VIDYASAGAR BALIKA VIDYAPITH"
    },
    {
        "name": "BIRLA HIGH SCHOOL-MUKUNDAPUR"
    },
    {
        "name": "BIRLA HIGH SCHOOL FOR GIRLS"
    },
    {
        "name": "BIRLA HIGH SCHOOL FOR BOYS"
    },
    {
        "name": "VIDYASHRAM D V HIGH SCHOOL"
    },
    {
        "name": "BIRLA DIVYA JYOTI"
    },
    {
        "name": "VIDYASAGAR VIDYAPITH, MIDNAPORE"
    },
    {
        "name": "VIDYASAGAR TAPASILI ADIBASI SIKSHA NIKETAN (HIGH SCHOOL)"
    },
    {
        "name": "BIRLA BHARATI SCHOOL"
    },
    {
        "name": "VIDYASAGAR HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "VIDYANJALI INTERNATIONAL SCHOOL, CAMBRIDGE INTERNATIONAL CENTRE"
    },
    {
        "name": "VIDYA NIKETAN, BANSDRONI"
    },
    {
        "name": "VIDYA BHARATI GLOBAL SCHOOL, DUM DUM"
    },
    {
        "name": "VALMIKI VIDYAPITH HIGH SCHOOL"
    },
    {
        "name": "UTTAR DALLHOLA HIGH SCHOOL"
    },
    {
        "name": "USHA MARTIN SCHOOL MALDA"
    },
    {
        "name": "UPARJARI HIGH SCHOOL"
    },
    {
        "name": "UNITED MISSIONARY GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "UNION CHAPEL SCHOOL"
    },
    {
        "name": "UDAYPUR HIGH SCHOOL"
    },
    {
        "name": "BIRLA BHARATI SCHOOL"
    },
    {
        "name": "UDAYPUR BALIKA BIDYANIKETAN"
    },
    {
        "name": "BIRGRAM HIGH SCHOOL"
    },
    {
        "name": "UCHITPORE PALLI PRAN HIGH SCHOOL"
    },
    {
        "name": "UCHCHA BALIKA VIDYAMANDIR, BARISHA"
    },
    {
        "name": "U.P. PUBLIC SCHOOL"
    },
    {
        "name": "BIRGRAM HIGH SCHOOL"
    },
    {
        "name": "TUNTURI HIGH SCHOOL"
    },
    {
        "name": "TULIN TAPOBAN HIGH SCHOOL"
    },
    {
        "name": "BIRCHANDRAPUR NITYANAND HIGH SCHOOL"
    },
    {
        "name": "TULIN JAI SIA RAM HIGH SCHOOL( H.S. )"
    },
    {
        "name": "TRIBENI TISSUES VIDYAPITH"
    },
    {
        "name": "BIRBHADRAPUR RAGHUNATH HIGH SCHOOL"
    },
    {
        "name": "TORIA HIGH SCHOOL"
    },
    {
        "name": "TITAGARH KRISHNA NATH MUNICIPAL HIGH SCHOOL (H.S.), TALPUKUR, BARRACKPORE."
    },
    {
        "name": "BINODINI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "TITAGARH ANGLO VERNACULAR HIGH SCHOOL"
    },
    {
        "name": "TITAGARH ANDHRA VIDYALAYAM"
    },
    {
        "name": "BINDOLE HIGH SCHOOL"
    },
    {
        "name": "TIRTHAPATI INSTITUTION"
    },
    {
        "name": "TILURI KRIPAMOYEE HIGH SCHOOL"
    },
    {
        "name": "BILWESWAR BINODELAL BALIKA VIDYALAYA"
    },
    {
        "name": "TILJALA HIGH SCHOOL (H.S), TILJALA"
    },
    {
        "name": "TILJALA HIGH SCHOOL"
    },
    {
        "name": "TILJALA BRAJANATH VIDYAPITH"
    },
    {
        "name": "TILDANGA HIGH SCHOOL"
    },
    {
        "name": "BILTORA HIGH SCHOOL"
    },
    {
        "name": "THE ROYAL INTERNATIONAL SCHOOL"
    },
    {
        "name": "THE PARK INSTITUTION"
    },
    {
        "name": "BIJOYDIH HIGH SCHOOL"
    },
    {
        "name": "THE PARK ENGLISH SCHOOL"
    },
    {
        "name": "THE NEWTOWN SCHOOL, NEW TOWN"
    },
    {
        "name": "THE NEW HORIZON HIGH SCHOOL"
    },
    {
        "name": "BIDYA BHARTI GIRLS&#8217; HIGH SCHOOL, NEW ALIPORE"
    },
    {
        "name": "THE MODERN ACADEMY"
    },
    {
        "name": "THE HERITAGE SCHOOL (IB)"
    },
    {
        "name": "BIDYA BHABAN HIGH SCHOOL"
    },
    {
        "name": "THE HERITAGE SCHOOL"
    },
    {
        "name": "BIDHANNAGAR MUNICIPAL SCHOOL"
    },
    {
        "name": "THE HAMILTON HIGH SCHOOL, TAMLUK"
    },
    {
        "name": "THE GOOD SHEPHERD MISSION SCHOOL, BARISHA"
    },
    {
        "name": "BIDHANNAGAR GOVT. SPONSORED GIRLS HIGH SCHOOL"
    },
    {
        "name": "THE FUTURE SCHOLARS ACADEMY"
    },
    {
        "name": "THE FUTURE FOUNDATION SCHOOL, KOLKATA"
    },
    {
        "name": "BIDHANNAGAR GOVERNMENT HIGH SCHOOL"
    },
    {
        "name": "THE FRANK ANTHONY PUBLIC SCHOOL"
    },
    {
        "name": "THE CRESCENT SCHOOL, KOLKATA"
    },
    {
        "name": "BIBHISHANPUR HIGH SCHOOL"
    },
    {
        "name": "THE CRESCENT SCHOOL"
    },
    {
        "name": "THE CAMBRIDGE SCHOOL (CALCUTTA NEW SCHOOL SOCIETY)"
    },
    {
        "name": "THE CALCUTTA EMMANUEL SCHOOL"
    },
    {
        "name": "BHUTAM NIBARAN CHANDRA HIGH SCHOOL"
    },
    {
        "name": "THE CALCUTTA ANGLO GUJARATI SCHOOL"
    },
    {
        "name": "THE BSS SCHOOL (BALLYGUNGE SHIKSHA SADAN)"
    },
    {
        "name": "BHUTA D.A.V HIGH SCHOOL"
    },
    {
        "name": "THE BHAWANIPUR GUJARATI EDUCATION SOCIETY SCHOOL"
    },
    {
        "name": "THE ASSEMBLY OF GOD CHURCH SCHOOL TOLLYGUNGE"
    },
    {
        "name": "THE ASSEMBLY OF GOD CHURCH SCHOOL"
    },
    {
        "name": "BHULKABARI HIGH SCHOOL"
    },
    {
        "name": "BHUIHARA HIGH SCHOOL"
    },
    {
        "name": "BHUERAH SK HIGH SCHOOL"
    },
    {
        "name": "THE ARYANS SCHOOL"
    },
    {
        "name": "BHUBANESWARI JOYKRISHNA HIGH SCHOOL"
    },
    {
        "name": "THAKURANICHAK BB HIGH SCHOOL"
    },
    {
        "name": "TENPUR THAKAMOYEE VIDYAPITH"
    },
    {
        "name": "BHUARAH BNS HIGH SCHOOL"
    },
    {
        "name": "TENPUR NABASAN ANANTARAM HIGH SCHOOL"
    },
    {
        "name": "BHOWRIDIH HIGH SCHOOL"
    },
    {
        "name": "TELINIPARA BHADRESWAR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "TEHATTA HIGH SCHOOL"
    },
    {
        "name": "BHOTEBARI SITANATH HIGH SCHOOL"
    },
    {
        "name": "TEGHARI HIGH SCHOOL"
    },
    {
        "name": "TECHNO INDIA GROUP PUBLIC SCHOOL RAIGANJ"
    },
    {
        "name": "BHOLANANDA NATIONAL VIDYALAYA, BARRACKPORE"
    },
    {
        "name": "TECHNO INDIA GROUP PUBLIC SCHOOL SILIGURI"
    },
    {
        "name": "BHOLANANDA NATIONAL VIDYALAYA"
    },
    {
        "name": "TECHNO INDIA GROUP PUBLIC SCHOOL NABADWIP"
    },
    {
        "name": "TECHNO INDIA GROUP PUBLIC SCHOOL GARIA"
    },
    {
        "name": "TECHNO INDIA GROUP PUBLIC SCHOOL BOLPUR"
    },
    {
        "name": "BHERI BHETKAKHALI HIGH SCHOOL"
    },
    {
        "name": "TECHNO INDIA GROUP PUBLIC SCHOOL"
    },
    {
        "name": "TATU SINGHA SMRITY HIGH SCHOOL"
    },
    {
        "name": "BHEMUA ATAL BEHARI HIGH SCHOOL"
    },
    {
        "name": "TATARPUR HIGH SCHOOL"
    },
    {
        "name": "TATARI KENDADIH HIGH SCHOOL"
    },
    {
        "name": "BHAWANIPUR HIGH SCHOOL"
    },
    {
        "name": "TAPSIKHATA HIGHSCHOOL"
    },
    {
        "name": "TAPNA CHATURBHUJ HIGH SCHOOL"
    },
    {
        "name": "BHAVAN&#8217;S NETAJI SUBHASH CHANDRA BOSE VIDYANIKETAN"
    },
    {
        "name": "TANTIGERIA HIGH SCHOOL"
    },
    {
        "name": "TANTIA HIGH SCHOOL"
    },
    {
        "name": "BHAVAN&#8217;S GANGABUX KANORIA VIDYAMANDIR"
    },
    {
        "name": "TAMRALIPTA PUBLIC SCHOOL"
    },
    {
        "name": "TAMLUK HIGH SCHOOL"
    },
    {
        "name": "BHATPARA AMARKRISHNA PATHSALA"
    },
    {
        "name": "TALDI SURABALA SIKSHAYATAN FOR GIRLS HS"
    },
    {
        "name": "BHASAIPAIKAR HIGH SCHOOL"
    },
    {
        "name": "TALDI MOCHAN CAHND HIGH SCHOOL"
    },
    {
        "name": "TALBANDI BELAYET ALI HIGH SCHOOL"
    },
    {
        "name": "BHARTITARI HIGH SCHOOL"
    },
    {
        "name": "TALAJURI SRIMATI HIGH SCHOOL"
    },
    {
        "name": "TAKI GOVERNMENT HIGH SCHOOL"
    },
    {
        "name": "TAJPUR M.N ROY INSTITUTION"
    },
    {
        "name": "BHARATPUR HIGH SCHOOL"
    },
    {
        "name": "SWAPAN SUBRATA HIGH SCHOOL"
    },
    {
        "name": "SWAMI VIVEKANANDA ACADEMY FOR EDUCATIONAL EXCELLENCE"
    },
    {
        "name": "BHARATIYA VIDYA BHAVAN"
    },
    {
        "name": "SWAMI SHRADDHANANDA VIDYAPITH"
    },
    {
        "name": "SURYA MEMORIAL ACADEMY"
    },
    {
        "name": "SURUK MONDODARI HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "BHARAT ACADEMY &#038; SCIENCES"
    },
    {
        "name": "SURJAPUR HIGH SCHOOL"
    },
    {
        "name": "SURAH KANYA VIDYALAYA"
    },
    {
        "name": "BHANRU HIGH SCHOOL"
    },
    {
        "name": "SUNRISE (ENG.MED) SCHOOL, HOWRAH"
    },
    {
        "name": "SUNITI BALA SADAR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BHANGAR HIGH SCHOOL"
    },
    {
        "name": "SUNDRABANDH HIGH SCHOOL"
    },
    {
        "name": "SUN SHINE ACADEMY"
    },
    {
        "name": "BHANGAMORA NUTANGRAM K.N.C.M. INSTITUTION"
    },
    {
        "name": "SUM INSTITUTION"
    },
    {
        "name": "SUKURHUTU HIGH SCHOOL"
    },
    {
        "name": "BHANDERBERIA DEBENDRA HIGH SCHOOL"
    },
    {
        "name": "SUKHIA POKHRI H.S. SCHOOL"
    },
    {
        "name": "SUDPUR HIGH SCHOOL"
    },
    {
        "name": "BHAMURIA S.M. HIGH (H/S) SCHOOL"
    },
    {
        "name": "SUDHIR MEMORIAL INSTITUTE MADHYAMGRAM"
    },
    {
        "name": "SUDARSANPUR D P U VIDYACHAKRA"
    },
    {
        "name": "BHAIRABPUR RAMGATI VIDYANIKETAN HIGH SCHOOL"
    },
    {
        "name": "SUBHASPALLI BIDYANIKETAN"
    },
    {
        "name": "BHAGWANCHAK PATIRAM SIKSHANIKETAN"
    },
    {
        "name": "SUBHASGRAM NABATARA VIDYALAYA"
    },
    {
        "name": "SUBHASGANJ HIGH SCHOOL"
    },
    {
        "name": "BHAGABATIPUR MILAN VIDYAPITH"
    },
    {
        "name": "STATIONPARA SARANARTHI HIGH SCHOOL"
    },
    {
        "name": "ST. ROBERT&#8217;S HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "ST. MARY&#8217;S CONVENT SCHOOL"
    },
    {
        "name": "BHAGABANTAPUR J S HIGH SCHOOL"
    },
    {
        "name": "ST. JOSEPH&#8217;S CONVENT"
    },
    {
        "name": "ST. ALOYSIUS ORPHANAGE &#038; DAY SCHOOL HOWRAH"
    },
    {
        "name": "BHAGABANDH HIGH SCOOL"
    },
    {
        "name": "ST. XAVIER&#8217;S INSTITUTION (PANIHATI)"
    },
    {
        "name": "ST. XAVIER&#8217;S HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "BHADRESWAR DHARMATALA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "ST. XAVIER&#8217;S HIGH SCHOOL"
    },
    {
        "name": "ST. XAVIER&#8217;S COLLEGIATE SCHOOL"
    },
    {
        "name": "BHABUK RAM MARDI HIGH SCHOOL"
    },
    {
        "name": "ST. VINCENT&#8217;S HIGH AND TECHNICAL SCHOOL"
    },
    {
        "name": "ST. THOMAS BOYS SCHOOL"
    },
    {
        "name": "BETTER HIGH SCHOOL, PRINCE GULAM MD. SHAH ROAD"
    },
    {
        "name": "ST. TERESA&#8217;S SECONDARY SCHOOL"
    },
    {
        "name": "ST. TERESA&#8217;S GIRLS&#8217; H.S.SCHOOL, DARJEELING"
    },
    {
        "name": "BETTEN HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "ST. STEPHENS SCHOOL, DUM DUM"
    },
    {
        "name": "ST. SEBASTIANS SCHOOL, SEAL LANE"
    },
    {
        "name": "BETHUNE COLLEGIATE SCHOOL"
    },
    {
        "name": "ST. PHILOMENA&#8217;S GIRLS HS SCHOOL"
    },
    {
        "name": "BETAI HIGH SCHOOL"
    },
    {
        "name": "ST. PAULS&#8217; SCHOOL"
    },
    {
        "name": "ST. PAUL&#8217;S MISSION SCHOOL"
    },
    {
        "name": "BERIA HIGH SCHOOL"
    },
    {
        "name": "ST. PAUL&#8217;S BOARDING AND DAY SCHOOL"
    },
    {
        "name": "ST. NICHOLAS PUBLIC SCHOOL"
    },
    {
        "name": "ST. MICHAEL;S HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "BERHAMPORE LIPIKA MEMORIAL GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "ST. MARY&#8217;S SCHOOL, RIPON STREET"
    },
    {
        "name": "ST. MARY&#8217;S ORPHANAGE &#038; DAY SCHOOL, KOLKATA"
    },
    {
        "name": "BERHAMPORE J N ACADEMY"
    },
    {
        "name": "ST. MARY&#8217;S GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "ST. MARY&#8217;S CONVENT SCHOOL, SANTRAGACHI."
    },
    {
        "name": "BERHAMPORE GIRLS&#8217; MAHAKALI PATHSALA"
    },
    {
        "name": "ST. MARY&#8217;S &#038; JESUS SCHOOL, BANGUR AVENUE."
    },
    {
        "name": "ST. LUIGI SCHOOL"
    },
    {
        "name": "BERADA HIGH SCHOOL"
    },
    {
        "name": "ST. LAWRENCE HIGH SCHOOL"
    },
    {
        "name": "ST. JUDE&#8217;S HIGH SCHOOL, MADHYAMGRAM"
    },
    {
        "name": "BERACHAMPA DEULIA UCHCHA VIDYALAYA"
    },
    {
        "name": "ST. JUDE&#8217;S HIGH SCHOOL"
    },
    {
        "name": "ST. JOSEPH&#8217;S GIRLS&#8217; H.S SCHOOL, KURSEONG"
    },
    {
        "name": "BERABERIA BHAGIRATH ADARSHA VIDYALAYA"
    },
    {
        "name": "ST. JOSEPH&#8217;S COLLEGE, CALCUTTA"
    },
    {
        "name": "ST. JOSEPH&#8217;S BRIGHT SCHOOL"
    },
    {
        "name": "BERABERI SURYYANARAYAN MEMORIAL HIGH SCHOOL"
    },
    {
        "name": "ST. JOSEPH &#038; MARYS SCHOOL, NEW ALIPORE"
    },
    {
        "name": "ST. JOHN&#8217;S HIGH SCHOOL, LAKEGARDENS, TOLLYGUNGE."
    },
    {
        "name": "BENGAI HIGH SCHOOL"
    },
    {
        "name": "ST. JOHN&#8217;S DIOCESAN GIRLS&#8217; HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "ST. JOAN&#8217;S SCHOOL, SALTLAKE"
    },
    {
        "name": "BELUR T.H.MEMORIAL URDU H.S.SCHOOL(CO-ED)"
    },
    {
        "name": "BELUR HIGH SCHOOL"
    },
    {
        "name": "ST. JAMES&#8217; SCHOOL"
    },
    {
        "name": "BELTALA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BELMURI UNION INSTITUTION"
    },
    {
        "name": "ST. IGNATIUS HIGH SCHOOL"
    },
    {
        "name": "BELMURI BALIKA VIDYALAYA"
    },
    {
        "name": "ST. GEORGE&#8217;S DAY HIGH SCHOOL, COLLIN LANE"
    },
    {
        "name": "ST. FRANCIS XAVIER SCHOOL"
    },
    {
        "name": "BELMA SRI SRI R.N. HIGH SCHOOL`"
    },
    {
        "name": "ST. ANTHONY&#8217;S HIGH SCHOOL, KOLKATA"
    },
    {
        "name": "ST. ANTHONY&#8217;S HIGH SCHOOL CHANDANNAGAR"
    },
    {
        "name": "BELKULAI C.K.A.C. VIDYAPITH"
    },
    {
        "name": "ST. ANTHONY&#8217;S HIGH SCHOOL"
    },
    {
        "name": "ST. ALPHONSUS HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "BELGUMA VIVEKANANDA VIDYAPITH"
    },
    {
        "name": "ST THOMAS SCHOOL, KOLKATA"
    },
    {
        "name": "ST MARYS SCHOOL"
    },
    {
        "name": "BELGACHIA BBAS HIGH SCHOOL"
    },
    {
        "name": "ST FRANCIS XAVIER SCHOOL"
    },
    {
        "name": "ST AUGUSTINE&#8217;S DAY SCHOOL"
    },
    {
        "name": "BELGACHI HINDI HIGH SCHOOL"
    },
    {
        "name": "ST AGNES SCHOOL"
    },
    {
        "name": "SRIRAMPUR HIGH SCHOOL"
    },
    {
        "name": "BELEGHATA DESHABANDHU HIGH SCHOOL MAIN"
    },
    {
        "name": "SRINAGAR HIGH SCHOOL ( H.S.)"
    },
    {
        "name": "SRIMA BALIKA VIDYALAYA"
    },
    {
        "name": "SRIKHANDA HIGH SCHOOL"
    },
    {
        "name": "BEKO ANCHAL HIGH SCHOOL"
    },
    {
        "name": "SRIHARI GLOBAL SCHOOL"
    },
    {
        "name": "BEJOYGARH VIDYAPITH"
    },
    {
        "name": "SRI SRI ACADEMY, ALIPORE"
    },
    {
        "name": "SRI SRI ACADEMY"
    },
    {
        "name": "SRI RAMKRISNA SHIKSHALAYA"
    },
    {
        "name": "BEHALA SHIKSHAYATAN"
    },
    {
        "name": "SRI RAMKRISHNA BALIKA BIDYALAYA"
    },
    {
        "name": "SRI RAM NARAYAN SINGH MEMORIAL HIGH SCHOOL, SIMLA STREET&#038;KASBA"
    },
    {
        "name": "BEHALA HIGH SCHOOL"
    },
    {
        "name": "SRI R.K.T.M HIGH SCHOOL"
    },
    {
        "name": "BEHALA GIRLS HIGH SCHOOL"
    },
    {
        "name": "SRI NARASINGHA VIDYAPITH"
    },
    {
        "name": "SRI CHAITANYA TECHNO SCHOOL"
    },
    {
        "name": "SRI AUROBINDO INSTITUTE OF EDUCATION, SALT LAKE"
    },
    {
        "name": "BEHALA ARYYA VIDYAMANDIR HIGH SCHOOL"
    },
    {
        "name": "SRI AUROBINDO BAL MANDIR, NEW ALIPUR"
    },
    {
        "name": "BEGUNKODAR HIGH SCHOOL"
    },
    {
        "name": "SREE SARADA ASHRAM BALIKA VIDYALAYA"
    },
    {
        "name": "SPRING GARDENS SECONDARY SCHOOL"
    },
    {
        "name": "BEGUM ROKEYA SMRITI BALIKA VIDYALAYA"
    },
    {
        "name": "SPRING DALES ACADEMY"
    },
    {
        "name": "SPARK SCHOOL"
    },
    {
        "name": "BEGUA HIGH SCHOOL"
    },
    {
        "name": "SOUTH SUBURBAN SCHOOL MAIN, BHOWANIPUR"
    },
    {
        "name": "BEACHWOOD SCHOOL"
    },
    {
        "name": "SOUTH POINT HIGH SCHOOL"
    },
    {
        "name": "SOUTH END HIGH SCHOOL"
    },
    {
        "name": "BDM INTERNATIONAL"
    },
    {
        "name": "SOUTH EASTERN RAILWAY H S SCHOOL(EM), KHARAGPUR"
    },
    {
        "name": "SOUTH CITY INTERNATIONAL SCHOOL"
    },
    {
        "name": "BATIKARA HIGH SCHOOL"
    },
    {
        "name": "BATHUARY ADARSHA VIDYAPITH"
    },
    {
        "name": "BATANAGAR SRI RAMKRISHNA ASHRAM VIVEKANANDA VIDYAMANDIR"
    },
    {
        "name": "SOUTH BISHNUPUR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BASUDEVPUR VIDYASAGAR BIDYAPITH"
    },
    {
        "name": "SOUTH ACADEMY HIGH SCHOOL"
    },
    {
        "name": "BASANTAPUR ADIBASI HIGH SCHOOL"
    },
    {
        "name": "SORRONG MADHUSUDAN VIDYAPITH"
    },
    {
        "name": "SONATHALI KALAPATHAR HIGH SCHOOL"
    },
    {
        "name": "SONATHALI GIRLS HIGH SCHOOL"
    },
    {
        "name": "SONARPUR VIDYAPITH"
    },
    {
        "name": "SONAPURHAT MAHATMA GANDHI HIGH SCHOOL"
    },
    {
        "name": "SONAMUKHI B J HIGH SCHOOL"
    },
    {
        "name": "SONAKHALI HIGH SCHOOL"
    },
    {
        "name": "SONAKHALI GIRLS` HIGH SCHOOL"
    },
    {
        "name": "SONAIJURI ANCHALIK HIGH SCHOOL"
    },
    {
        "name": "SONADA HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "SODEPUR HIGH SCHOOL"
    },
    {
        "name": "SODEPUR CHANDRACHUR VIDYAPITH FOR GIRLS"
    },
    {
        "name": "SODEPUR CHANDRACHUR VIDYAPITH FOR BOYS"
    },
    {
        "name": "SODEPUR CHANDRACHUR VIDYAPITH"
    },
    {
        "name": "BARUIPUR JNANADA VIDYAPITH"
    },
    {
        "name": "SOASHI HIGH SCHOOL"
    },
    {
        "name": "SITALPUR MOBARAKPUR HIGH SCHOOL"
    },
    {
        "name": "SITALPUR HIGH SCHOOL"
    },
    {
        "name": "BARUIPUR HIGH SCHOOL"
    },
    {
        "name": "SITAI HIGH SCHOOL"
    },
    {
        "name": "SISHU SIKSHA KENDRA HIGH SCHOOL"
    },
    {
        "name": "BARUIPUR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SIRKABAD HIGH SCHOOL"
    },
    {
        "name": "SIR NRIPENDRA NATH INSTITUTION"
    },
    {
        "name": "BARUIPARA HIGH SCHOOL"
    },
    {
        "name": "SIR NRIPENDRA NATH GIRLS HIGH SCHOOL"
    },
    {
        "name": "SINTHI R. B. T. VIDYAPITH"
    },
    {
        "name": "BARSHAL HIGH SCHOOL"
    },
    {
        "name": "SINDRI HIGH SCHOOL"
    },
    {
        "name": "SINDRI CHAS ROAD HIGH SCHOOL"
    },
    {
        "name": "BARRAH ANCHAL HIGH SCHOOL"
    },
    {
        "name": "SIMULTALA HIGH MADRASAH"
    },
    {
        "name": "BARRACKPORE GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SILVER POINT SCHOOL"
    },
    {
        "name": "SILIGURI VIVEKANANDA VIDYALAYA"
    },
    {
        "name": "BARRACKPORE CANTONMENT VIDYAPITH H.S SCHOOL"
    },
    {
        "name": "SILIGURI NETAJI HIGH SCHOOL"
    },
    {
        "name": "SILIGURI MODEL HIGH SCHOOL (SR. SEC.)"
    },
    {
        "name": "BARLOW GIRL&#8217;S HIGH SCHOOL"
    },
    {
        "name": "SILIGURI BOYS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BARKOLA VIVEKANANDA HIGH SCHOOL"
    },
    {
        "name": "SIKARPUR HIGH SCHOOL ( HS)"
    },
    {
        "name": "SIDHI HIGH SCHOOL"
    },
    {
        "name": "BARJORA HIGH SCHOOL"
    },
    {
        "name": "SHYAMSUNDARPUR RAMKRISHNA VIDYAPITH"
    },
    {
        "name": "SHYAMSUNDAR CHILDREN HIGH SCHOOL"
    },
    {
        "name": "BARISHA PURBAPARA HIGH SCHOOL"
    },
    {
        "name": "SHYAMPUR HIGH SCHOOL"
    },
    {
        "name": "SHYAMBAZAR GOPAL CHANDRA SEN HIGH SCHOOL"
    },
    {
        "name": "SHYAMA PRASAD SMRITI RAMGANJ BALIKA VIDYALAYA"
    },
    {
        "name": "SHYAM SUKHI BALIKA SHIKSHA NIKETAN"
    },
    {
        "name": "BARISHA HIGH SCHOOL"
    },
    {
        "name": "SHRI SHIKSHAYATAN SCHOOL"
    },
    {
        "name": "BARISHA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SHRI KRISHNA PRANAMI VIDYA NIKETAN"
    },
    {
        "name": "SHREE RAMKRISHNA PARAMHANS VIDHYAPITH HIGH SCHOOL, GARIA"
    },
    {
        "name": "SHREE MAHESHWARI VIDYALAYA"
    },
    {
        "name": "BARISHA CHARANKABI SIKSHA NIKETAN"
    },
    {
        "name": "SHREE JAIN VIDYALAYA"
    },
    {
        "name": "SHREE DEE DO MAHESWARI SCHOOL"
    },
    {
        "name": "BARI HIGH SCHOOL"
    },
    {
        "name": "SHMD SCHOOL"
    },
    {
        "name": "SHITGRAM VIDYABHABAN"
    },
    {
        "name": "BARGARIA HIGH SCHOOL"
    },
    {
        "name": "SHISHU NALANDA HIGH SCHOOL"
    },
    {
        "name": "SHIBRAMPUR HIGH SCHOOL"
    },
    {
        "name": "SHEAKHALA BENIMADHAB HIGH SCHOOL"
    },
    {
        "name": "SHASANGORA HIGH SCHOOL"
    },
    {
        "name": "SHANKPUR BARKHANIA HIGH SCHOOL"
    },
    {
        "name": "BARDHAMAN  BIDYARTHI BHABAN GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BARDEULI ADARSHA SIKSHA NIKETAN"
    },
    {
        "name": "SHANKAR HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "SHAKUNTALA HIGH SCHOOL"
    },
    {
        "name": "BARASINI N.L HIGH SCHOOL"
    },
    {
        "name": "SEVENTH DAY ADVENTIST DAY SCHOOL, PARK STREET"
    },
    {
        "name": "SEVAGRAM HIGH SCHOOL"
    },
    {
        "name": "BARASAT INDIRA GANDHI MEMORIAL HIGH SCHOOL"
    },
    {
        "name": "SERAMPORE HIGH SCHOOL"
    },
    {
        "name": "SENERA HIGH SCHOOL"
    },
    {
        "name": "BARANAGORE RAMAKRISHNA MISSION ASHRAMA HIGH SCHOOL"
    },
    {
        "name": "SEHARABAZAR RADHARANI BALIKA VIDYALAYA"
    },
    {
        "name": "SCOTTISH CHURCH COLLEGIATE SCHOOL"
    },
    {
        "name": "BARANAGAR RAJKUMARI MEMORIAL GIRLS HIGH SCHOOL"
    },
    {
        "name": "SATSOLE K.M. HIGH SCHOOL"
    },
    {
        "name": "BARANAGAR MOHAN GIRLS HIGH SCHOOL H S"
    },
    {
        "name": "BARAJAGULI GOPAL ACADEMY"
    },
    {
        "name": "SATISH CHANDRA MEMORIAL SCHOOL"
    },
    {
        "name": "BARAGAGAN GOHALIA HIGH SCHOOL"
    },
    {
        "name": "SATASHI HIGH SCHOOL"
    },
    {
        "name": "BARACHARA P B HIGH SCHOOL"
    },
    {
        "name": "SASINDA SAGAR CHANDRA HIGH SCHOOL"
    },
    {
        "name": "SASATI NAHALA KANTALDAHA ABINASH HIGH SCHOOL"
    },
    {
        "name": "SASATI NAHALA ABINASH HIGH SCHOOL"
    },
    {
        "name": "BARABAZAR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SASA NETAJI ADARSHA HIGH SCHOOL"
    },
    {
        "name": "SARSUNA HIGH SCHOOL"
    },
    {
        "name": "BARABAZAR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SARISHA RAMAKRISHNA MISSION SIKSHAMANDIR"
    },
    {
        "name": "SARISHA KUNARPUR ANCHAL HIGH SCHOOL"
    },
    {
        "name": "SARBERIA HIGH SCHOOL"
    },
    {
        "name": "BARA URMA HIGH SCHOOL"
    },
    {
        "name": "SARASWATI HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "SARANGABAD HIGH SCHOOL"
    },
    {
        "name": "BARA BARI SRI KRISHNA HIGH SCHOOL"
    },
    {
        "name": "SARAI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SAORA UNION HIGH SCHOOL"
    },
    {
        "name": "BANSHABATI HIGH SCHOOL"
    },
    {
        "name": "SANTURI HIGH SCHOOL"
    },
    {
        "name": "SANTRAGACHI KEDARNATH INSTITUTION FOR GIRLS"
    },
    {
        "name": "BANSDRONI BENOY BALIKA VIDYALAYA"
    },
    {
        "name": "SANTRAGACHI KEDARNATH INSTITUTION"
    },
    {
        "name": "SANTRAGACHI BHANUMATI GIRLS HIGH SCHOOL"
    },
    {
        "name": "BANSBERIA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "SANTOSHPUR GOVT. COLONY NETAJI SUBHAS VIDYALAYA"
    },
    {
        "name": "SANTINAGAR SAPUIPARA HIGH SCHOOL"
    },
    {
        "name": "BANSA DIBAKAR MAHATO HIGH SCHOOL"
    },
    {
        "name": "SANTAMAYEE GIRLS HIGH SCHOOL"
    },
    {
        "name": "SANTALDIH T.P.HIGH SCHOOL"
    },
    {
        "name": "SANSKRIT COLLEGIATE SCHOOL, COLLEGE STREET"
    },
    {
        "name": "SANKRA HIGH SCHOOL"
    },
    {
        "name": "BANKIM GHOSH MEMORIAL GIRLS HIGH SCHOOL"
    },
    {
        "name": "SANKHARI BANSBERIA HIGH SCHOOL"
    },
    {
        "name": "SANKARPUR BASED ALI INSTITUTION"
    },
    {
        "name": "BANKI SENDRA HIGH SCHOOL"
    },
    {
        "name": "SANKAR PARULIA HIGH SCHOOL"
    },
    {
        "name": "SANKA HIGH SCHOOL"
    },
    {
        "name": "BANIPITH GIRLS&#8217;HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "SANGRA S.C.M HIGH SCHOOL"
    },
    {
        "name": "BANGITOLA HIGH SCHOOL"
    },
    {
        "name": "SAMBALPUR ANCHAL HIGH SCHOOL"
    },
    {
        "name": "SAMASPUR HIGH SCHOOL"
    },
    {
        "name": "BANGALPUR U C HIGH SCHOOL"
    },
    {
        "name": "SALTORE COLLIERY HINDI HIGH HS SCHOOL"
    },
    {
        "name": "SALT LAKE SHIKSHA NIKETAN"
    },
    {
        "name": "BANGALPUR JYOTIRMOYEE GIRLS HIGH SCHOOL"
    },
    {
        "name": "SALT LAKE SCHOOL"
    },
    {
        "name": "SALT LAKE POINT SCHOOL"
    },
    {
        "name": "BANGALBARI HIGH SCHOOL (H. S.)"
    },
    {
        "name": "SALGECHIA HIGH SCHOOL"
    },
    {
        "name": "SALDIHA HIGH SCHOOL"
    },
    {
        "name": "SALBONI N. M GIRLS HIGH SCHOOL"
    },
    {
        "name": "SAKUNDIHA HIGH SCHOOL"
    },
    {
        "name": "SAKTIGARH BIDYAPITH"
    },
    {
        "name": "SAKHAWAT MEMORIAL GOVT. GIRLS HIGH SCHOOL"
    },
    {
        "name": "SAJINA PUBLIC HIGH SCHOOL"
    },
    {
        "name": "BANGABARI HIGH SCHOOL"
    },
    {
        "name": "SAINIK SCHOOL PURULIA"
    },
    {
        "name": "SAILENDRA SIRCAR VIDYALAYA, SHYAM PUKUR STREET"
    },
    {
        "name": "SAIFEE HALL GOLDEN JUBILEE PUBLIC SCHOOL, PARK LANE"
    },
    {
        "name": "SAIFEE GOLDEN JUBILEE ENGLISH PUBLIC SCHOOL"
    },
    {
        "name": "SAIDUNNESSA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BANDWAN R.N.C.VIDYAPITH"
    },
    {
        "name": "SAHUDANGI HAT P.K. ROY HIGH SCHOOL"
    },
    {
        "name": "SAHIDGARH HIGH SCHOOL"
    },
    {
        "name": "BANDWAN BANJORA HIGH SCHOOL"
    },
    {
        "name": "SAHID RAMESWAR VIDYAMANDIR"
    },
    {
        "name": "BANDIPUR GHANARAMPUR HIGH SCHOOL"
    },
    {
        "name": "BANDEL ST. JOHN&#8217;S HIGH SCHOOL"
    },
    {
        "name": "BANBOLE HIGH SCHOOL"
    },
    {
        "name": "SAHEBGANJ HIGH SCHOOL"
    },
    {
        "name": "BANAMALIPUR PRIYANATH INSTITUTION"
    },
    {
        "name": "SAHASPUR GHOSAL HIGH SCHOOL"
    },
    {
        "name": "SAHAPUR HIGH SCHOOL"
    },
    {
        "name": "BAMUNDIHA HIGH SCHOOL"
    },
    {
        "name": "SAHAPUR HARENDRANATH VIDYAPITH"
    },
    {
        "name": "BAMU SADHU ASHRAM HIGH SCHOOL"
    },
    {
        "name": "SAGMA HIGH SCHOOL"
    },
    {
        "name": "BAMNIGAON JDNAT GOVT SPONS HIGH SCHOOL FOR BOYS"
    },
    {
        "name": "SAGARDIGHI S.N. HIGH SCHOOL"
    },
    {
        "name": "SADHANPUR ULUDANGA TULSIRAM HIGH SCHOOL"
    },
    {
        "name": "BAMNIA VIVEKANANDA VIDYAPITH"
    },
    {
        "name": "SACRED HEART HIGH SCHOOL"
    },
    {
        "name": "S.T.P.S GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BAMNI HIGH SCHOOL"
    },
    {
        "name": "RUBY PARK PUBLIC SCHOOL (TARATALA)"
    },
    {
        "name": "BALURGHAT HIGH SCHOOL"
    },
    {
        "name": "ROYAL ACADEMY"
    },
    {
        "name": "RIVERDALE HIGH SCHOOL"
    },
    {
        "name": "RISHEEHAT HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "RIMBICK HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "RAVINDRA BHARATHI GLOBAL SCHOOL NEWTOWN"
    },
    {
        "name": "RATANPUR J.N. INSTITUTION"
    },
    {
        "name": "BALURGHAT HIGH SCHOOL"
    },
    {
        "name": "RASIKNAGAR HIGH SCHOOL"
    },
    {
        "name": "RASHMONI BALIKA VIDYALAYA"
    },
    {
        "name": "RANIPUR COLLIERY HIGH SCHOOL HS"
    },
    {
        "name": "RANIGANJ LIONS J.D.M. CHANANI DAV PUBLIC SCHOOL"
    },
    {
        "name": "BALURGHAT HIGH SCHOOL"
    },
    {
        "name": "RANICHAK DESHAPRAN HIGH SCHOOL"
    },
    {
        "name": "RANIBANDH HIGH SCHOOL"
    },
    {
        "name": "RANIBANDH GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "RANGADIH S.B.HIGH SCHOOL"
    },
    {
        "name": "RANGADANGA HIGH SCHOOL"
    },
    {
        "name": "RANAGARH HIGH SCHOOL"
    },
    {
        "name": "RAMPURHAT JITENDRALAL VIDYABHABAN"
    },
    {
        "name": "BALLYGUNGE GOVERNMENT HIGH SCHOOL"
    },
    {
        "name": "RAMNAGAR ATUL VIDYALAYA"
    },
    {
        "name": "BALITORA HIGH  SCHOOL"
    },
    {
        "name": "RAMLALPUR GHANABAN HIGH SCHOOL"
    },
    {
        "name": "RAMKRISHNAPUR P.D.G.M HIGH SCHOOL H.S"
    },
    {
        "name": "BALIKA SIKSHA SCHOOL"
    },
    {
        "name": "RAMJIBANPUR BALIKA VIDYALAYA"
    },
    {
        "name": "BALIDEWANGANJ SARADAMONI BALIKA VIDYALAYA"
    },
    {
        "name": "RAMESWARPUR SREE KRISHNA HIGH SCHOOL"
    },
    {
        "name": "RAMESH MITRA GIRLS SCHOOL"
    },
    {
        "name": "RAMCHANRAPUR SSBK VIDYAPITH"
    },
    {
        "name": "RAMAKRISHNA SARADA MISSION SISTER NIVEDITA GIRLS&#8217; SCHOOL"
    },
    {
        "name": "RAMAKRISHNA MISSION VIVEKANANDA VIDYAMANDIR"
    },
    {
        "name": "BALIBELA HIGH SCHOOL"
    },
    {
        "name": "RAMAKRISHNA MISSION VIDYABHAVAN"
    },
    {
        "name": "RAMAKRISHNA H.S SCHOOL FOR GIRLS, KURSEONG"
    },
    {
        "name": "BALIA NAFAR CHANDRA BALIKA VIDYALAYA H.S"
    },
    {
        "name": "RAMAKANTANAGAR VIDYAMANDIR"
    },
    {
        "name": "BALI NHIGH SCHOOL"
    },
    {
        "name": "RAM MOHAN MISSION HIGH SCHOOL (LAKE GARDENS)"
    },
    {
        "name": "RAM CHANDRA HIGH SCHOOL (DHAKURIA)"
    },
    {
        "name": "BALARAMPUR PHOOL CHAND HIGH SCHOOL HS"
    },
    {
        "name": "RAKHERA HIGH SCHOOL"
    },
    {
        "name": "BALARAMPUR LALIMOTI GIRLS HS SCHOOL"
    },
    {
        "name": "BALAGARH HIGH SCHOOL"
    },
    {
        "name": "RAJPUR PADMAMANI GIRLS&#8217;HIGH SCHOOL ( H.S)"
    },
    {
        "name": "RAJKUMARI SANTANAMOYEE GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BALA HIGH SCHOOL"
    },
    {
        "name": "RAJHATI BANDAR HIGH SCHOOL"
    },
    {
        "name": "BAKSHIRHAT HIGH SCHOOL"
    },
    {
        "name": "RAJGRAM HIGH SCHOOL"
    },
    {
        "name": "RAJASTHAN VIDYAPITH"
    },
    {
        "name": "BAJUA HIGH SCHOOL"
    },
    {
        "name": "RAJASTHAN VIDYA MANDIR"
    },
    {
        "name": "RAJARHAT VIDYABHAVAN HIGH SCHOOL"
    },
    {
        "name": "BAKHRAHAT HIGH SCHOOL"
    },
    {
        "name": "RAJAGRAM HIGH SCHOOL"
    },
    {
        "name": "BAKHRAHAT HIGH SCHOOL"
    },
    {
        "name": "BAJUA HIGH SCHOOL"
    },
    {
        "name": "BAJUA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BAJITPUR SARADAMANI BALIKA VIDYALA"
    },
    {
        "name": "RAJABAZAR BOYS &#038; GIRLS&#8217; SCHOOL (RAJABAZAR)"
    },
    {
        "name": "RAIPUR SRI SRI RAMKRISHNA AMRITA VIDYALYA"
    },
    {
        "name": "BAISHNABNAGAR HIGH SCHOOL"
    },
    {
        "name": "RAIGANJ GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "RAIDIHI DALDALI HIGH SCHOOL"
    },
    {
        "name": "BAIPATNA VIDYASAGAR HIGH SCHOOL"
    },
    {
        "name": "RAHIMPUR NABAGRAM HIGH SCHOOL"
    },
    {
        "name": "RAHARA RAMAKRISHNA MISSION (KHARDAH)"
    },
    {
        "name": "BAIDYANATHPUR HIGH SCHOOL"
    },
    {
        "name": "RAGHUNATHPUR NAFAR ACADEMY"
    },
    {
        "name": "RAGHUNATHPUR HIGH SCHOOL"
    },
    {
        "name": "BAIDYABATI SURENDRA NATH ROY BALIKA VIDYALAYA"
    },
    {
        "name": "RAGHUNATHPUR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "RAGHUNATHPUR G D LANG INSTITUTION"
    },
    {
        "name": "RAGHUNATHBARI RAM TARAK HIGH SCHOOL"
    },
    {
        "name": "BAHUTALI HIGH SCHOOL"
    },
    {
        "name": "RAGHOBPUR HIGH&#8217;SCHOOL"
    },
    {
        "name": "BAHIN HIGH SCHOOL"
    },
    {
        "name": "RAGHABPUR ST. PAUL&#8217;S HIGH SCHOOL"
    },
    {
        "name": "BAHARAN JOY DURGA HIGH SCHOOL"
    },
    {
        "name": "RADHAPUR HIGH SCHOOL"
    },
    {
        "name": "RABINDRANATH HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "RABINDRA NAGAR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BAHARAIL B.C. HIGH SCHOOL"
    },
    {
        "name": "RABINDRA INTERNATIONAL SCHOOL (SUBHASGRAM)[3]"
    },
    {
        "name": "R.D. MEMORIAL SCHOOL (HIGH), DHAKURIA"
    },
    {
        "name": "BAHANGAON HIGH SCHOOL"
    },
    {
        "name": "R.B.B. HIGH   SCHOOL"
    },
    {
        "name": "BAGRAKOTE H.S. SCHOOL"
    },
    {
        "name": "PUTIMARI HIGH SCHOOL(X+II)"
    },
    {
        "name": "BAGPOTA HIGH SCHOOL"
    },
    {
        "name": "PUSHPARANI ROY MEMORIAL HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "PURWANCHAL VIDYAMANDIR"
    },
    {
        "name": "BAGNAN GIRLS HIGH SCHOOL"
    },
    {
        "name": "PURUSHOTTAM BHAGCHANDKA ACADEMIC SCHOOL"
    },
    {
        "name": "PURUNDA RAMKRISHNA SIKSHA SADAN HS"
    },
    {
        "name": "BAGNABARH HIGH SCHOOL"
    },
    {
        "name": "PURULIA TOWN HIGH SCHOOL"
    },
    {
        "name": "PURULIA NETAJI VIDYAPITH"
    },
    {
        "name": "BAGMUNDI HIGH SCHOOL , SARAKDI"
    },
    {
        "name": "PURULIA NAZRUL BALIKA VIDYAPITH"
    },
    {
        "name": "BAGMARI MANICKTALA GOVT. SPONSD. H.S SCHOOL"
    },
    {
        "name": "PURULIA MUNICIPAL MANAGED HIGH SCHOOL"
    },
    {
        "name": "BAGHMUNDI HIGH SCHOOL"
    },
    {
        "name": "PURBA AMARPUR NAGENDRA UPENDRA VIDYANIKETAN (HIGH SCHOOL )"
    },
    {
        "name": "BAGHMUNDI GIRLS HIGH SCHOOL"
    },
    {
        "name": "PURAH HIGH SCHOOL"
    },
    {
        "name": "PUNISOLE BOARD HIGH SCHOOL"
    },
    {
        "name": "BAGHADARI DESHAPRAN HIGH SCHOOL."
    },
    {
        "name": "PUNDIBARI R.G.L HIGH SCHOOL"
    },
    {
        "name": "PUNDAG HIGH SCHOOL"
    },
    {
        "name": "BAGH BAZAR MULTI PURPOSE GIRLS HIGH SCHOOL"
    },
    {
        "name": "PUNCHA GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "BAGDOGRA CHITTARANJAN HIGH SCHOOL"
    },
    {
        "name": "PUKURGORIA BIBHUTINATH VIDYAPITH"
    },
    {
        "name": "PUKHURIA HIGH SCHOOL"
    },
    {
        "name": "BAGATBARI HIGH SCHOOL"
    },
    {
        "name": "PUARA HIGH SCHOOL"
    },
    {
        "name": "BADRA   HIGH  SCHOOL"
    },
    {
        "name": "PRATT MEMORIAL SCHOOL"
    },
    {
        "name": "PRATAPNAGAR RAMANIMOHAN VIDYANIKETAN"
    },
    {
        "name": "BADKULLA UNITED ACADEMY"
    },
    {
        "name": "PRARAMBH PRE-PRIMARY SCHOOL"
    },
    {
        "name": "BADANGANJ HIGH SCHOOL"
    },
    {
        "name": "PRAMILA MEMORIAL ADVANCED SCHOOL"
    },
    {
        "name": "PRABARTAK VIDYARTHI BHAWAN"
    },
    {
        "name": "BADALPUR HIGH SCHOOL"
    },
    {
        "name": "PRABARTAK NARI MANDIR"
    },
    {
        "name": "POUTI HIGH SCHOOL"
    },
    {
        "name": "BABUGRAM SAMMILANI HIGH SCHOOL"
    },
    {
        "name": "POPRA ISWARLAL HIGH SCHOOL"
    },
    {
        "name": "BABNAN HIGH SCHOOL"
    },
    {
        "name": "POLERHAT HIGH SCHOOL"
    },
    {
        "name": "POKHRIABONG HS SCHOOL"
    },
    {
        "name": "B.D.M. INTERNATIONAL"
    },
    {
        "name": "PIRAKATA HIGH SCHOOL"
    },
    {
        "name": "B. T. ROAD GOVERNMENT SPONSORED H. S. SCHOOL"
    },
    {
        "name": "PIPLA HIGH SCHOOL"
    },
    {
        "name": "PINGLASH HIGH SCHOOL"
    },
    {
        "name": "AZIMGANJ RAI BUDH SINGH BAHADUR HIGH SCHOOL"
    },
    {
        "name": "AUXILIUM CONVENT SCHOOL"
    },
    {
        "name": "AUROBINDO VIDYAPITH, CHETLA, KOLKATA"
    },
    {
        "name": "ASSEMBLY OF GOD CHURCH SCHOOL"
    },
    {
        "name": "ASSEMBLY OF CHRIST SCHOOL"
    },
    {
        "name": "PINGBONI HIGH SCHOOL"
    },
    {
        "name": "PINDRUI K.I VIDYANIKETAN"
    },
    {
        "name": "ASIAN INTERNATIONAL SCHOOL"
    },
    {
        "name": "PATULIA HIGH SCHOOL"
    },
    {
        "name": "PATOIR C.C. HIGH SCHOOL"
    },
    {
        "name": "PATHA BHAVAN"
    },
    {
        "name": "PASCHIMPARA HIGH SCHOOL"
    },
    {
        "name": "PARK ENGLISH SCHOOL"
    },
    {
        "name": "PARITOSH MEMORIAL HIGH SCHOOL"
    },
    {
        "name": "PARGELA HIGH SCHOOL"
    },
    {
        "name": "PARBELIA COLLIERY HINDI HIGH   SCHOOL"
    },
    {
        "name": "ASHURALI JATISH CHANDRA HIGH SCHOOL"
    },
    {
        "name": "PARBATIPUR CHUNMARDI HIGH SCHOOL"
    },
    {
        "name": "PARAMANANDAPUR JAGANNATH INSTITUTION"
    },
    {
        "name": "PARA GIRLS HIGH   SCHOOL"
    },
    {
        "name": "PANSKURA BRADLEY BIRT HIGH SCHOOL"
    },
    {
        "name": "PANSKURA BB HIGH SCHOOL"
    },
    {
        "name": "PANSILA DESHBANDHUNAGAR VIDYAMANDIR"
    },
    {
        "name": "PANNALAL INSTITUTION"
    },
    {
        "name": "PANJANIA J.D.R. HIGH SCHOOL"
    },
    {
        "name": "PANDIT RAGHUNATH MURMU VIDYAPITH"
    },
    {
        "name": "PANDIT RAGHUNATH MURMU ADARSHA ABASIK VIDYALAYA"
    },
    {
        "name": "PANDIT ISWAR CHANDRA VIDYASAGAR MISSION"
    },
    {
        "name": "PANCHUDANGA HIGH SCHOOL"
    },
    {
        "name": "PANCHPUKUR HIGH SCHOOL"
    },
    {
        "name": "PANCHKHURI DESHBANDHU HIGH SCHOOL"
    },
    {
        "name": "PANCHAMPALLI VIDYALAYA"
    },
    {
        "name": "PANCHAKOTE RAJ HIGH SCHOOL"
    },
    {
        "name": "PALASI HEMANGINI SAROJINI VIDYAMANDIR"
    },
    {
        "name": "ASHOK HALL GIRLS&#8217; HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "PALASHCHABRI NIGAMANANDA HIGH SCHOOL"
    },
    {
        "name": "PAKHIHAGA GOUR SUNDARI HIGH SCHOOL"
    },
    {
        "name": "ASHARIA ISWARIPUR HIGH SCHOOL"
    },
    {
        "name": "PAHARIPUR GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "PAHALANPUR HIGH SCHOOL"
    },
    {
        "name": "ASANSOL CHELIDANGA HIGH SCHOOL"
    },
    {
        "name": "PADMAPUKUR MADHYA VIDYALAYA"
    },
    {
        "name": "P.A.J’S ENGLISH DAY SCHOOL"
    },
    {
        "name": "ASADTALA BINODE VIDYAPITH"
    },
    {
        "name": "OUR LADY QUEEN OF THE MISSIONS SCHOOL"
    },
    {
        "name": "ORIENTAL SEMINARY"
    },
    {
        "name": "ORIENT DAY SCHOOL (ICSE)"
    },
    {
        "name": "OLD MALDA KALACHAND HIGH SCHOOL"
    },
    {
        "name": "OAKTREE INTERNATIONAL SCHOOL (IB)"
    },
    {
        "name": "ARMY PUBLIC SCHOOL, BENGDUBI ARYA VIDYA MANDIR, BEHALA ASADTALA BINODE VIDYAPITH ASANSOL CHELIDANGA HIGH SCHOOL"
    },
    {
        "name": "NUDIPUR B.S.VIDYAMANDIR"
    },
    {
        "name": "NORTH POINT SENIOR SECONDARY BOARDING SCHOOL, ARJUNPUR &#038; RAJARHAT BRANCH"
    },
    {
        "name": "NORTH 24 PARGANAS GOVT MODEL MADRASAH(ENGLISH MEDIUM)"
    },
    {
        "name": "NOHARI HIGH SCHOOL"
    },
    {
        "name": "AIR FORCE SCHOOL BARRACKPORE"
    },
    {
        "name": "NIVEDITA VIDYAPITH, BARRACKPORE"
    },
    {
        "name": "NIVEDITA SCHOOL FOR GIRLS, BAGBAZAR"
    },
    {
        "name": "NISHIGANJ NISHIMOYEE HIGH SCHOOL"
    },
    {
        "name": "AJODHYA PAHAR H.S.SCHOOL"
    },
    {
        "name": "AKARBAID L. B. S. HIGH SCHOOL"
    },
    {
        "name": "ARMY PUBLIC SCHOOL, BARRACKPORE"
    },
    {
        "name": "AKNA HIGH SCHOOL"
    },
    {
        "name": "NIMTALA HIGH SCHOOL"
    },
    {
        "name": "AKNA UNION HIGH SCHOOL"
    },
    {
        "name": "NIKUNJAPUR HIGH SCHOOL"
    },
    {
        "name": "ARMY PUBLIC SCHOOL, BALLYGUNGE"
    },
    {
        "name": "NEWTOWN SCHOOL"
    },
    {
        "name": "NEW NATIONAL HIGH SCHOOL (HINDI MEDIUM) FOR GIRLS"
    },
    {
        "name": "AKSHAR SCHOOL"
    },
    {
        "name": "NEW INTEGRATED GOVT. SCHOOL , MEDINIPUR SADAR"
    },
    {
        "name": "NEW INTEGRATED GOVT. SCHOOL (E.M.), SANKRAIL"
    },
    {
        "name": "NEW INTEGRATED GOVT. SCHOOL (BENGALI MEDIUM)"
    },
    {
        "name": "AKUNI B. G. BIHARILAL INSTITUTION"
    },
    {
        "name": "NEW INTEGRATED GOVT. SCHOOL"
    },
    {
        "name": "NEW HORIZON HIGH SCHOOL HAZRA"
    },
    {
        "name": "ARMY PUBLIC SCHOOL BINNAGURI"
    },
    {
        "name": "AL HIDAYAH INTERNATIONAL ISLAMIC SCHOOL"
    },
    {
        "name": "NEW ALIPORE MULTIPURPOSE SCHOOL"
    },
    {
        "name": "ARJUNJORA HIGH SCHOOL"
    },
    {
        "name": "NETURIA GIRLS HIGH SCHOOL"
    },
    {
        "name": "ALIGUNJ RISHI RAJNARAYAN BALIKA VIDYALAYA"
    },
    {
        "name": "ARIT VIVEKANANDA VIDYAMANDIR"
    },
    {
        "name": "ARIADAHA KALACHAND HIGH SCHOOL"
    },
    {
        "name": "ALIPORE GIRLS&#8217; &#038; BOYS&#8217; HIGH SCHOOL (JOKA)"
    },
    {
        "name": "ALIPORE GIRLS&#8217; &#038; BOYS&#8217; HIGH SCHOOL (MOMINPORE)"
    },
    {
        "name": "ARAPUR JOTE T.A.G GIRLS HIGH SCHOOL"
    },
    {
        "name": "ALIPUR SATISH CHANDRA PAL HIGH SCHOOL"
    },
    {
        "name": "ARALI RAGHUNATHPUR ADIBASI HIGH SCHOOL"
    },
    {
        "name": "NETAJI GIRLS&#8217; HIGH SCHOOL"
    },
    {
        "name": "APEEJAY SCHOOL, PARK STREET"
    },
    {
        "name": "NCP MANMATHANATH GIRLS SCHOOL HS"
    },
    {
        "name": "NAYAGRAM HIGH SCHOOL"
    },
    {
        "name": "NAYABAZAR HIGH SCHOOL"
    },
    {
        "name": "NAYABASAT PARBATIMOYEE SIKSHANIKETAN"
    },
    {
        "name": "NAVA NALANDA HIGH SCHOOL"
    },
    {
        "name": "NATIONAL OPEN SCHOOL, ALIPORE"
    },
    {
        "name": "NATIONAL MODEL HIGH SCHOOL, BIRATI"
    },
    {
        "name": "NATIONAL HIGH SCHOOL"
    },
    {
        "name": "NATIONAL ENGLISH SCHOOL"
    },
    {
        "name": "NATABARI HIGH SCHOOL"
    },
    {
        "name": "NASKARPUR SUKANTA SIKSHANIKETAN HS"
    },
    {
        "name": "NASKARPUR HIGH SCHOOL"
    },
    {
        "name": "NARULA PUBLIC SCHOOL"
    },
    {
        "name": "NARUA SIKSHANIKETAN"
    },
    {
        "name": "NARMADA SCHOOL"
    },
    {
        "name": "NARMADA HIGH SCHOOL"
    },
    {
        "name": "NARKELDANGA HIGH SCHOOL"
    },
    {
        "name": "NARENDRAPUR RAMAKRISHNA MISSION"
    },
    {
        "name": "NARAYANPUR AKSHAY VIDYAMANDIR"
    },
    {
        "name": "NARAGORIA DURGA DASHI HIGH SCHOOL"
    },
    {
        "name": "NAODA NAYAN CHANDRA BIDYAPITH"
    },
    {
        "name": "NANOOR C M HIGH SCHOOL"
    },
    {
        "name": "NANGI HIGH SCHOOL"
    },
    {
        "name": "ALIPURDUAR MCWILLIAM HIGH SCHOOL"
    },
    {
        "name": "NANDANPUR RUPCHAND ACADEMY"
    },
    {
        "name": "NANDANPUR HIGH SCHOOL"
    },
    {
        "name": "AL-KAUTHAR PUBLIC SCHOOL"
    },
    {
        "name": "NANDAJHAR ADIBASI TAPASILI HIGH SCHOOL"
    },
    {
        "name": "AMARASI VIDYA MANDIR (GOVT. AIDED), COSSIPORE"
    },
    {
        "name": "NALTA MAHAJATI HIGH SCHOOL FOR GIRLS"
    },
    {
        "name": "NALGORADHAM BAIKUNTHA VIDYAPITH"
    },
    {
        "name": "NALBONA HIGH&#8217; SCHOOL"
    },
    {
        "name": "NAKUNDA KATYAYANI HIGH SCHOOL"
    },
    {
        "name": "AMARPATI LIONS CITIZENS PUBLIC SCHOOL"
    },
    {
        "name": "NAKTALA HIGH SCHOOL"
    },
    {
        "name": "NAIMOUZA HIGH SCHOOL"
    },
    {
        "name": "AJODHYA BELPUKUR HIGH SCHOOL"
    },
    {
        "name": "NAIHATI MAHENDRA HIGH SCHOOL"
    },
    {
        "name": "AKRA SHAKTIGARH RABINDRA VIDYAPITH"
    },
    {
        "name": "NAIHATI ADARSHA VIDYANIKETAN FOR GIRLS"
    },
    {
        "name": "NAHATA HIGH SCHOOL"
    },
    {
        "name": "ALGARAH HIGHER SECONDARY SCHOOL"
    },
    {
        "name": "NAGRI FARM H S SCHOOL"
    },
    {
        "name": "NAGRAKATA HINDI MADHYAMIK VIDYALAYA"
    },
    {
        "name": "ARAPUR JOTE T.A.G GIRLS HIGH SCHOOL"
    },
    {
        "name": "NAGAR A. M. HIGH SCHOOL"
    },
    {
        "name": "ARALI RAGHUNATHPUR ADIBASI HIGH SCHOOL"
    },
    {
        "name": "NACHIPUR ADIBASI HIGH SCHOOL"
    },
    {
        "name": "NABAGRAM VIDYAPITH"
    },
    {
        "name": "APEEJAY SCHOOL, PARK STREET"
    },
    {
        "name": "NABAGRAM K. D. PAUL VIDYALAYA"
    },
    {
        "name": "MURULIDHAR MEMORIAL HIGH SCHOOL"
    },
    {
        "name": "MURSHIDABAD (GOVT.) MODEL MADRASAH (ENGLISH MEDIUM)"
    },
    {
        "name": "ALBANY HALL PUBLIC SCHOOL"
    },
    {
        "name": "ANKRO HIGH SCHOOL"
    },
    {
        "name": "ANGUA GADADHAR HIGH SCHOOL"
    },
    {
        "name": "ANGLO ARABIC SECONDARY SCHOOL, M. G. ROAD"
    },
    {
        "name": "ANGADPUR HIGH SCHOOL"
    },
    {
        "name": "ANDREW&#8217;S HIGH SCHOOL"
    },
    {
        "name": "ANDHRA ASSOCIATION HIGH SCHOOL"
    },
    {
        "name": "ANDHRA ASSOCIATION HIGH SCHOOL"
    },
    {
        "name": "ANAUN HIGH SCHOOL"
    },
    {
        "name": "ANARA S.E. RLY. COLONY H.S. SCHOOL"
    },
    {
        "name": "ANANDAPUR S.K GIRLS&#8217; H.S SCHOOL"
    },
    {
        "name": "ANANDAPUR HIGH SCHOOL"
    },
    {
        "name": "AMRAKHALI DESHPRAN HIGH SCHOOL"
    },
    {
        "name": "AMRAI HIGH SCHOOL"
    },
    {
        "name": "AMLASULI INDRANARAYAN HIGH SCHOOL"
    },
    {
        "name": "AMLAI BHALUIPARA HIGH SCHOOL"
    },
    {
        "name": "AMDOBA S.C.B HIGH SCHOOL"
    },
    {
        "name": "Heritage School Kolkata"
    },
    {
        "name": "Loreto House Kolkata"
    },
    {
        "name": "Modern High School Kolkata"
    },
    {
        "name": "Don Bosco School Kolkata"
    }
];

export const newSchoolArray = [
    {
      "School Name": "Loreto Convent"
    },
    {
      "School Name": "St. Vincent's High & Technical School"
    },
    {
      "School Name": "St. Patrick's Higher Secondary School"
    },
    {
      "School Name": "Assembly Of God Church School"
    },
    {
      "School Name": "Mary Immaculate School"
    },
    {
      "School Name": "St. Xavier's School"
    },
    {
      "School Name": "Auxilium Convent School"
    },
    {
      "School Name": "Assembly Of God Church School"
    },
    {
      "School Name": "Auxilium Convent School"
    },
    {
      "School Name": "Calcutta Boys' School"
    },
    {
      "School Name": "Calcutta Girls' High School"
    },
    {
      "School Name": "Our Lady Queen Of The Missions School"
    },
    {
      "School Name": "Don Bosco School"
    },
    {
      "School Name": "Elias Meyer F.S. & T.T."
    },
    {
      "School Name": "The Frank Anthony Public School"
    },
    {
      "School Name": "La Martiniere For Boys"
    },
    {
      "School Name": "La Martiniere For Girls"
    },
    {
      "School Name": "Loreto Day School"
    },
    {
      "School Name": "Loreto Convent"
    },
    {
      "School Name": "Loreto House"
    },
    {
      "School Name": "Loreto Day School"
    },
    {
      "School Name": "Pratt Memorial School"
    },
    {
      "School Name": "St. James' School"
    },
    {
      "School Name": "St. Joseph's College"
    },
    {
      "School Name": "St. Mary's Orphanage And Day School"
    },
    {
      "School Name": "St. Mary's School"
    },
    {
      "School Name": "St. Paul's Mission School"
    },
    {
      "School Name": "St. Thomas' Boys' School"
    },
    {
      "School Name": "St. Thomas' Girls' School"
    },
    {
      "School Name": "St. Teresa's Secondary School"
    },
    {
      "School Name": "St. Thomas' Day School"
    },
    {
      "School Name": "St. Xavier's Collegiate School"
    },
    {
      "School Name": "Welland Gouldsmith School"
    },
    {
      "School Name": "St. Paul's Boarding & Day School"
    },
    {
      "School Name": "The Oral School For Deaf Children"
    },
    {
      "School Name": "Julien Day School"
    },
    {
      "School Name": "Armenian College And Philanthropic Academy"
    },
    {
      "School Name": "St. Augustine's Day School"
    },
    {
      "School Name": "Albany Hall Public School"
    },
    {
      "School Name": "Grace Ling Liang English School"
    },
    {
      "School Name": "The Park English School"
    },
    {
      "School Name": "St. Joseph & Mary's School"
    },
    {
      "School Name": "Sri Ram Narayan Singh Memorial High School"
    },
    {
      "School Name": "Saifee Golden Jubilee English Public School"
    },
    {
      "School Name": "W. W. A. Cossipore English School"
    },
    {
      "School Name": "Dolna Day School"
    },
    {
      "School Name": "Orient Day School"
    },
    {
      "School Name": "St. Helen School"
    },
    {
      "School Name": "Salt Lake School"
    },
    {
      "School Name": "Loyola High School"
    },
    {
      "School Name": "Sri Aurobindo Institute of Education"
    },
    {
      "School Name": "The Future Foundation School"
    },
    {
      "School Name": "Harrow Hall"
    },
    {
      "School Name": "Mansur Habibullah Memorial School"
    },
    {
      "School Name": "G.D. Birla Centre For Education"
    },
    {
      "School Name": "Seventh Day Adventist Senior Secondary School"
    },
    {
      "School Name": "Rajasthan Vidya Mandlr"
    },
    {
      "School Name": "Hirendra Leela Patranavis School"
    },
    {
      "School Name": "St. Joseph's Convent"
    },
    {
      "School Name": "St. Joseph's Convent High School"
    },
    {
      "School Name": "St. Xavier's School"
    },
    {
      "School Name": "Carmel Convent High School"
    },
    {
      "School Name": "Assembly of God Church School"
    },
    {
      "School Name": "St. Peter's School"
    },
    {
      "School Name": "St. Michael's School"
    },
    {
      "School Name": "Pranavananda Vidyamandir"
    },
    {
      "School Name": "Bidhan School"
    },
    {
      "School Name": "Mount Hermon School"
    },
    {
      "School Name": "Loreto Convent School"
    },
    {
      "School Name": "St. Joseph's College"
    },
    {
      "School Name": "St. Paul's School"
    },
    {
      "School Name": "Vidya Vikash Academy"
    },
    {
      "School Name": "West Point School"
    },
    {
      "School Name": "Bhadra Sheela Memorial Institutions"
    },
    {
      "School Name": "Julien Day School"
    },
    {
      "School Name": "Don Bosco School"
    },
    {
      "School Name": "St. Agnes' Convent School"
    },
    {
      "School Name": "St. Thomas' Church School"
    },
    {
      "School Name": "St. Aloysius Orphanage And Day School"
    },
    {
      "School Name": "Sun Rise (English Medium) School"
    },
    {
      "School Name": "Little Star High School"
    },
    {
      "School Name": "S.E. Rly. Mixed High School (english Medium)"
    },
    {
      "School Name": "St. Thomas' High School"
    },
    {
      "School Name": "Don Bosco School, Bandel"
    },
    {
      "School Name": "Dreamland School"
    },
    {
      "School Name": "Raymond Mem. Higher Secondary School"
    },
    {
      "School Name": "St. James' High School"
    },
    {
      "School Name": "Holy Child School"
    },
    {
      "School Name": "Dr. Graham's Homes"
    },
    {
      "School Name": "St. Augustine's School"
    },
    {
      "School Name": "St. Joseph's Convent"
    },
    {
      "School Name": "Dow Hill School"
    },
    {
      "School Name": "Victoria Boys' School"
    },
    {
      "School Name": "Goethals Memorial School"
    },
    {
      "School Name": "St. Helen's Secondary School"
    },
    {
      "School Name": "Himali Boarding School"
    },
    {
      "School Name": "Lewis English School"
    },
    {
      "School Name": "Glenhill Public School"
    },
    {
      "School Name": "S.E. Rly. Mixed Higher Secondary School (English Medium)"
    },
    {
      "School Name": "St. Agnes School"
    },
    {
      "School Name": "Sacred Heart High School"
    },
    {
      "School Name": "St. Joseph's High School"
    },
    {
      "School Name": "Orange Lake School"
    },
    {
      "School Name": "The St. Xavier's School"
    },
    {
      "School Name": "Don Bosco School"
    },
    {
      "School Name": "Mahbert High School"
    },
    {
      "School Name": "St. Xavier's Institution"
    },
    {
      "School Name": "St. Augustine's Day School"
    },
    {
      "School Name": "St. Joseph's School"
    },
    {
      "School Name": "Lincolns High School"
    },
    {
      "School Name": "Holy Home"
    },
    {
      "School Name": "Krishnagar Academy"
    },
    {
      "School Name": "Carmel School"
    },
    {
      "School Name": "Sister Nivedita Institute"
    },
    {
      "School Name": "Rockvale Academy"
    },
    {
      "School Name": "Methodist School"
    },
    {
      "School Name": "Anglo - Arabic Secondary School"
    },
    {
      "School Name": "Vivekananda Mission School"
    },
    {
      "School Name": "Assembly Of God Church School"
    },
    {
      "School Name": "The Assembly Of God Church School"
    },
    {
      "School Name": "Ling Liang High School"
    },
    {
      "School Name": "South End Centre (E.M.) School"
    },
    {
      "School Name": "Maheshwari Girls' School"
    },
    {
      "School Name": "St. Francis Xavier School"
    },
    {
      "School Name": "Central Modern School"
    },
    {
      "School Name": "Maria's Day School"
    },
    {
      "School Name": "Assembly of God Church School"
    },
    {
      "School Name": "Holy Rock School"
    },
    {
      "School Name": "Mahadevi Birla Shishu Vihar"
    },
    {
      "School Name": "St. Paul's K.G. & Day School"
    },
    {
      "School Name": "Julien Day School"
    },
    {
      "School Name": "St. Helen's School"
    },
    {
      "School Name": "St. Stephen's School"
    },
    {
      "School Name": "Xavier's English School"
    },
    {
      "School Name": "Tribeni Tissues Vidyapith"
    },
    {
      "School Name": "Jewish Girls' School"
    },
    {
      "School Name": "St. Mary's High School"
    },
    {
      "School Name": "M,P. Birla Foundation Higher Secondary School"
    },
    {
      "School Name": "Saptashri Gyanpeeth"
    },
    {
      "School Name": "National Gems Higher Secondary School"
    },
    {
      "School Name": "Young Horizons School"
    },
    {
      "School Name": "St. Anthony's School"
    },
    {
      "School Name": "Little Flower School"
    },
    {
      "School Name": "The Central Modern School"
    },
    {
      "School Name": "Ratnakar North Point School"
    },
    {
      "School Name": "The Modern Academy"
    },
    {
      "School Name": "Howrah St. John's High School"
    },
    {
      "School Name": "Rose Bud School"
    },
    {
      "School Name": "Stepping Stone Model School"
    },
    {
      "School Name": "Daisies School"
    },
    {
      "School Name": "Budge Budge St. Paul's Day School"
    },
    {
      "School Name": "B.D. Memorial English High School"
    },
    {
      "School Name": "The Assembly Of God Church School"
    },
    {
      "School Name": "Augustine's Public School"
    },
    {
      "School Name": "The Bhawanipur Gujarati Education Society School"
    },
    {
      "School Name": "Green Lawns School"
    },
    {
      "School Name": "Vidyasagar Shishu Niketan"
    },
    {
      "School Name": "Ruhiyyih School"
    },
    {
      "School Name": "St. Augustine's Day School"
    },
    {
      "School Name": "Belle Vue Boarding School"
    },
    {
      "School Name": "St. Joan's School"
    },
    {
      "School Name": "Indira Memorial English Medium High School"
    },
    {
      "School Name": "Splendour High School"
    },
    {
      "School Name": "Agrasain Balika Siksha Sadan"
    },
    {
      "School Name": "Calcutta Public School"
    },
    {
      "School Name": "Salt Lake Point School"
    },
    {
      "School Name": "Emmanuel English School"
    },
    {
      "School Name": "Authpur National Model School"
    },
    {
      "School Name": "Pine Hall Academy"
    },
    {
      "School Name": "West Point Academy"
    },
    {
      "School Name": "Trinity High School"
    },
    {
      "School Name": "Pearls Of God"
    },
    {
      "School Name": "Indira Gandhi Memorial Senior Secondary School"
    },
    {
      "School Name": "St. Augustine's School"
    },
    {
      "School Name": "Narmada School"
    },
    {
      "School Name": "Douglas Memorial Higher Secondary School"
    },
    {
      "School Name": "St. Denis School"
    },
    {
      "School Name": "St. Johns' School"
    },
    {
      "School Name": "Himalayan English School"
    },
    {
      "School Name": "Acme Academy"
    },
    {
      "School Name": "Nirmala Convent School"
    },
    {
      "School Name": "Himalayan International Residential School"
    },
    {
      "School Name": "Monalisa English School"
    },
    {
      "School Name": "Sacred Heart School"
    },
    {
      "School Name": "Meghmala Roy Education Centre"
    },
    {
      "School Name": "St. Dominic Savio School"
    },
    {
      "School Name": "St. Sebastian's School"
    },
    {
      "School Name": "St. Joseph's School"
    },
    {
      "School Name": "Good Shepherd School"
    },
    {
      "School Name": "Gyanoday Niketan"
    },
    {
      "School Name": "St. Jude's High School"
    },
    {
      "School Name": "Bethany School"
    },
    {
      "School Name": "M.c. Kejriwal Vidyapeeth"
    },
    {
      "School Name": "Holy Child English Academy"
    },
    {
      "School Name": "Jnaneshwar Memorial Academy"
    },
    {
      "School Name": "East West Model School"
    },
    {
      "School Name": "Gospel Home School"
    },
    {
      "School Name": "Durgapur Harshabardhan Road Aurobindo Vidyamandir"
    },
    {
      "School Name": "St. Xavier School"
    },
    {
      "School Name": "Holy Cross School"
    },
    {
      "School Name": "Modern English Academy"
    },
    {
      "School Name": "Ram Mohan Mission High School"
    },
    {
      "School Name": "St. Paul's Educational Institution"
    },
    {
      "School Name": "Sacred Heart School"
    },
    {
      "School Name": "Christopher Day School"
    },
    {
      "School Name": "Calcutta Public School"
    },
    {
      "School Name": "Hebron English School"
    },
    {
      "School Name": "Cambridge English School"
    },
    {
      "School Name": "Union Chapel School"
    },
    {
      "School Name": "Modern High School For Girls"
    },
    {
      "School Name": "Vidya Niketan"
    },
    {
      "School Name": "Sunrise School"
    },
    {
      "School Name": "Sunshine School"
    },
    {
      "School Name": "Holy Home"
    },
    {
      "School Name": "Purwanchal Vidyamandir"
    },
    {
      "School Name": "St. Anthony's Convent School"
    },
    {
      "School Name": "St. Milarepa Academy"
    },
    {
      "School Name": "St. Mary's Convent School"
    },
    {
      "School Name": "Gitaram Academy"
    },
    {
      "School Name": "Mount Carmel School"
    },
    {
      "School Name": "The Calcutta Emmanuel School"
    },
    {
      "School Name": "St. Xavier's School"
    },
    {
      "School Name": "Convent Of Jesus And Mary"
    },
    {
      "School Name": "St. John's High School"
    },
    {
      "School Name": "Vivekananda Mission School"
    },
    {
      "School Name": "Park Institution"
    },
    {
      "School Name": "Modern English School"
    },
    {
      "School Name": "K.E. Carmel School"
    },
    {
      "School Name": "Springdale Academy"
    },
    {
      "School Name": "Green Lawn School"
    },
    {
      "School Name": "Stepping Stone School (High)"
    },
    {
      "School Name": "The Heritage School"
    },
    {
      "School Name": "St. Xavier's School"
    },
    {
      "School Name": "Bishop Morrow School"
    },
    {
      "School Name": "St. Clare School"
    },
    {
      "School Name": "Kanchrapara English Medium School"
    },
    {
      "School Name": "Isabella School"
    },
    {
      "School Name": "Garia Vidyabhavana South"
    },
    {
      "School Name": "Rose Bank Edu-Care High School"
    },
    {
      "School Name": "West Point School"
    },
    {
      "School Name": "St. Luke's Day School"
    },
    {
      "School Name": "Akshar"
    },
    {
      "School Name": "Asansol St. Anthony Secondary School"
    },
    {
      "School Name": "Temple Of Wisdom School"
    },
    {
      "School Name": "The Assembly Of God Church School"
    },
    {
      "School Name": "West End High School"
    },
    {
      "School Name": "North Point English Academy"
    },
    {
      "School Name": "Vrindavan School"
    },
    {
      "School Name": "Jogamaya Memorial Institute"
    },
    {
      "School Name": "Dawn Boarding School"
    },
    {
      "School Name": "St. Michael's School"
    },
    {
      "School Name": "Auxilium Convent School"
    },
    {
      "School Name": "International Public School"
    },
    {
      "School Name": "Camellia School"
    },
    {
      "School Name": "Father Leblond School"
    },
    {
      "School Name": "St. Anthony's Day School"
    },
    {
      "School Name": "St. Joseph Day School"
    },
    {
      "School Name": "National English School"
    },
    {
      "School Name": "St. Ann's Day School"
    },
    {
      "School Name": "Dunlop English Medium School"
    },
    {
      "School Name": "Contai Public School"
    },
    {
      "School Name": "The Scholar"
    },
    {
      "School Name": "Assembly Of Christ School"
    },
    {
      "School Name": "Holy Family School"
    },
    {
      "School Name": "Shatavisha Public School"
    },
    {
      "School Name": "Maharishi Vidya Mandir"
    },
    {
      "School Name": "Delhi Public School Megacity"
    },
    {
      "School Name": "Adamas International School"
    },
    {
      "School Name": "Garden High School"
    },
    {
      "School Name": "Disari Public School"
    },
    {
      "School Name": "Don Bosco Self Employment Research Institute"
    },
    {
      "School Name": "Pramila Memorial Institute"
    },
    {
      "School Name": "Sunrise School"
    },
    {
      "School Name": "Mahavir Institute Of Education And Research"
    },
    {
      "School Name": "Harvard House High School"
    },
    {
      "School Name": "Assembly Of Angels Secondary School"
    },
    {
      "School Name": "St. Mary's English School"
    },
    {
      "School Name": "Lyceum English Medium School"
    },
    {
      "School Name": "Oxford High School"
    },
    {
      "School Name": "National Public School"
    },
    {
      "School Name": "Sarwan Memorial School"
    },
    {
      "School Name": "Grace English School"
    },
    {
      "School Name": "Welland Gouldsmith School"
    },
    {
      "School Name": "Delhi Public School"
    },
    {
      "School Name": "Our Lady Queen Of The Missions School"
    },
    {
      "School Name": "Rishi Aurobindo Memorial Academy"
    },
    {
      "School Name": "Devaki Memorial School"
    },
    {
      "School Name": "Purushottam Bhagchandka Academic School"
    },
    {
      "School Name": "Marian Co-Educational School"
    },
    {
      "School Name": "St. Xavier's School"
    },
    {
      "School Name": "Alphonsa School"
    },
    {
      "School Name": "Abbot Shishu Hall"
    },
    {
      "School Name": "Welkin National School"
    },
    {
      "School Name": "St. Claret School"
    },
    {
      "School Name": "Carmel School"
    },
    {
      "School Name": "El-Bethel School"
    },
    {
      "School Name": "Burdwan Holy Child School"
    },
    {
      "School Name": "Saint Paul's School"
    },
    {
      "School Name": "Lions Calcutta (greater) Vidya Mandir"
    },
    {
      "School Name": "The Aryans School"
    },
    {
      "School Name": "St. Francis School"
    },
    {
      "School Name": "S.l. Bajoria Foundation High School"
    },
    {
      "School Name": "Nopany High"
    },
    {
      "School Name": "Auxilium Convent School"
    },
    {
      "School Name": "Shaw Public School"
    },
    {
      "School Name": "St. Teresa's School"
    },
    {
      "School Name": "New Age Public School"
    },
    {
      "School Name": "H.S. Memorial School"
    },
    {
      "School Name": "Mangalam Vidya Nlketan"
    },
    {
      "School Name": "Loreto Day School"
    },
    {
      "School Name": "St. Paul's Academy"
    },
    {
      "School Name": "Cygnet Day School"
    },
    {
      "School Name": "Beachwood School"
    },
    {
      "School Name": "Apex Academy"
    },
    {
      "School Name": "South City International School"
    },
    {
      "School Name": "Vision lnternational School"
    },
    {
      "School Name": "Sri Sri Academy"
    },
    {
      "School Name": "Pailan World School"
    },
    {
      "School Name": "Boon English School"
    },
    {
      "School Name": "Ideal Public School"
    },
    {
      "School Name": "Claret School"
    },
    {
      "School Name": "Tamralipta Public School"
    },
    {
      "School Name": "Holy Child School"
    },
    {
      "School Name": "Greenhill English School"
    },
    {
      "School Name": "St. Claret School"
    },
    {
      "School Name": "Royal Academy"
    },
    {
      "School Name": "Glenmore International School"
    },
    {
      "School Name": "Stratford Day School"
    },
    {
      "School Name": "St. Paul's School"
    },
    {
      "School Name": "Divine Mercy School"
    },
    {
      "School Name": "Sunrise English Medium School"
    },
    {
      "School Name": "M.N.M. Memorial High School"
    },
    {
      "School Name": "Calcutta Public School"
    },
    {
      "School Name": "Gurukul Vidya Mandir Secondary School"
    },
    {
      "School Name": "HOLY ANGELS' SCHOOL"
    },
    {
      "School Name": "Julien Day School"
    },
    {
      "School Name": "St. Stephen's School"
    },
    {
      "School Name": "Holy Cross School"
    },
    {
      "School Name": "St. Stephen's Academy"
    },
    {
      "School Name": "St. Stephen's School"
    },
    {
      "School Name": "Gems Akademia International School"
    },
    {
      "School Name": "Brindavan Boarding School"
    },
    {
      "School Name": "Magno Vale Academy"
    },
    {
      "School Name": "Sunshine High School"
    },
    {
      "School Name": "Mountain Heritage Academy"
    },
    {
      "School Name": "Holy Family Convent School"
    },
    {
      "School Name": "Ideal Public School"
    },
    {
      "School Name": "Mount Carmel School"
    },
    {
      "School Name": "Belda English Medium School"
    },
    {
      "School Name": "Paramount School"
    },
    {
      "School Name": "DON BOSCO ENGLISH MEDIUM SCHOOL"
    },
    {
      "School Name": "SRI AUROBINDO VIDYABHAWAN"
    },
    {
      "School Name": "The Green View English Academy"
    },
    {
      "School Name": "Bharat Academy & Sciences"
    },
    {
      "School Name": "Don Bosco School"
    },
    {
      "School Name": "Seventh Day Adventist School"
    },
    {
      "School Name": "CARMEL HIGH SCHOOL"
    },
    {
      "School Name": "Vivekananda Academy"
    },
    {
      "School Name": "All Saints Church School"
    },
    {
      "School Name": "St. Mary's School"
    },
    {
      "School Name": "St. Joseph's Convent School"
    },
    {
      "School Name": "P.P.MEMORIAL ACADEMY"
    },
    {
      "School Name": "Rabindra Path Bhaban Academy"
    },
    {
      "School Name": "Egra Public School"
    },
    {
      "School Name": "Xavier's Model Secondary School"
    },
    {
      "School Name": "Agrasain Boys' School"
    },
    {
      "School Name": "Sri Ram Narayan Singh Memorial High School"
    },
    {
      "School Name": "St.Joseph's English Medium School"
    },
    {
      "School Name": "De Paul School"
    },
    {
      "School Name": "SERAPHIM'S ASSEMBLY SCHOOL"
    },
    {
      "School Name": "Howard Memorial School"
    },
    {
      "School Name": "St. Joseph's School"
    },
    {
      "School Name": "Familia School"
    },
    {
      "School Name": "St. Joseph's School"
    }
  ]
  
  