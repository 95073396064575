import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import practiceQuiz from '../../../assets/quiz-select/practice-quiz.png'
import liveQuiz from '../../../assets/quiz-select/live-quiz.png'
import comingSoon from '../../../assets/quiz-select/coming-soon.png'


export default function SelectQuizView() {
  return (
      <div><Grid container spacing={2} className="grid-style">
          <Grid item xs={6}>
              <Card className='mAuto single-card' sx={{ maxWidth: 345 }} key={1}>
                  <CardHeader
                      title={<Box display="flex" justifyContent="center">
                        <Avatar
                            alt="Image Alt Text"
                            src={practiceQuiz}
                            sx={{ width: 86, height: 86 }}
                        />
                    </Box>}
                    //   avatar={
                        // <Avatar
                        //     alt="Image Alt Text"
                        //     src="https://via.placeholder.com/150"
                        //     sx={{ width: 56, height: 56 }} // Adjust size as needed
                        // />
                        // <img
                        //     alt="Image Alt Text"
                        //     src="https://via.placeholder.com/150"
                        //     style={{ width: '56px', height: '56px', borderRadius: '50%' }} // Adjust size and styling as needed
                        // />
                    // }
                  />
                  <CardContent>
                      {/* <Typography variant="body2" color="text.secondary">
                          {'Practice Quiz'}
                      </Typography> */}
                  </CardContent>
                  <CardActions disableSpacing>

                      <Grid container spacing={2}>
                          <Grid item xs={4}>
                          </Grid>
                          <Grid item xs={12}>
                              <NavLink to={`/practice-quiz`}>
                                  <Button variant="contained" color='error'>
                                     Past Quiz
                                  </Button>
                              </NavLink>
                          </Grid>
                      </Grid>
                  </CardActions>
              </Card>
          </Grid>
          <Grid item xs={6}>
              <Card className='mAuto single-card' sx={{ maxWidth: 345 }} key={1}>
                  <CardHeader
                      title={<Box display="flex" justifyContent="center">
                        <Avatar
                            alt="Image Alt Text"
                            src={liveQuiz}
                            sx={{ width: 88, height: 88 }}
                        />
                    </Box>}
                    //   avatar={
                        // <Avatar
                        //     alt="Image Alt Text"
                        //     src="https://via.placeholder.com/150"
                        //     sx={{ width: 56, height: 56 }} // Adjust size as needed
                        // />
                        // <img
                        //     alt="Image Alt Text"
                        //     src="https://via.placeholder.com/150"
                        //     style={{ width: '56px', height: '56px', borderRadius: '50%' }} // Adjust size and styling as needed
                        // />
                    // }
                  />
                  <CardContent>
                      {/* <Typography variant="body2" color="text.secondary">
                          {'Practice Quiz'}
                      </Typography> */}
                  </CardContent>
                  <CardActions disableSpacing>

                      <Grid container spacing={2}>
                          <Grid item xs={4}>
                          </Grid>
                          <Grid item xs={12}>
                              <NavLink to={`/quiz`}>
                                  <Button variant="contained" color='error'>
                                     Live Quiz
                                  </Button>
                              </NavLink>
                          </Grid>
                      </Grid>
                  </CardActions>
              </Card>
          </Grid>

          <Grid item xs={12}>
              <Card className='mAuto single-card' sx={{ maxWidth: 345 }} key={1}>
                  <CardHeader
                      title={<Box display="flex" justifyContent="center">
                        <Avatar
                            alt="Image Alt Text"
                            src={comingSoon}
                            sx={{ width: 128, height: 128, borderRadius: 0  }}
                        />
                    </Box>}
                    //   avatar={
                        // <Avatar
                        //     alt="Image Alt Text"
                        //     src="https://via.placeholder.com/150"
                        //     sx={{ width: 56, height: 56 }} // Adjust size as needed
                        // />
                        // <img
                        //     alt="Image Alt Text"
                        //     src="https://via.placeholder.com/150"
                        //     style={{ width: '56px', height: '56px', borderRadius: '50%' }} // Adjust size and styling as needed
                        // />
                    // }
                  />
                  <CardContent>
                      <Typography variant="body2" color="text.secondary">
                          {'More Coming Soon!'}
                      </Typography>
                  </CardContent>
                  <CardActions disableSpacing>

                      <Grid container spacing={2}>
                          <Grid item xs={4}>
                          </Grid>
                          <Grid item xs={12}>
                              {/* <NavLink to={`/quiz`}>
                                  <Button variant="contained" color='error'>
                                     Live Quiz
                                  </Button>
                              </NavLink> */}
                          </Grid>
                      </Grid>
                  </CardActions>
              </Card>
          </Grid>
      </Grid></div>
  )
}
