import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { cloneElement, useEffect, useRef, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import './attend-practice-quiz.css'
import { NavLink, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getSingleQuiz } from '../../../../core/services/quiz-service/quiz.service';
import { showDToaster } from '../../../../core/services/toaster-service/toaster.service';
import { saveResult } from '../../../../core/services/result-service/result.service';
import { getUser } from '../../../../core/services/login-service/login.service';
import moment from 'moment';
import { AWS_VARS } from '../../../../core/util/constants/constant';
function generate(element) {
    return [0, 1, 2].map((value) =>
      cloneElement(element, {
        key: value,
      }),
    );
  }

async function getSingleQuizForScreen(quizID) {
    try {
        const rsp = await getSingleQuiz(quizID);
        console.log(rsp);
        showDToaster('Quiz Started!')
        return rsp;
    } catch (error) {
        console.log(error);
        showDToaster(error?.response?.data?.message)
    }
    // getSingleQuiz('6612ad27319e5839a01c5ce6')
    // .then((rsp)=>{
    //     console.log(rsp);
    // })
    // .catch((err)=>{
    //     console.log(err);
    // })
}

function setCurrentIndex(currentQuesIndex) {
    localStorage.setItem('currentQuesIndex',currentQuesIndex);
}

function getCurrentIndex() {
    return JSON.parse(localStorage.getItem('currentQuesIndex'));
}

function removeCurrentIndex() {
    localStorage.removeItem('counter');
    localStorage.removeItem('currentQuesIndex');
}



export function AttendPracticeQuizPage() {
    const [currentQuiz, setCurrentQuiz] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState();
    const [currentQuesIndex, setCurrentQuesIndex] = useState(0);
    const [counter, setCounter] = useState(currentQuiz?.timerDuration);
    const [savedQuestionArr, setSavedQuestionArr] = useState([]);
    const [savedQuiz, setSavedQuiz] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const [totalQuestions, setTotalQuestions] = useState();
    const navigate = useNavigate();
    
    useEffect(() => {
        getQuiz();
        const prevIndex = getCurrentIndex();
        if(prevIndex){
            setCurrentQuesIndex(prevIndex);
        }
    }, []);
    
    useEffect(() => {
        setCurrentQuestion(currentQuiz?.questions?.[currentQuesIndex]);
        setCounter(currentQuiz?.timerDuration);
        setTotalQuestions(currentQuiz?.questions?.length);
    }, [currentQuesIndex, currentQuiz]);
    
    //?timer
    useEffect(() => {
        localStorage.setItem('counter',counter);
        // counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        let intervalId;
        if (counter >= 0) {
        intervalId = setInterval(() => setCounter(prevCounter => prevCounter - 1), 1000);
            if(counter === 0 && (currentQuesIndex + 1) === currentQuiz?.questions?.length){
                submitQuiz();
            }else if(counter === 0){
                getNextQuestion();
            }
            // console.log(counter);
        }
        return () => clearInterval(intervalId);
    }, [counter]);

    
    useEffect(() => {
        // console.log(savedQuestionArr);
        // console.log(currentQuiz);
        let finalQuizObj= {...currentQuiz,questions:[...savedQuestionArr]}
        setSavedQuiz(finalQuizObj);
    }, [savedQuestionArr]);  

    useEffect(() => {
        // setSavedQuiz({question:{savedQuiz}});
        console.log(savedQuiz);
    }, [savedQuiz]); 

    const restartTimer = () => {
        setCounter(currentQuiz?.timerDuration);
    }  

    const getQuiz = (event) => {
        // event.preventDefault();
        getSingleQuiz(id)
        .then((rsp)=>{
            rsp.data.quiz.timerDuration = rsp.data.quiz.timerDuration? parseInt(rsp.data.quiz.timerDuration) : 10;
            setCurrentQuiz(rsp?.data?.quiz);
        })
        .catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message)});
    }
    
    const saveResultF = () => {
        let user = getUser();
        console.log(user);
        console.log(totalQuestions);
        let payload = {...savedQuiz,
            "attemptedUserId": user?.id,
            "attemptedUserName": user?.name,
            "attemptedQuizId": savedQuiz?.id,
            "attemptedQuizName": savedQuiz?.name,
            "practiceQuiz":true,
            "totalQuizQuestions":totalQuestions,
            "createdAt": moment()
        }
            console.log(payload);
        const {name,id,createdAt,updatedAt, ...finalPayload} = payload; 
        console.log(finalPayload);   
        saveResult(finalPayload)
        .then((rsp) => {
            console.log(rsp);
            let savedResultId = rsp?.data?.result?.id; 
            navigate(`/practice-quiz/result?id=${savedResultId}`);
            showDToaster('Quiz Submitted');
        })
        .catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message)});
    }

    const getCurrentQuestion = () => {
        setCurrentQuestion(currentQuiz?.questions?.[currentQuesIndex])
        // restartTimer();

    }

    const getNextQuestion = (event) => {
        setCurrentQuesIndex(currentQuesIndex + 1);
        setCurrentIndex(currentQuesIndex + 1);
        getCurrentQuestion();
        restartTimer();
        setSelectedOption( null);
    }

    const getPrevQuestion = () => {
        setCurrentQuesIndex(currentQuesIndex - 1);
        getCurrentQuestion();
    }

    const submitQuiz = () => {
        // showDToaster('Quiz Submitted');
        // setSavedQuiz()
        console.log(savedQuiz);
        removeCurrentIndex();
        saveResultF();
    }

    const handleOptioChange = (event) => {
        const value = event.target.value;
        let currentQues = currentQuiz?.questions?.[currentQuesIndex];
        currentQues.answeredOptionIndex = parseInt(value);
        currentQues.timetaken = (parseInt(currentQuiz?.timerDuration) - counter);
        // console.log(currentQues);
        let newQuestionArr = [];
        newQuestionArr.push(currentQues);
        if(!savedQuestionArr.some(item => item.questionId === currentQues?.questionId)){
            setSavedQuestionArr(prevSavedQuestionArr => [...prevSavedQuestionArr, ...newQuestionArr]);
        }
        setSelectedOption(value === selectedOption ? null : value);
    }

    return (<>
        {/* <h2>{currentQuiz?.name}   </h2> */}
        {counter > 0 ?
        <div className='timerContainer'> 
        <strong> Next question appears in
        <span> {counter} </span>
        Seconds
        </strong>
        </div>  
        :
        <div className='timerContainer'> 
        <strong> Next question appears in
        <span> {counter} </span>
        Seconds
        </strong>
        </div> 
    }
          
    {/* <Button onClick={restartTimer}>restartTimer</Button> */}
    
    <div maxWidth="sm" className='quiz-container'>
        <div className='questionContainer'>
            <h2> {currentQuesIndex + 1}. {currentQuestion?.question}</h2>
            {currentQuestion?.imgPath !== '' && <img src={currentQuestion?.imgPath !== '' ? AWS_VARS.aws_base+currentQuestion?.imgPath:'NA' }/>}
        </div>

        <div className='answeredClass'>
            {currentQuestion?.options.map((option, index) => (
                <div  key={index} className={`options-ls ${parseInt(selectedOption) === index ? 'selected' : ''}`}>
                    <label className="custom-radio-container">
                    <input
                        type="radio"
                        name={currentQuiz?.id}
                        value={index}
                        onChange={handleOptioChange}
                        checked={parseInt(selectedOption) === index}
                        className="custom-radio-input"
                    />
                    <span className="custom-radio" />
                    <span id={option?.id} className="custom-radio-label">
                        {option?.optionsVal}
                    </span>
                    </label>
                </div>
            ))}
        </div>
        <ul>
            {/* <li> <input type='checkbox'/> Option 1 </li> */}
                
        </ul>
            {/* <Grid container spacing={2} className="grid-style">
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    {(currentQuesIndex + 1) === currentQuiz?.questions?.length ?
                        <Button variant="contained" color="success" onClick={submitQuiz}>
                            Submit 
                        </Button>
                        :
                        <Button variant="contained" color="info" onClick={getNextQuestion}>
                            Next
                        </Button>
                    }

                </Grid>
            </Grid> */}
            <hr/>
            <div className='btn-holder'>
            {(currentQuesIndex + 1) === currentQuiz?.questions?.length ?
                <Button variant="contained" color="success" onClick={submitQuiz}>
                    Submit 
                </Button>
                :
                <Button variant="contained" color="info" onClick={getNextQuestion}>
                    Next
                </Button>
            }
            
            </div>

        </div>
    </>)
}

// handleAddOption(value, isChecked) {
//     this.setState((prevState, props) => {
//       // Get the old state's value, sticking with immutable pattern
//       let yourProperty = prevState.yourProperty;
//       // Determine if the value already exists in your property's array
//       const exists = yourProperty.find(v => v === value);
  
//       if (isChecked) {
//         // If the checkbox is checked...
//         // If the property exists, don't do anything
//         // If it isn't there, add it
//         !exists && yourProperty.push(value);
//       } else {
//         // If the checkbox is NOT checked...
//         // If the property exists, filter the array to remove it
//         // If it isn't there, do nothing
//         exists && (yourProperty = yourProperty.filter(v => v !== value));
//       }
  
//       // Return the new state
//       return { yourProperty };
//     });
//   }