import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import './category-posts.css'
import { getFeed, getFeedByCategory, updateFeed } from '../../../core/services/feed-service/feed.service';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import ImgCardComponent from '../../../components/ui/img-card/img-card';
import { SpinnerComponent } from '../../../components/ui/spinner/spinner';
import { useLocation } from 'react-router-dom';
import { getUser } from '../../../core/services/login-service/login.service';
import { NoDataFound } from '../../../components/no-data-found/no-data-found';

export function CategoryFeedPage() {
    const [fetchedFeed, setFetchedFeed] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const categoryId = searchParams.get('category');

    useEffect(() => {
        getFeedFunc();
    },[])

    useEffect(() => {
        console.log(fetchedFeed);
    },[fetchedFeed])

    const getFeedFunc = () => {
        setShowLoader(true);
        getFeedByCategory(categoryId).then((v)=>{
            console.log(v);
            let data = v?.data;
            setFetchedFeed(data);
            setShowLoader(false);
            if(v){
                // showDToaster('Congo!!');
            }
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }
    
    const updateSingleFeedFunc = (postId, payload) => {
        // setShowLoader(true);
        updateFeed(postId, payload).then((v)=>{
            // setShowLoader(false);
        }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }

    const currentUserLikedOrNot = (post) => {
        const user = getUser();
        return post?.likedByUsers.includes(user?.id);
    }

    const likedFromChild = (post,likeStatus)=> {
        const user = getUser();
        // console.log(post);
        // console.log(likeStatus);
        // let randowm = Math.random();
        if(likeStatus){
            post.likedByUsers.push(user?.id)
        }else{
            post.likedByUsers = post.likedByUsers.filter((likers)=> likers !== user?.id);
        }
        let paylod = {
            likedByUsers: post.likedByUsers,
        }
        updateSingleFeedFunc(post?.id,paylod)
    }

    return (<>
    {/* <h2>Feed is here!</h2> */}
    {showLoader && <SpinnerComponent/>}
    {/* <Button variant="contained">Hello world</Button> */}
    <div className='feed-container'>
       {fetchedFeed?.length > 0 ? 
        (
        fetchedFeed?.map((post, index) => {
            const liked =  currentUserLikedOrNot(post);
            return (<>
                <ImgCardComponent key={index} post={post} onLike={likedFromChild} prevLiked={liked}/>
            </>)
        })
        ):
        (
            <NoDataFound/>
         )
       } 
    {/* <ImgCardComponent props={{postName:"m Fost",liked:true}} onLike={likedFromChild}/>
    <ImgCardComponent props={{postName:"2m Fost",liked:false}} onLike={likedFromChild}/> */}
    </div>
    </>)
}