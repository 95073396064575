import './login.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink } from 'react-router-dom';
import { loginUser } from '../../../core/services/login-service/login.service';
import { useNavigate, useHistory, unstable_HistoryRouter } from "react-router-dom";
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import AuthContext from '../../../context/auth.context';
import { useContext } from 'react';


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <span color="inherit" href="#">
                knowalledge.com
            </span>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
 async function loginFunc(payload) {
    try {
        const user = await loginUser(payload);
    } catch (error) {
    }
}



export function LoginPage() {
    const navigate = useNavigate();
    const user = useContext(AuthContext);


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        loginUser({
            email: data.get('email'),
            password: data.get('password'),
        }).then((v)=>{
            console.log(v);
            const loggedUser = v?.data?.user;
            showDToaster("Welcome back! 👋 " + loggedUser?.name);
            navigate('/');
        }).catch((err)=>{
            showDToaster("Invalid email or password, please check.");
        });
    };
    return (<>
        <div className='login-form'>
        {/* <h2> Login here!</h2> */}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        maxWidth: 400,
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <AppRegistrationIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            // defaultValue={'test@example.com'}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            // defaultValue={'password1'}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <NavLink to={'/forgot-password'} variant="body2">
                                    Forgot password?
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to='/register'>
                                    {"Don't have an account?"}
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        <div>
        </div>    
        </div>
    </>)
}

