import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './img-card.css';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Facebook, WhatsApp } from '@mui/icons-material';
import { showDToaster, showUnderDevToaster } from '../../../core/services/toaster-service/toaster.service';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { AWS_VARS } from '../../../core/util/constants/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ImgCardComponent({post, onLike, prevLiked}) {
  const [expanded, setExpanded] = useState(false);
  const [reaction, setReaction] = useState(false); 
  const [htmlContent, setHtmlContent] = useState('');
  const [isWebShareSupported, setIsWebShareSupported] = useState(false);
  const sanitizedHtml = DOMPurify.sanitize(post?.postContent, {USE_PROFILES: { html: true }});
  const navigate = useNavigate();
  // console.log(sanitizedHtml);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setReaction(prevLiked);
    if (navigator.share) {
      setIsWebShareSupported(true);
    }
  }, [prevLiked])

  useEffect(()=>{
    setHtmlContent(sanitizedHtml);
  },[post]);

  const handelLike = () => {
    onLike(post, !reaction);
    setReaction(!reaction);
  }

  const underDev = () => {
    showUnderDevToaster();
  }
  const shareToWhatsapp = (post)=>{
    // const url = 'whatsapp://send?text=Your%20Message%20Here%20https://example.com"'
    // navigate(url)
    // var phoneNumber = '1234567890';
    // // Predefined message (optional)
    var message = encodeURIComponent(window.location.host);
    // // Construct the WhatsApp URL
    // var whatsappURL = 'https://wa.me/' + phoneNumber + '?text=' + message;
    // // Redirect to WhatsApp
    // window.location.href = whatsappURL;
    // navigator.share({
    //   title: 'Shared via WhatsApp',
    //   text: 'Check out this content!' +' '+ post?.name ,
    //   url: window.location.href
    // }).then(() => {
    //     console.log('Shared successfully.');
    // }).catch((error) => {
    //     console.error('Error sharing:', error);
    // });

    if (navigator.share) {
      navigator.share({
        title: 'Shared via WhatsApp',
        text: 'Check out this content! ' + post?.name,
        url: window.location.href
      }).then(() => {
        console.log('Shared successfully.');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      // Fallback for browsers that do not support navigator.share
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent('Check out this content! ' + post?.name + ' ' + window.location.href)}`;
      window.open(whatsappUrl, '_blank');
    }
  }

  return (
    <>
      {htmlContent && (<>
      {!post?.carouselPost ?
        (
          <>
            <Card className='mAuto' sx={{ maxWidth: 345 }}>
              <CardHeader
                // avatar={
                //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                //     R
                //   </Avatar>
                // }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                className='title-name'
                title={post?.name}
                // subheader={moment(props?.props?.post?.createdAt).format('Do [of] MMMM YYYY')}
                // subheader={moment(post?.createdAt).format('Do MMMM YYYY')}
                subheader={'Posted in: ' + moment(post?.createdAt).format('MMMM')}
                // sx={{ pb: 3 }}
              />
              {/* <CardContent>
                <Typography variant="body2" color="text.secondary" className='description'>
                  {post?.description}
                </Typography>
              </CardContent> */}
              <CardMedia
                component="img"
                height="194"
                image={AWS_VARS?.aws_base+post?.bannerImg}
                alt="Paella dish"
                className='single-post-img'
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary" className='description-single'>
                  {post?.description}
                </Typography>
              </CardContent>
              <div className='category-container'>
                  {post?.category?.map((content, i) => {
                    return ( <>
                    <NavLink to={`/posts-by-categories?category=${content?.categoryId}`}>
                    <Badge
                        className='mBadge'
                        key={i}
                        badgeContent={content?.categoryName}
                        color="error"
                        sx={{ padding: '10px', borderRadius: '5px', margin: '5px' }}
                      >
                        {/* <Box sx={{ width: 0, height: 0 }} /> */}
                      </Badge>
                    </NavLink>
                      
                    </>)
                  })}
                </div>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites"
                  onClick={handelLike}>
                  {reaction ? <FavoriteIcon color='warning' /> : <FavoriteIcon />}
                </IconButton>
                
                
                {isWebShareSupported ? (
                  <>
                  <IconButton aria-label="share" onClick={()=>{shareToWhatsapp(post)}}>
                  <WhatsApp />
                  </IconButton>
                  <IconButton aria-label="share" onClick={shareToWhatsapp}>
                    <Facebook />
                  </IconButton>
                  <IconButton aria-label="share" onClick={shareToWhatsapp}>
                    <ShareIcon />
                  </IconButton>
                  </>
                ) : (
                  <IconButton aria-label="share" onClick={()=>{shareToWhatsapp(post)}}>
                  <WhatsApp />
                  </IconButton>
                )}
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph>
                    {/* {parse(htmlContent)} */}
                    <span dangerouslySetInnerHTML={{__html: parse(htmlContent)}}>
                    </span>
                    
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </>
        )
        : (<>
          
            <Card className='mAuto' sx={{ maxWidth: 345 }}>
            <CardHeader
                        // title={post?.name}
                        title={'Knowalledge Trivia'}
                        // subheader={moment(post?.createdAt).format('Do MMMM YYYY')}
                        subheader={'Posted in: ' + moment(post?.createdAt).format('MMMM')}
                        // sx={{ pb: 3 }}
                      />
                      {/* <CardContent>
                        <Typography variant="body2" color="text.secondary" className='description'>
                          {post?.description}
                        </Typography>
                      </CardContent> */}
              <div className='carousel-container'>
                
                <Carousel showArrows={true} showIndicators={true} showThumbs={false} showStatus={false}>
                  {post?.carouselPostContentArr?.map((content, i) => {
                    return (<div className='carousel-card'>
                      
                      <CardMedia
                        component="img"
                        height="350"
                        image={AWS_VARS?.aws_base+content?.bannerImgArr}
                        key={content?.seriesPostId}
                        alt={content?.seriesPostId}
                      />
                      {/* <CardContent>
                        <Typography variant="body2" color="text.secondary" className='description'>
                          {post?.description}
                        </Typography>
                      </CardContent> */}
                      
                    </div>)
                  })}
                </Carousel>
                <CardActions disableSpacing>

                  {/* <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore> */}
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography >
                      <span dangerouslySetInnerHTML={{ __html: parse(htmlContent) }}>
                      </span>
                    </Typography>
                  </CardContent>
                </Collapse>
                <div className='category-container'>
                  {post?.category?.map((content, i) => {
                    return ( <>
                    <NavLink to={`/posts-by-categories?category=${content?.categoryId}`}>
                        <Badge
                          className='mBadge'
                          key={i}
                          badgeContent={content?.categoryName}
                          color="error"
                          sx={{ padding: '10px', borderRadius: '5px', margin: '5px' }}
                        >
                          {/* <Box sx={{ width: 0, height: 0 }} /> */}
                        </Badge>
                    </NavLink>
                      
                    </>)
                  })}
                </div>
                <div className='action-btns'>
                  <IconButton aria-label="add to favorites"
                    onClick={handelLike}>
                    {reaction ? <FavoriteIcon color='warning' /> : <FavoriteIcon />}
                  </IconButton>
                  {isWebShareSupported ? (
                  <>
                  <IconButton aria-label="share" onClick={()=>{shareToWhatsapp(post)}}>
                  <WhatsApp />
                  </IconButton>
                  <IconButton aria-label="share" onClick={shareToWhatsapp}>
                    <Facebook />
                  </IconButton>
                  <IconButton aria-label="share" onClick={shareToWhatsapp}>
                    <ShareIcon />
                  </IconButton>
                  </>
                ) : (
                  <IconButton aria-label="share" onClick={()=>{shareToWhatsapp(post)}}>
                  <WhatsApp />
                  </IconButton>
                )}
                </div>
              </div>
            </Card>
        </>)
      }
  </>)}
    </>
  );
}