import './practice-quiz-card.css'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import { checkAttempt, attemptingQuiz } from '../../../core/services/quiz-service/quiz.service';
import { getUser } from '../../../core/services/login-service/login.service';
import { SpinnerComponent } from '../spinner/spinner';


function PracticeQuizCard({quiz,isQuiz}) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [quizConfig, setQuizConfig] = useState(null);
  const [quizStartCounter, setQuizStartCounter] = useState(-1);
  const [showLoader, setShowLoader] = useState(false);
  

  const handleAttendQuiz = () => {
    setShowLoader(true);
    console.log('Attend',quiz);
    const user = getUser();
    setShowLoader(false);
        //? navigating for quiz
        navigate(`/practice-quiz/attend-quiz?id=${quiz?.id}`)
    }
    return (
      <>
       {showLoader && <SpinnerComponent/>}
      <Card className='mAuto single-card' sx={{ maxWidth: 345 }} key={quiz?.id}>
        <CardHeader
          title={isQuiz?
            quiz?.name
          :
            quiz?.attemptedQuizName}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
          {quiz?.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          
          <Grid container spacing={2}>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12}>
              {isQuiz ? 

              <>
                <Button variant="contained" color='error' onClick={handleAttendQuiz}>
                    Practice Quiz
                </Button>
              </>
                : 
              <NavLink to={`/quiz/result?id=${quiz?.id}`}>
                <Button variant="contained" color='error'>
                  View Score
                </Button>
              </NavLink>}
            </Grid>
          </Grid>
        </CardActions>
      </Card>

       
      </>
    );
}

export default PracticeQuizCard;