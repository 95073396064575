import { useLocation, useNavigate } from 'react-router-dom';
import './quiz-result.css'
import { useEffect, useState } from 'react';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import { getResult } from '../../../core/services/result-service/result.service';
import moment from 'moment/moment';
import { SpinnerComponent } from '../../../components/ui/spinner/spinner';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';
import scoreImg from '../../../assets/quiz-result/stars.png'
import timeImg from '../../../assets/quiz-result/clock.png'
import rightTick from '../../../assets/quiz-result/tick-inside-a-circle.svg'

function ResultsTable({ result }) {
    return (
        <div className='bg-c'>
        <h2> Hi {result?.attemptedUserName} 👋!</h2>
        <h3>{result?.attemptedQuizName} Attempted On {moment(result?.createdAt).format('Do MMMM, dddd')}</h3>
        <div className='result-container'>
        <table >
        <thead>
            <tr>
                <th>Name</th>
                <th>Quiz Name</th>
                <th>Score</th>
                <th>Total Time</th>
                <th>Attempt Date</th>
            </tr>
        </thead>
        <tbody>
            <tr key={result?.id}>
                <td>{result?.attemptedUserName}</td>
                <td>{result?.attemptedQuizName}</td>
                <td>{result?.totalCalculatedScore}</td>
                <td>{result?.totalCalculatedTime} sec</td>
                <td>
                {/* {result?.attemptedUserName} */}
                {moment(result?.createdAt).format('Do MMMM dddd')}
                    {/* {moment().format('Do MMMM dddd')} */}
                </td>
            </tr>
            {/* {result?.questions.map((result, index) => (
                <tr key={index}>
                    <td>{result.attemptedUserName}</td>
                    <td>{result.score}</td>
                    <td>{result.attemptDate}</td>
                </tr>
            ))} */}
        </tbody>
        </table>

        
        </div>
        <div>
            <div className='main-img'>
                <img src={rightTick} height={190}/>
                <h2> <strong>You are done!</strong></h2>
            </div>
        </div>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="grid-style">
                <Grid item xs={6}>
                <Card className='mAuto single-card' sx={{ maxWidth: 345 }}>
                <CardHeader
                    title={<Box display="flex" justifyContent="center">
                        <Avatar
                            alt="Image Alt Text"
                            src={scoreImg}
                            sx={{ width: 86, height: 86 }}
                        />
                        </Box>}
                    />
                    <CardContent>
                    </CardContent>
                    <CardActions disableSpacing>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="text.secondary">
                                <div className='diff-attr'>
                                <span className='header-attr'>Total Score </span> <br/> <strong> {result?.totalCalculatedScore} / {(result?.totalQuizQuestions)} </strong> 
                                <br/> 
                                </div>
                            </Typography>
                            {/* <Button variant="contained" color='error'>
                            Attend Quiz
                            </Button> */}
                        </Grid>
                    </Grid>
                    </CardActions>
                </Card>
                </Grid>
                <Grid item xs={6}>
                <Card className='mAuto single-card' sx={{ maxWidth: 345 }}>
                <CardHeader
                    title={<Box display="flex" justifyContent="center">
                        <Avatar
                            alt="Image Alt Text"
                            src={timeImg}
                            sx={{ width: 86, height: 86 }}
                        />
                        </Box>}
                    />
                    <CardContent>
                    </CardContent>
                    <CardActions disableSpacing>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="text.secondary">
                                <div className='diff-attr'>
                                <span className='header-attr'>Time Taken </span> <br/> <strong> {result?.totalCalculatedTime} sec </strong> 
                                <br/> 
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                    </CardActions>
                </Card>
                </Grid>
            </Grid>
        </Box>
        </div>
       
    );
};


export function QuizResultPage() {
    const [currenResult, setCurrentResult] = useState();
    const [showLoader, setShowLoader] = useState(true);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const navigate = useNavigate();
    useEffect(() => {
        getResultF();
        

        //todo improvement can be done here as this code restricts user so they dont go back to quiz page
        // Add a new entry to the history stack with the URL of the quiz result page
        window.history.pushState(null, '', window.location.href);
        // Add event listener to prevent navigation using browser back button
        const handleBackNavigation = (event) => {
        event.preventDefault();
        window.history.pushState(null, '', window.location.href); // Add another entry to the history stack
        // Optionally, display a message to inform the user about the navigation restriction
        alert('You cannot navigate back to the quiz page.');
        };
        window.addEventListener('popstate', handleBackNavigation);

        // Clean up event listener when component unmounts
        return () => {
        window.removeEventListener('popstate', handleBackNavigation);
        };

    }, []);

    useEffect(() => {
        console.log(currenResult);
    }, [currenResult]);
    
    const calCulateTotalScoreAndTime = (data) => {
        let totalScore = 0;
        let totalTime = 0;
        data?.questions?.map((question, index) => {
            console.log(question);
            totalTime += question?.timetaken || 0;
            console.log({totalTime:totalTime});
            if(question?.answeredOptionIndex === question?.correctOptionIndex){
                totalScore += 1;
            }else{
                console.log('Wrong answer');
            }
        });
        return {totalScore:totalScore,totalTime:totalTime};
    }

    const getResultF = () => {
        setShowLoader(true);
        // event.preventDefault();
        getResult(id)
        .then((rsp)=>{
            let data = rsp?.data?.result;
            let totalCalculatedScoreAndTime = calCulateTotalScoreAndTime(data);
            console.log(totalCalculatedScoreAndTime);
            const processedData = {...data,
                totalCalculatedScore:totalCalculatedScoreAndTime?.totalScore,
                totalCalculatedTime:totalCalculatedScoreAndTime?.totalTime};
            console.log(processedData);
            setCurrentResult(processedData);
            setShowLoader(false);
        })
        .catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    }

    const handleGoBack = ()=>{
        navigate('/');
    }
    return (<>
    {showLoader && <SpinnerComponent/>}
    {/* <h2>Quiz result!</h2> */}
    {currenResult?<ResultsTable result={currenResult}/>:''}
    <Button variant="contained" color="inherit" type='button' className='go-back-btn' onClick={handleGoBack}>
      <Home/>  Go back
    </Button>
    </>)
}