import './profile.css'
import AccountCircle from '@mui/icons-material/AccountCircle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { changeRefreshToken, getUser, getUserById, removeUser, updateUserById } from '../../core/services/login-service/login.service';
import { showDToaster } from '../../core/services/toaster-service/toaster.service';
import { SpinnerComponent } from '../../components/ui/spinner/spinner';
import { schoolsConstant, stateAndCity } from '../../core/util/constants/constant';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';

export function ProfilePage() {
  const [userData, setUserData] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [formValue, setFormValue] = useState();
  const navigate = useNavigate();

    useEffect(() => {
      console.log('runing');
      getUserDetails();
      changeRefreshTokenFunc();
      // console.log(schoolsConstant.length);
      // console.log(stateAndCity.length);
    }, []);

    useEffect(() => {
      console.log(userData?.name);
    }, [userData]);

    const changeRefreshTokenFunc = ()=>{
      changeRefreshToken().then((v)=>{
        setShowLoader(false);
      }).catch((err)=> {
        console.log(err); 
        showDToaster(err?.response?.data?.message); 
        setShowLoader(false); 
        removeUser();
        navigate('/');
      });
    };

    const getUserDetails = ()=>{
      const user = getUser();
      getUserById(user?.id).then((v)=>{
        console.log(v);
        let data = v?.data;
        setUserData(data);
        setShowLoader(false);
        if(v){
            // showDToaster('Congo!!');
        }
    }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    };

    const updateUserDetails = (payload)=>{
      const user = getUser();
      const mPayload = payload;
      updateUserById({userId:user?.id, payload:mPayload}).then((v)=>{
        // console.log(v);
        setShowLoader(false);
        showDToaster('Updated!');
        getUserDetails();
        if(v){
            // showDToaster('Congo!!');
        }
    }).catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message); setShowLoader(false);});
    };

    const handleSbmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
        console.log({
          name: data.get('name'),
          email: data.get('email'),
        });
      const payload = {
        // email: data.get('email'),
        // schoolstudent: , //todo boolean
        schoolName: data.get('schoolname')|| ' ',
        state: data.get('state')|| ' ',
        city: data.get('city')|| ' ',
        gender: data.get('gender') || ' ',
        mobileNo: data.get('mobileno') || ' ',
      }
      
        updateUserDetails(payload);
    }
  
    const handleChange = (event)=>{
      console.log('ss',event.target.value);
      setFormValue(event.target.value)
    }
    const handleSchoolName = (event) =>{
      const school = schoolsConstant?.filter((school)=> 
     {
      console.log(event.target.value);
      console.log(
        school.name.trim())
      }
    //  school?.name.toLowerCase().includes(event.target.value)
    );
      // const sortedSchools = countryCodes.filter(country =>
      //   country.name.toLowerCase().includes(searchText)
      // );
      console.log(school);
    }
    

    return (<>
     {showLoader && <SpinnerComponent/>}
        {/* <h2> PRofile Page</h2> */}
     {userData && (
      <>
       <div className='profile-avatar'>
          <AccountCircle />
      </div>
      <Box
        component="form"
        sx={{
            '& > :not(style)': { m: 1, p:1,width: '90%' },
          }}
        noValidate
        autoComplete="off"
        onSubmit={handleSbmit}
      >
          <TextField id="standard-basic" label="Name" variant="filled" name="name"  defaultValue={userData?.name} disabled/>
          <TextField id="filled-basic" label="State" variant="filled" name="state"  defaultValue={userData?.state}/>
          <TextField id="filled-basic" label="City" variant="filled" name="city" defaultValue={userData?.city}/>
          <TextField type='date' id="filled-basic" label="DOB" variant="filled" name="DOB" defaultValue={userData?.city}/>
          <TextField id="filled-basic" label="School Name" variant="filled"  name="schoolname" defaultValue={userData?.schoolname} onChange={handleSchoolName}/>

          {/* <FormControl variant="filled">
            <InputLabel id="schoolname-label">School Name</InputLabel>
            <Select
              labelId="schoolname-label"
              id="filled-basic"
              name='schoolname'
              value={formValue?.schoolname}
              onChange={handleChange}
            >
              {schoolsConstant?.map((school,i) => {
                return (<>
                <MenuItem value={school?.name} key={i}>{school?.name}</MenuItem>
                </>)
              })}
            </Select>
          </FormControl> */}

          <TextField id="filled-basic" label="Email" variant="filled" name="email" defaultValue={userData?.email} disabled/>
          <TextField id="filled-basic" label="Mobile" variant="filled" name='mobileno' defaultValue={userData?.mobileNo}  inputProps={{ maxLength: 10 }}/>
          {/* <TextField id="filled-basic" label="Gender" variant="filled" defaultValue={userData?.name}/> */}
         
          
          <FormControl variant="filled">
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              id="filled-basic"
              defaultValue={userData?.gender}
              value={formValue?.gender}
              onChange={handleChange}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              {/* <MenuItem value="Other">Other</MenuItem> */}
            </Select>
          </FormControl>

          <Button variant="contained" color="success" type='submit'>
              Update
          </Button>
          {/* //todo <Button variant="contained" color="inherit" type='button'>
              Change Your Password
          </Button> */} 
      </Box>
      </>
     )}
	
    </>)
}