import './quiz-card.css'
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { showDToaster } from '../../../core/services/toaster-service/toaster.service';
import { checkAttempt, attemptingQuiz } from '../../../core/services/quiz-service/quiz.service';
import { getUser } from '../../../core/services/login-service/login.service';
import { SpinnerComponent } from '../spinner/spinner';

function StartingText() {
  return(<>
  <span className='start-text'> It's going to be <span>Live</span> soon! </span>
  </>)
}

function StartingCounder({quizStartCounter}) {
  return(<>
  <span className='start-counter' >Starting in <span>{Math.round(quizStartCounter)}</span> </span>
  </>)
}
 

function QuizCardComponent({quiz,isQuiz}) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [quizConfig, setQuizConfig] = useState(null);
  const [quizStartCounter, setQuizStartCounter] = useState(-1);
  const [showLoader, setShowLoader] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
    //?timer
  useEffect(() => {
    CalculateTime({ startTime: quiz?.quizStartTime, endTime: quiz?.quizEndTime  });
  }, [quiz]);

  useEffect(() => {
    let intervalId; 
    if(quizStartCounter >= 0){
      if(quizStartCounter < 1 && quizStartCounter !== null && quizConfig?.showCounter){
        setQuizConfig({ showButton: true, showCounter: false });
        // CalculateTime({ startTime: quiz?.quizStartTime, endTime:'s' });
      }else if(quizStartCounter < 300 && quizStartCounter !== null && !quizConfig?.showCounter && !quizConfig?.showButton){
        setQuizConfig({ showCounter: true });
      }
      intervalId = setInterval(() => {
        setQuizStartCounter(prevCounter => prevCounter - 1)
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [quiz?.quizStartTime, quizConfig, quizStartCounter]);

  const CalculateTime = ({ startTime, endTime }) => {
    // console.log(quizStartCounter);
    const currentTime = moment();
    // const mStartTime = moment().add(2,'seconds');//? case 1; lessthan 1m
    // const mStartTime = moment().add(4,'minutes'); //? case 2; lessthan 5m
    // const mStartTime = moment().add(7,'minutes');//? case 3; lessthan 10m
    const mStartTime = moment(startTime);//? case 1; lessthan 1m
    //  mEndTime = moment().subtract(10, 'minutes');
    const mEndTime = moment(endTime);
    // console.log({current: currentTime.format('HH:mm:ss')});
    // console.log({endTime: mEndTime.format('HH:mm:ss')});
    const timeDiff = moment.duration(mStartTime.diff(currentTime));
    if(mEndTime.isAfter(moment())) {
      if (currentTime === mStartTime || timeDiff < 0 ) {
        setQuizConfig({ showButton: true, showCounter: false });
        // setQuizConfig({buttonEnabled:true, showCountDown:false, showTag:false});
      } else if (timeDiff.asMinutes() >= 0 && timeDiff.asMinutes() <= 5) {
        if (timeDiff.asMinutes() > 1) {
          //? case 1
          // console.log({ case1: timeDiff.asMinutes() });
          setQuizConfig({ showCounter: true,  });
          setQuizStartCounter(timeDiff.asSeconds());
        } else {
          //? case 2
          // console.log({ case2: timeDiff.asSeconds() });
          setQuizConfig({ showCounter: true, });
          setQuizStartCounter(timeDiff.asSeconds());
        }
  
        // setQuizConfig({ buttonEnabled: false, showCountDown: true, showTag: true });
      } else if (timeDiff.asMinutes() >= 5 && timeDiff.asMinutes() <= 10) {
        //? case 3
        // && (currentTime > mStartTime && currentTime < mEndTime)
        // console.log('6h');
        // console.log({ case3: timeDiff.asMinutes() });
        setQuizConfig({ showLiveSoon: true, showCounter: false });
        setQuizStartCounter(timeDiff.asSeconds());
      } else {
      }
    }else{
      setQuizConfig({ showButton: false, showCounter: false });
    }
  }

  const handleAttendQuiz = () => {
    setShowLoader(true);
    console.log('Attend',quiz);
    const user = getUser();
    checkAttempt(user?.id, quiz?.id)
    .then((v)=>{

      attemptingQuiz(quiz?.id)
      .then((v)=>{
        setShowLoader(false);
        //? navigating for quiz
        navigate(`/quiz/attend-quiz?id=${quiz?.id}`)
      })
      .catch((err)=> {
        showDToaster(err?.response?.data?.message); 
        setShowLoader(false);
      });

    })
    .catch((err)=> {
      showDToaster(err?.response?.data?.message); 
      setShowLoader(false);
    });

  }
      

    return (
      <>
       {showLoader && <SpinnerComponent/>}
      <Card className='mAuto single-card' sx={{ maxWidth: 345 }} key={quiz?.id}>
        <CardHeader
        //   avatar={
        //     <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //       R
        //     </Avatar>
        //   }
        //   action={
        //     <IconButton aria-label="settings">
        //       <MoreVertIcon />
        //     </IconButton>
        //   }
          title={isQuiz?
            quiz?.name
          :
            quiz?.attemptedQuizName + `${quiz?.practiceQuiz? ' [Practice]' : ''}`}
          // subheader="September 14, 2016"
        />
        {/* <CardMedia
          component="img"
          height="194"
          image="http://knowalledge.com/wp-content/uploads/2024/03/holi.001.jpeg"
          alt="Paella dish"
        /> */}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
          {quiz?.description}
          </Typography>
          { isQuiz?
          // <CalculateTime/>
          moment(quiz?.quizStartTime).format('Do MMMM YYYY [at] HH:mm:ss')
          :
          moment(quiz?.createdAt).format('[Attempted at] Do MMMM YYYY')}
        </CardContent>
        <CardActions disableSpacing>
          
          {/* <IconButton aria-label="share">
            <ShareIcon />
          </IconButton> */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {/* <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton> */}
            </Grid>
            <Grid item xs={12}>
              {isQuiz ? 

              <>
              {quizConfig?.showButton && (
                <Button variant="contained" color='error' onClick={handleAttendQuiz}>
                  Attend Quiz
                </Button>
              )}
              {quizConfig?.showLiveSoon && (
                <p> <StartingText/></p>
              )}
              {quizConfig?.showCounter && (
                <p><StartingCounder quizStartCounter={quizStartCounter}/></p>
              )}
              </>
                : 
              <NavLink to={`/quiz/result?id=${quiz?.id}`}>
                <Button variant="contained" color='error'>
                  View Score
                </Button>
              </NavLink>}
            </Grid>
          </Grid>
        </CardActions>
      </Card>

       
      </>
    );
}

export default QuizCardComponent;