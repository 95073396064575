import Typography from '@mui/material/Typography';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <span color="inherit" href="#">
                knowalledge.com
            </span>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export function FooterComponent() {
    return (<>
    {/* <div> Footer Here!</div> */}
    <Copyright sx={{ mt: 8, mb: 4 }} />
    </>)
}