import { localStorageKeyConstants } from "../../util/constants/constant";
import { getRequest, patchRequest, postRequest } from "../base/base-api.service";

function setToken(token) {
    localStorage.setItem(localStorageKeyConstants.token,token);
}

function setRefreshToken(refreshToken) {
    localStorage.setItem(localStorageKeyConstants.refreshToken,refreshToken);
}

function getRefreshToken() {
    return localStorage.getItem(localStorageKeyConstants.refreshToken);
}

function getToken(){
    return localStorage.getItem(localStorageKeyConstants.token);
}

function setUser(token) {
    localStorage.setItem(localStorageKeyConstants.user,JSON.stringify(token));
}

function getUser(){
    return JSON.parse(localStorage.getItem(localStorageKeyConstants.user));
}

function removeAll(){
    localStorage.removeItem(localStorageKeyConstants.token);
    localStorage.removeItem(localStorageKeyConstants.user);
    localStorage.removeItem(localStorageKeyConstants.refreshToken);
}

function loginUser(payload) {
    return new Promise((resolve, reject) => {
        postRequest('auth/login', payload)
            .then(response => {
                setToken(response?.data?.tokens?.access?.token);
                setRefreshToken(response?.data?.tokens?.refresh?.token);
                setUser(response?.data?.user);
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function changeRefreshToken() {
    const refToken = getRefreshToken();
    const payload ={
        "refreshToken":refToken,
      };
    return new Promise((resolve, reject) => {
        postRequest('auth/refresh-tokens', payload)
            .then(response => {
                console.log(response);
                setToken(response?.data?.access?.token);
                setRefreshToken(response?.data?.refresh?.token);
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}


function registerUser(payload) {
    return new Promise((resolve, reject) => {
        postRequest('auth/register', payload)
            .then(response => {
                setToken(response?.data?.tokens?.access?.token);
                setRefreshToken(response?.data?.tokens?.refresh?.token);
                setUser(response?.data?.user);
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function logoutUserC() {
    return new Promise((resolve, reject) => {
        let payload = {
            refreshToken:getRefreshToken()
        }
        postRequest('auth/logout', payload)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}


function getUserById(userId) {
    return new Promise((resolve, reject) => {
        getRequest(`users/${userId}`,)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function updateUserById({userId, payload}) {
    return new Promise((resolve, reject) => {
        patchRequest(`users/${userId}`,payload)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}

function removeUser() {
    localStorage.clear();
}

function setStaticPassword(payload) {
    return new Promise((resolve, reject) => {
        postRequest('auth/set-password-email', payload)
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}


export  {setToken, setRefreshToken, setUser, getToken, getUser, loginUser, registerUser, logoutUserC, removeAll, getUserById, updateUserById, changeRefreshToken, removeUser, setStaticPassword};  