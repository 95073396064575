import { getRequest, postRequest } from "../base/base-api.service";

function getCategories() {
    return new Promise((resolve, reject) => {
        getRequest('category/viewAll')
            .then(response => {
                resolve(response); // Resolve with user data
            })
            .catch(error => {
                reject(error); // Reject with the error
            });
    });
}







export  {getCategories};  