
import axios from 'axios';
import { enviromentConfigs } from '../../../env/environment';
import { showDToaster } from '../toaster-service/toaster.service';
import { getToken } from '../login-service/login.service';

const axiosClient = axios.create({
    baseURL: enviromentConfigs.APP_API_URL, // Assuming your API is hosted on this URL
  });

// axiosClient.defaults.baseURL = enviromentConfigs.APP_API_URL;
function setHeader(){
    const token = getToken();
    axiosClient.defaults.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${token}`
        // Accept: 'application/json'
    };

}
//!important https://dev.to/nilanth/how-to-use-axios-in-an-optimized-and-scalable-way-with-react-518n

// axios.interceptors.request.use(function (request) {
//     request.headers['Content-Type'] = 'multipart/form-data';
//     return request;
//   }, null, { synchronous: true });

// All request will wait 2 seconds before timeout
//*disabled it for the time being
// axiosClient.defaults.timeout = enviromentConfigs.APP_URL_TIMEOUT;
//* end
//? this triggers weird bug which results no-origin passing in the header
// axiosClient.defaults.withCredentials = true;

export function getRequest(URL) {
    setHeader();
    return axiosClient.get(`/${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
    setHeader();
    return axiosClient.post(`/${URL}`, payload).then(response => response)
    // .catch((err)=> {console.log(err); showDToaster(err?.response?.data?.message)});
}

export function patchRequest(URL, payload) {
    setHeader();
    return axiosClient.patch(`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
    setHeader();
    return axiosClient.delete(`/${URL}`).then(response => response);
}


// export default {getRequest, postRequest};